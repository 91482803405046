import React, { useEffect } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";

const SearchableSelect = ({
  value,
  setValue,
  name,
  sx,
  label,
  helperText,
  error,
}) => {
  const [searchKey, setSearchKey] = React.useState(" ");
  const [options, setOptions] = React.useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { cookies } = useAuth();
  const { id } = cookies?.auth?.user;

  useEffect(() => {
    const getMerchants = async () => {
      if (searchKey) {
        try {
          const response = await axiosPrivate.get(
            "https://services.staging.wayagram.ng/wayagram-service/main/admin-profile/search",
            {
              params: {
                query: searchKey,
                page: 1,
                sso_id: id,
              },
            }
          );
          setOptions(response?.data?.data ? response?.data?.data : []);
        } catch (error) {
          console.log(error);
        }
      }
    };

    getMerchants();
  }, [searchKey]);

  return (
    <Box sx={sx}>
      <Autocomplete
        value={value}
        name={name}
        onChange={(event, newValue) => {
          setValue(name, newValue);
        }}
        options={options}
        getOptionLabel={({ displayName, username }) =>
          displayName + " - " + username
        }
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              onChange={(e) => setSearchKey(e.target.value)}
              label={label}
              helperText={helperText}
              error={error}
              variant="outlined"
            />
          );
        }}
      />
    </Box>
  );
};

export default SearchableSelect;
