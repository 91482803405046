import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const MyMenu = ({ id, anchorEl, open, onClose, rowId }) => {
  const handleMenuItemClick = (option) => {
    // Handle menu item click event here
    // You can customize the behavior based on the selected option
    console.log(`Menu option clicked: ${option}`);
    onClose();
  };

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem
        onClick={() => handleMenuItemClick(`Option 1 for Row ${rowId}`)}
      >
        Option 1
      </MenuItem>
      <MenuItem
        onClick={() => handleMenuItemClick(`Option 2 for Row ${rowId}`)}
      >
        Option 2
      </MenuItem>
      <MenuItem
        onClick={() => handleMenuItemClick(`Option 3 for Row ${rowId}`)}
      >
        Option 3
      </MenuItem>
    </Menu>
  );
};

export default MyMenu;
