import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const NoAuthRequired = ({ allowedRoles }) => {
  const { cookies } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  return !cookies?.auth ? (
    <Outlet />
  ) : (
    <Navigate to={from} state={{ from: location }} replace />
  );
};

export default NoAuthRequired;
