import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import UploadKYCDocumentModal from "../modals/UploadKYCDocumentModal";
import { ViewDocumentModal } from "../modals/ViewDocumentModal";
import { useState } from "react";
import WarningModal from "../modals/WarningModal";
import RejectRequestModal from "../modals/RejectRequestModal";

function createData(name, status, detail, action, attachment, id) {
  return { name, status, detail, action, attachment, id };
}

export default function Tier4Table({ tier4Details }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [viewDocModal, setViewDocModal] = useState({ open: false });
  const [uploadModal, setUploadModal] = useState({
    open: false,
    row: null,
  });
  const [modal, setModal] = useState({ openConfirm: false });
  const [rejectModal, setRejectModal] = useState({ open: false });

  const rows = tier4Details
    ? tier4Details?.map((tier4Detail) => {
        return createData(
          tier4Detail?.requirementType,
          tier4Detail?.status,
          tier4Detail?.verificationReference
            ? tier4Detail.verificationReference
            : "-----------------------------------",
          ["upload", "view", "verify", "reject"],
          tier4Detail?.attachment,
          tier4Detail.id
        );
      })
    : [
        createData("TIN", "pending", "", [
          "upload",
          "view",
          "verify",
          "reject",
        ]),
        createData("CAC", "pending", "", [
          "upload",
          "view",
          "verify",
          "reject",
        ]),
        createData("REFERENCE", "pending", "", [
          "upload",
          "view",
          "verify",
          "reject",
        ]),
      ];

  const complete =
    rows.length > 0 ? rows?.every((row) => row.status == "APPROVED") : false;

  return (
    <TableContainer sx={{ bgcolor: colors.primary[400] }}>
      <ViewDocumentModal modal={viewDocModal} setModal={setViewDocModal} />
      <UploadKYCDocumentModal modal={uploadModal} setModal={setUploadModal} />
      <WarningModal
        open={modal.openConfirm}
        setOpen={setModal}
        user={modal.user}
        url={modal.url}
        action={modal.action}
      />
      <RejectRequestModal
        isOpen={rejectModal.open}
        setIsOpen={setRejectModal}
        url={rejectModal.url}
        user={rejectModal.user}
        action={rejectModal.action}
      />
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Typography
          variant="h2"
          color={colors.grey[500]}
          fontWeight="bold"
          sx={{ mb: "15px" }}
        >
          Tier 4
        </Typography>
        <Typography
          variant="h4"
          bgcolor={complete ? colors.greenAccent[600] : colors.redAccent[600]}
          color={complete ? colors.greenAccent[200] : colors.redAccent[200]}
          p={1.2}
          height={44}
          borderRadius={1}
        >
          {complete ? "completed" : "not completed"}
        </Typography>
      </Box>
      <Table sx={{ minHeight: 180 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", fontSize: "14px" }}>
              KYC Requirement
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", fontSize: "14px" }}
              align="right"
            >
              Status
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", fontSize: "14px" }}
              align="right"
            >
              Details
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", fontSize: "14px" }}
              align="right"
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontSize: "14px",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontSize: "14px",
                  color:
                    row.status == "verified"
                      ? colors.greenAccent[300]
                      : colors.orangeAccent[300],
                }}
              >
                {row.status}
              </TableCell>
              <TableCell align="right">
                <TextField value={row.detail} disabled />
              </TableCell>
              <TableCell align="right" sx={{ minWidth: 390 }}>
                <Button
                  underline="none"
                  onClick={() => setUploadModal({ open: true, row: row })}
                  sx={{
                    backgroundColor: colors.orangeAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "8px 20px",
                  }}
                  disabled={row.status == "verified"}
                >
                  {row.action[0]}
                </Button>
                <Button
                  underline="none"
                  onClick={() =>
                    setViewDocModal({
                      open: true,
                      imgUrl: row?.attachment,
                    })
                  }
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    ml: 1,
                    padding: "8px 20px",
                  }}
                  // disabled={row.status == "verified"}
                >
                  {row.action[1]}
                </Button>
                <Button
                  underline="none"
                  onClick={() =>
                    setModal({
                      openConfirm: true,
                      user: row,
                      url: "/kyc-servicev2/api/v1/admin/kyc/approveOrReject/",
                      action: "approve kyc",
                    })
                  }
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    ml: 1,
                    padding: "8px 20px",
                  }}
                  disabled={row.status == "verified"}
                >
                  {row.action[2]}
                </Button>
                <Button
                  underline="none"
                  onClick={() =>
                    setRejectModal({
                      open: true,
                      user: row,
                      url: "/kyc-servicev2/api/v1/admin/kyc/approveOrReject/",
                      action: "reject user kyc",
                    })
                  }
                  sx={{
                    backgroundColor: colors.redAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    ml: 1,
                    padding: "8px 20px",
                  }}
                  // disabled={row.status == "verified"}
                >
                  {row.action[3]}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
