import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Field } from "formik";
import React from "react";

const useStyles = makeStyles({
  textArea: {
    width: "440%",
  },

  inputFile: {
    "& input[type=file]": {
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    "& input[type=file]::-webkit-file-upload-button": {
      display: "none",
    },
  },
});

const FileUploadInput = ({ name, label, sx, error, helperText, accept }) => {
  const classes = useStyles();

  const handleFileChange = (event, field, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setFieldValue(field.name, file);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <Field label="Upload file" name={name}>
      {({ field, form }) => {
        const { setFieldValue } = form;
        return (
          <TextField
            {...field}
            fullWidth
            type="file"
            label={label}
            variant="outlined"
            value={undefined}
            className={classes.inputFile}
            error={error}
            helperText={helperText}
            onChange={(e) => handleFileChange(e, field, setFieldValue)}
            sx={sx}
            inputProps={{
              accept: accept,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      }}
    </Field>
  );
};

export default FileUploadInput;
