import { Formik, Form, Field } from "formik";
import {
  Button,
  Modal,
  Typography,
  TextField,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import * as yup from "yup";
import useAuth from "../../hooks/useAuth";
import { makeStyles } from "@mui/styles";
import { generateReqId } from "../../utils/generateReqId";
import { generateUrlId } from "../../utils/generateUrlID";

const useStyles = makeStyles({
  inputFile: {
    "& input[type=file]": {
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    "& input[type=file]::-webkit-file-upload-button": {
      display: "none",
    },
  },
});

const SpreadSheetUploadModal = ({ modal, setModal }) => {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const { success } = useAuth();

  const handleFormSubmit = async ({ file }) => {
    console.log(file);
    const { action = "" } = modal;
    if (action == "bulk transfer") {
      try {
        const response = await axiosPrivate.postForm(
          `/logs-service/api/v1/requestlog/create/bulk/${generateUrlId()}/%20Bulk%20Transfer/BulkTransferToNonWaya`,
          {
            file,
            customerName: "Bulk Users",
            requestId: generateReqId(),
            requestType: "BulkTransferToNonWaya",
          }
        );
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await axiosPrivate.postForm(
          `https://services.staging.wayagram.ng/wayagram-service/main/groups/admin/excel-upload`,
          {
            excelGroupRecords: file,
          },
          {
            params: {
              sso_id: 1417,
            },
          }
        );
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    }
    handleClose();
  };

  const handleFileChange = (event, field, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setFieldValue(field.name, file);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleClose = () => {
    setModal({ open: false });
  };

  const initialValues = {
    file: "",
  };

  const checkoutSchema = yup.object().shape({
    file: yup.mixed().required("Required"),
  });

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: "40%",
          backgroundColor: colors.primary[400],
          p: 5,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography variant="h5" textAlign="center" mb={5} mt={2}>
          {modal.title}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={checkoutSchema}
          onSubmit={handleFormSubmit}
        >
          {({ touched, errors }) => (
            <Form>
              <Field label="Upload JPG file" name="file">
                {({ field, form }) => {
                  const { setFieldValue } = form;
                  return (
                    <TextField
                      {...field}
                      fullWidth
                      type="file"
                      variant="outlined"
                      value={undefined}
                      error={!!touched.file && !!errors.file}
                      helperText={touched.file && errors.file}
                      inputProps={{
                        accept:
                          ".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      }}
                      onChange={(e) =>
                        handleFileChange(e, field, setFieldValue)
                      }
                      className={classes.inputFile}
                      sx={{ gridColumn: "span 4", mb: 3 }}
                    />
                  );
                }}
              </Field>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ p: 2, mt: 2 }}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default SpreadSheetUploadModal;
