import useCookies from "react-cookie/cjs/useCookies";
import React, { useState, createContext, useContext } from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import MyProSidebar from "./MyProSidebar";

export const MyProSidebarProvider = ({ children }) => {
  return (
    <ProSidebarProvider>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <MyProSidebar />
        {children}
      </div>
    </ProSidebarProvider>
  );
};
