import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

const SendOTPModal = ({ isOpen, setIsOpen, setRecieverModal }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Close the modal
  const handleClose = () => {
    setIsOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    width: 520,
    height: 320,
    bgcolor: colors.primary[400],
    border: "1px solid #000",
    boxShadow: 24,
    p: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h5" component="h2" align="center">
          Send OTP
        </Typography>
        <Box
          sx={{
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          {/* Buttons */}
          <Box display="flex" justifyContent="space-between" marginBottom={3}>
            <Button
              variant="outlined"
              sx={{
                marginRight: 4,
                width: "190px",
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              underline="none"
              onClick={() =>
                setRecieverModal({
                  open: true,
                  url: "/auth-service/api/v1/password/change-password/byEmail",
                })
              }
            >
              Send OTP for Password via Email
            </Button>
            <Button
              variant="outlined"
              sx={{
                width: "190px",
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() =>
                setRecieverModal({
                  open: true,
                  url: "/auth-service/api/v1/password/change-password/byPhone",
                })
              }
            >
              Send OTP for Password via Phone
            </Button>
          </Box>
          <Box>
            <Button
              variant="outlined"
              sx={{
                width: "190px",
                marginRight: 7,
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() =>
                setRecieverModal({
                  open: true,
                  url: "/auth-service/api/v1/pin/change-pin/byEmail",
                })
              }
            >
              Send OTP for Pin via Email
            </Button>
            <Button
              variant="outlined"
              sx={{
                width: "190px",
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() =>
                setRecieverModal({
                  open: true,
                  url: "/auth-service/api/v1/pin/change-pin/byPhone",
                })
              }
            >
              Send OTP for Pin via Phone
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SendOTPModal;
