import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import SearchTable from "../../components/SearchTable";

const WayaPosTransactions = () => {
  const [details, setDetails] = useState({});
  const [newDetails, setNewDetails] = useState([]);

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const getTransactions = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayapos.ng/transaction-service/api/v1/transactions/filters",
        {
          params: {
            pageNo: 1,
            pageSize: 20,
          },
        }
      );
      setNewDetails(response?.data?.respBody);
      return response?.data?.respBody;
    } catch (error) {
      console.log(error);
    }
  };

  const getTransactionStats = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayapos.ng/transaction-service/api/v1/transactions/metric/data"
      );
      return response?.data?.respBody;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.transactions?.filter(
        (transaction) =>
          transaction.de37 === searchKey ||
          transaction?.de43?.toLowerCase() === searchKey.toLowerCase()
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getDetails = async () => {
      const [transactions, transactionStats] = await Promise.all([
        getTransactions(),
        getTransactionStats(),
      ]);
      setDetails({ transactions, transactionStats });
    };
    getDetails();
  }, []);

  const columns = [
    { field: "de37", headerName: "Reference Id", width: 100 },
    {
      field: "de43",
      headerName: "Business Name",
      width: 200,
    },
    {
      field: "channel",
      headerName: "Transactions Category",
      width: 100,
    },
    {
      field: "terminalType",
      headerName: "Terminal Type",
      width: 100,
    },
    {
      field: "paymentMethod",
      headerName: "Payment Method",
      cellClassName: "name-column--cell",
      width: 100,
    },
    {
      field: "processedBy",
      headerName: "Payment Processor",
      width: 100,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
    },
    {
      field: "transactionDate",
      headerName: "Created At",
      width: 100,
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      width: 100,
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 100,
    //   renderCell: (params) => {
    //     return (
    //       <div data-gid={`row${params.id}-col-actions`}>
    //         <IconMenu rowId={params.id} /> {/* Render the icon for the menu */}
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}

      {/* GRID & CHARTS */}
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ justifyContent: "space-between" }}
      >
        <Grid xs={12} sm={12} md={6} lg={3.6} xl={3}>
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
          >
            <Header
              title="TRANSACTION HISTORY"
              subtitle="Manage WayaPos Transaction History"
            />
          </Box>
        </Grid>

        <Box mx={2} mt={1}>
          <SearchTable handleSearch={handleSearch} placeholder="Reference Id" />
        </Box>

        <Box
          m="10px"
          width="100%"
          height="65vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row?.transactionDate;
            }}
            rows={newDetails}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default WayaPosTransactions;
