import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { generateReqId } from "../../utils/generateReqId";
import useAuth from "../../hooks/useAuth";
import naijaStateLocalGovernment from "naija-state-local-government";

const KYCModal = ({
  isOpen,
  setModal,
  user,
  businessTypes,
  setWarningModal,
  setRejectModal,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { success, snackError } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [isEdit, setIsEdit] = useState(false);
  const {
    merchantId,
    userId,
    businessName,
    businessDescription,
    businessIndustry,
    businessType,
    businessNationality,
    businessLogoUrl,
    businessEmail,
    businessPhoneNumber,
    country,
    state,
    lga,
    city,
    streetAddress,
    usePaymentLink,
    websiteLink,
    appLink,
    facebookUrl,
    instagramUrl,
    twitterUrl,
    businessAccountProvider,
    businessAccountNumber,
    businessAccountName,
    businessRegistrationType,
    memartCertificateUrl,
    cacItCertificateUrl,
    cacBnCertificateUrl,
    incorporationCertificateUrl,
    businessNameCertificateUrl,
    businessRegistrationNumber,
    tin,
    bvn,
    businessAddressProveType,
    businessAddressProveUrl,
    identificationType,
    identificationUrl,
    approvalStatus,
    statusModifiedBy,
    statusLastModifiedOn,
    reasonForRejection,
    createdAt,
    updatedAt,
    deleted,
    deletedBy,
    dateDeleted,
  } = user;

  const toggleEdit = async () => {
    setIsEdit(!isEdit);
  };

  const handleReject = async () => {
    setRejectModal({ open: true, action: "reject kyc" });
  };

  const handleApprove = () => {
    setWarningModal({ openConfirm: true, action: "accept kyc" });
  };

  const handleFormSubmit = async (values) => {
    const {
      legalBusinessName,
      businessDescription,
      typeOfIndustry,
      typeOfBusiness,
      email,
      phone,
      country,
      state,
      city,
      LGA,
      streetAddress,
      websiteUrl,
      mobileAppUrl,
      facebook,
      instagram,
      twitter,
      accountSource,
      accountNumber,
      accountName,
      typeOfRegistration,
      formCAC,
      registrationNumber,
      TIN,
      BVN,
      proofOfBusinessAddress,
      idCard,
    } = values;
    try {
      const response = await axiosPrivate.post(
        "/activity-logservice/api/v1/requestlog/create",
        {
          customerName: businessName,
          payload: JSON.stringify({
            merchantId,
            userId: null,
            businessName: legalBusinessName,
            businessDescription,
            businessIndustry: typeOfIndustry,
            businessType: typeOfBusiness,
            businessNationality: country,
            businessLogoUrl,
            businessEmail: email,
            businessPhoneNumber: phone,
            country: country,
            state: state,
            lga: LGA,
            city: city,
            streetAddress: streetAddress,
            usePaymentLink,
            websiteLink: websiteUrl,
            appLink: mobileAppUrl,
            facebookUrl: facebook,
            instagramUrl: instagram,
            twitterUrl: twitter,
            businessAccountProvider: accountSource,
            businessAccountNumber: accountNumber,
            businessAccountName: accountName,
            businessRegistrationType: typeOfRegistration,
            memartCertificateUrl,
            cacItCertificateUrl: formCAC,
            cacBnCertificateUrl,
            incorporationCertificateUrl,
            businessNameCertificateUrl,
            businessRegistrationNumber: registrationNumber,
            tin: TIN,
            bvn: BVN,
            businessAddressProveType: proofOfBusinessAddress,
            businessAddressProveUrl,
            identificationType: idCard,
            identificationUrl,
            reasonForRejection,
          }),
          requestId: generateReqId(),
          requestType: "kycUpdate",
        }
      );
    } catch (error) {
      console.log(error);
    }
    // handleClose();
  };

  const initialValues = {
    legalBusinessName: businessName,
    businessDescription: businessDescription,
    typeOfIndustry: businessIndustry?.name,
    typeOfBusiness: businessType,
    email: businessEmail,
    phone: businessPhoneNumber,
    country: country,
    state: state,
    city: city,
    LGA: lga,
    streetAddress: streetAddress,
    websiteUrl: websiteLink,
    mobileAppUrl: appLink,
    facebook: facebookUrl,
    instagram: instagramUrl,
    twitter: twitterUrl,
    accountSource: businessAccountProvider,
    accountNumber: businessAccountNumber,
    accountName: businessAccountName,
    typeOfRegistration: businessRegistrationType,
    formCAC: cacBnCertificateUrl,
    registrationNumber: businessRegistrationNumber,
    TIN: tin,
    BVN: bvn,
    proofOfBusinessAddress: businessAddressProveType,
    idCard: identificationType,
  };

  const checkoutSchema = yup.object().shape({
    legalBusinessName: yup.string(),
    businessDescription: yup.string(),
    typeOfIndustry: yup.string(),
    typeOfBusiness: yup.string(),
    email: yup.string().email(),
    phone: yup.number(),
    country: yup.string(),
    state: yup.string(),
    city: yup.string(),
    LGA: yup.string(),
    streetAddress: yup.string(),
    websiteUrl: yup.string(),
    mobileAppUrl: yup.string(),
    facebook: yup.string(),
    instagram: yup.string(),
    twitter: yup.string(),
    accountSource: yup.string(),
    accountNumber: yup.number(),
    accountName: yup.string(),
    typeOfRegistration: yup.string(),
    formCAC: yup.string().nullable(),
    registrationNumber: yup.string(),
    TIN: yup.string(),
    BVN: yup.number(),
    proofOfBusinessAddress: yup.string(),
    idCard: yup.string(),
  });

  const countries = [{ name: "NIGERIA", value: "Nigeria" }];

  const typesOfBusiness = [
    {
      name: "REGISTERED BUSINESS",
      value: "REGISTERED_BUSINESS",
    },
  ];

  const typesOfRegistration = [
    {
      name: "INCORPORATED TRUSTEES",
      value: "INCORPORATED_TRUSTEES",
    },
    {
      name: "INCORPORATED COMPANY",
      value: "INCORPORATED_COMPANY",
    },
    {
      name: "BUSINESS NAME REGISTRATION",
      value: "BUSINESS_NAME_REGISTRATION",
    },
  ];

  const proofOfBusinessAddresses = [
    {
      name: "UTILITY BILL",
      value: "UTILITY_BILL",
    },
  ];

  const idCards = [
    {
      name: "VOTERS CARD",
      value: "VOTERS_CARD",
    },
    {
      name: "NATIONAL ID",
      value: "NATIONAL_ID",
    },
  ];

  const handleClose = () => {
    setIsEdit(false);
    setModal((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: "60%",
          height: "80%",
          backgroundColor: colors.primary[400],
          p: 3,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
          borderRadius: "5px",
        }}
      >
        <Box
          sx={{ textAlign: "center", marginBottom: "20px", marginTop: "10px" }}
        >
          <Typography variant="h3">Update User KYC</Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "90%",
            overflowY: "scroll",
          }}
        >
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Button
                  onClick={() => toggleEdit()}
                  type={!isEdit ? "submit" : "reset"}
                  color="secondary"
                  variant="contained"
                  sx={{ position: "absolute", right: 40, top: 32 }}
                >
                  {!isEdit ? "Edit" : "Save"}
                </Button>
                <Box padding={5}>
                  <Box
                    display="grid"
                    gap="20px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                      marginBottom: "10px",
                    }}
                  >
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Legal Business Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.legalBusinessName}
                      name="legalBusinessName"
                      error={
                        !!touched.legalBusinessName &&
                        !!errors.legalBusinessName
                      }
                      helperText={
                        touched.legalBusinessName && errors.legalBusinessName
                      }
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Business Description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.businessDescription}
                      name="businessDescription"
                      error={
                        !!touched.businessDescription &&
                        !!errors.businessDescription
                      }
                      helperText={
                        touched.businessDescription &&
                        errors.businessDescription
                      }
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      label="Type of Industry"
                      fullWidth
                      disabled={!isEdit}
                      select
                      name="typeOfIndustry"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.typeOfIndustry}
                      sx={{ gridColumn: "span 2" }}
                    >
                      {businessTypes.map((option, index) => (
                        <MenuItem key={index} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Type of Business"
                      fullWidth
                      disabled={!isEdit}
                      select
                      name="typeOfBusiness"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.typeOfBusiness}
                      sx={{ gridColumn: "span 2" }}
                    >
                      {typesOfBusiness.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                      name="phone"
                      error={!!touched.phone && !!errors.phone}
                      helperText={touched.phone && errors.phone}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      label="Country"
                      fullWidth
                      disabled={!isEdit}
                      select
                      name="country"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.country}
                      sx={{ gridColumn: "span 2" }}
                    >
                      {countries.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="State"
                      fullWidth
                      disabled={!isEdit}
                      select
                      name="state"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.state}
                      sx={{ gridColumn: "span 2" }}
                    >
                      {naijaStateLocalGovernment
                        .states()
                        .map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                      label="City"
                      fullWidth
                      disabled={!isEdit}
                      select
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.city}
                      sx={{ gridColumn: "span 2" }}
                    >
                      {naijaStateLocalGovernment.lgas(values?.state)
                        ? naijaStateLocalGovernment
                            .lgas(values?.state)
                            .lgas?.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))
                        : []}
                    </TextField>
                    <TextField
                      label="Local Government Area"
                      fullWidth
                      disabled={!isEdit}
                      select
                      name="LGA"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.LGA}
                      sx={{ gridColumn: "span 2" }}
                    >
                      {naijaStateLocalGovernment.lgas(values?.state)
                        ? naijaStateLocalGovernment
                            .lgas(values?.state)
                            .lgas?.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))
                        : []}
                    </TextField>
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Street Address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.streetAddress}
                      name="streetAddress"
                      error={!!touched.streetAddress && !!errors.streetAddress}
                      helperText={touched.streetAddress && errors.streetAddress}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Website Url"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.websiteUrl}
                      name="websiteUrl"
                      error={!!touched.websiteUrl && !!errors.websiteUrl}
                      helperText={touched.websiteUrl && errors.websiteUrl}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Mobile App Url"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mobileAppUrl}
                      name="mobileAppUrl"
                      error={!!touched.mobileAppUrl && !!errors.mobileAppUrl}
                      helperText={touched.mobileAppUrl && errors.mobileAppUrl}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Facebook"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.facebook}
                      name="facebook"
                      error={!!touched.facebook && !!errors.facebook}
                      helperText={touched.facebook && errors.facebook}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Instagram"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.instagram}
                      name="instagram"
                      error={!!touched.instagram && !!errors.instagram}
                      helperText={touched.instagram && errors.instagram}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Twitter"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.twitter}
                      name="twitter"
                      error={!!touched.twitter && !!errors.twitter}
                      helperText={touched.twitter && errors.twitter}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Account Source"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.accountSource}
                      name="accountSource"
                      error={!!touched.accountSource && !!errors.accountSource}
                      helperText={touched.accountSource && errors.accountSource}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Account Number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.accountNumber}
                      name="accountNumber"
                      error={!!touched.accountNumber && !!errors.accountNumber}
                      helperText={touched.accountNumber && errors.accountNumber}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Account Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.accountName}
                      name="accountName"
                      error={!!touched.accountName && !!errors.accountName}
                      helperText={touched.accountName && errors.accountName}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      label="Type Of Registration"
                      fullWidth
                      disabled={!isEdit}
                      select
                      name="typeOfRegistration"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.typeOfRegistration}
                      sx={{ gridColumn: "span 2" }}
                    >
                      {typesOfRegistration.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Form CAC IT/1"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.formCAC}
                      name="formCAC"
                      error={!!touched.formCAC && !!errors.formCAC}
                      helperText={touched.formCAC && errors.formCAC}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Registration Number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.registrationNumber}
                      name="registrationNumber"
                      error={
                        !!touched.registrationNumber &&
                        !!errors.registrationNumber
                      }
                      helperText={
                        touched.registrationNumber && errors.registrationNumber
                      }
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Tax Identification Number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.TIN}
                      name="TIN"
                      error={!!touched.TIN && !!errors.TIN}
                      helperText={touched.TIN && errors.TIN}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      variant="filled"
                      type="text"
                      label="Bank Verification Number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.BVN}
                      name="BVN"
                      error={!!touched.BVN && !!errors.BVN}
                      helperText={touched.BVN && errors.BVN}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      label="Proof of Business Address"
                      fullWidth
                      disabled={!isEdit}
                      select
                      name="proofOfBusinessAddress"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.proofOfBusinessAddress}
                      sx={{ gridColumn: "span 2" }}
                    >
                      {proofOfBusinessAddresses.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Valid ID Card"
                      fullWidth
                      disabled={!isEdit}
                      select
                      name="idCard"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.idCard}
                      sx={{ gridColumn: "span 2" }}
                    >
                      {idCards.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Button
                      onClick={() => handleApprove()}
                      variant="contained"
                      sx={{
                        width: "48%",
                        p: 2,
                        bgcolor: colors.greenAccent[400],
                      }}
                    >
                      Accept
                    </Button>
                    <Button
                      onClick={() => handleReject()}
                      variant="contained"
                      sx={{
                        width: "48%",
                        p: 2,
                        bgcolor: colors.orangeAccent[400],
                      }}
                    >
                      Reject
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default KYCModal;
