import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { tokens } from "../../theme";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import blobToBase64 from "../../utils/blobToBase64";
import { useState } from "react";

const useStyles = makeStyles({
  textArea: {
    gridColumn: "span 4",
  },

  inputFile: {
    "& input[type=file]": {
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    "& input[type=file]::-webkit-file-upload-button": {
      display: "none",
    },
  },
});

const NewTerminalModal = ({ isOpen, setModal, terminalTypes }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { success, snackError } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event, field, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64 = await blobToBase64(file);
        setFieldValue(field.name, base64);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    console.log(values);
    const { cost, description, name, file, serialNumber } = values;

    try {
      const response = await axiosPrivate.post(
        "https://services.staging.wayapos.ng/terminal-service/api/v1/terminals/createterminal",
        {
          actualTerminalName: name.toUpperCase(),
          description: description,
          image: file,
          numberOfPaymentTime: 0,
          preferredTerminalName: name.toUpperCase(),
          terminalCost: cost,
          terminalId: "",
          terminalSerialNumber: serialNumber,
          terminalType: name.toUpperCase(),
        }
      );
      success(response?.data?.respDesc);
      handleClose();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const initialValues = {
    serialNumber: "",
    cost: "",
    type: "",
    name: "",
    description: "",
    file: "",
  };

  const checkoutSchema = yup.object().shape({
    serialNumber: yup.string().required("Required"),
    cost: yup.number().required("Required"),
    type: yup.object().required("Required"),
    name: yup.string().required("Required"),
    description: yup.string(),
    file: yup.mixed(),
  });

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  const handleSelectChange = (event, setFieldValue) => {
    const { terminalCategory, amount } = event.target.value;
    setFieldValue("type", event.target.value);
    setFieldValue("cost", amount);
    setFieldValue("name", terminalCategory);
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "30px" }}
                >
                  Add New Terminal Device
                </Typography>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                    marginBottom: "10px",
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Terminal Serial Number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.serialNumber}
                    name="serialNumber"
                    error={!!touched.serialNumber && !!errors.serialNumber}
                    helperText={touched.serialNumber && errors.serialNumber}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    label="Terminal Type"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={(e) => handleSelectChange(e, setFieldValue)}
                    value={values.type}
                    name="type"
                    error={!!touched.type && !!errors.type}
                    helperText={touched.type && errors.type}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {terminalTypes?.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {type.terminalName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    disabled
                    type="text"
                    label="Terminal Cost"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.cost}
                    name="cost"
                    error={!!touched.cost && !!errors.cost}
                    helperText={touched.cost && errors.cost}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Terminal Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextareaAutosize
                    className={classes.textArea}
                    minRows={4}
                    variant="filled"
                    type="text"
                    label="Description"
                    placeholder="A brief description of what is obtainable using the terminal POS..."
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    name="description"
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                  />
                  <Field label="Upload JPG file" name="file">
                    {({ field, form }) => {
                      const { setFieldValue } = form;
                      return (
                        <TextField
                          {...field}
                          fullWidth
                          type="file"
                          variant="outlined"
                          value={undefined}
                          className={classes.inputFile}
                          onChange={(e) =>
                            handleFileChange(e, field, setFieldValue)
                          }
                          sx={{ gridColumn: "span 4", mb: 2 }}
                        />
                      );
                    }}
                  </Field>
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  disabled={isLoading}
                  variant="contained"
                  sx={{ width: "100%", p: 2 }}
                >
                  Add Terminal
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default NewTerminalModal;
