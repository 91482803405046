import React from "react";
import { Box, IconButton, Modal, Tab, Tabs } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import PayWithEmail from "./screens/PayWithEmail";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useState } from "react";
import PayWithWayaId from "./screens/PayWithWayaId";
import PayToAccount from "./screens/PayToAccount";
import PayToPhone from "./screens/PayToPhone";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const TransferToOtherAccountModal = ({
  isOpen,
  setModal,
  setOTPModal,
  OTPModal,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNext = () => {
    setValue((prevValue) => (prevValue + 1) % 4);
  };

  const handlePrev = () => {
    setValue((prevValue) => (prevValue + 3) % 4);
  };

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={isOpen.open} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <IconButton onClick={handlePrev}>
            <ArrowBackIos />
          </IconButton>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              bgcolor: colors.primary[400],
            }}
            aria-label="My Tabs"
          >
            <Tab
              sx={{
                borderRadius: "5px",
                ":hover": { color: colors.primary[300] },
                "&.Mui-selected": {
                  color: colors.grey[100],
                  bgcolor: colors.orangeAccent[700],
                },
              }}
              label="Pay With Email"
              id="tier-0"
              aria-controls="tabpanel-0"
            />
            <Tab
              sx={{
                borderRadius: "5px",
                ":hover": { color: colors.primary[300] },
                "&.Mui-selected": {
                  color: colors.grey[100],
                  bgcolor: colors.orangeAccent[700],
                },
              }}
              label="Pay With WAYA ID"
              id="tier-1"
              aria-controls="tabpanel-1"
            />
            <Tab
              sx={{
                borderRadius: "5px",
                ":hover": { color: colors.primary[300] },
                "&.Mui-selected": {
                  color: colors.grey[100],
                  bgcolor: colors.orangeAccent[700],
                },
              }}
              label="Pay To Account"
              id="tier-2"
              aria-controls="tabpanel-2"
            />
            <Tab
              sx={{
                borderRadius: "5px",
                ":hover": { color: colors.primary[300] },
                "&.Mui-selected": {
                  color: colors.grey[100],
                  bgcolor: colors.orangeAccent[700],
                },
              }}
              label="Pay To Phone"
              id="tier-3"
              aria-controls="tabpanel-3"
            />
          </Tabs>
          <IconButton onClick={handleNext}>
            <ArrowForwardIos />
          </IconButton>
        </Box>
        <TabPanel value={value} index={0}>
          <PayWithEmail setOTPModal={setOTPModal} OTPModal={OTPModal} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PayWithWayaId setOTPModal={setOTPModal} OTPModal={OTPModal} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PayToAccount setOTPModal={setOTPModal} OTPModal={OTPModal} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PayToPhone setOTPModal={setOTPModal} OTPModal={OTPModal} />
        </TabPanel>
      </Box>
    </Modal>
  );
};

export default TransferToOtherAccountModal;
