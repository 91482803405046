import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import IconMenu from "../../components/IconMenu";
import SearchTable from "../../components/SearchTable";
import PaymentRequestModal from "../../components/modals/PaymentRequestModal";

const PaymentRequest = () => {
  const [details, setDetails] = useState(null);
  const [result, setResult] = useState([]);
  const [modal, setModal] = useState({ open: false, title: "" });
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const getPaymentRequest = async () => {
    try {
      const response = await axiosPrivate.get(
        "/payment-request/payment-request-transactions",
        {
          params: {
            isWaya: true,
            page: 0,
            size: 100,
          },
        }
      );
      setResult(response?.data.data?.request);
      return response?.data.data?.request;
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalPending = async () => {
    try {
      const response = await axiosPrivate.get(
        "/payment-request/payment-request/all-transactions-filter",
        {
          params: {
            filter: "PENDING",
          },
        }
      );
      return response?.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalPaid = async () => {
    try {
      const response = await axiosPrivate.get(
        "/payment-request/payment-request/all-transactions-filter",
        {
          params: {
            filter: "PAID",
          },
        }
      );
      setDetails(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.paymentRequest?.request.filter(
        (request) => request.reference === searchKey
      );
      setResult(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const requestDetails = async () => {
      const [paymentRequest, totalPaid, totalPending] = await Promise.all([
        getPaymentRequest(),
        getTotalPaid(),
        getTotalPending(),
      ]);
      setDetails({ paymentRequest, totalPaid, totalPending });
    };
    requestDetails();
  }, []);

  const columns = [
    {
      field: "createdAt",
      headerName: "Date Created",
      width: 140,
      renderCell: ({ row: { createdAt } }) => {
        return new Date(Date(createdAt)).toDateString();
      },
    },
    {
      field: "senderName",
      headerName: "Request Creator",
      width: 150,
    },
    {
      field: "receiverName",
      headerName: "Reciever",
      width: 150,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 150,
    },
    {
      field: "reference",
      headerName: "Reference",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div data-gid={`row${params.id}-col-actions`}>
            <IconMenu rowId={params.id} /> {/* Render the icon for the menu */}
          </div>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      {/* GRID & CHARTS */}
      <PaymentRequestModal
        isOpen={modal.open}
        setModal={setModal}
        title={modal.title}
      />
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ justifyContent: "space-between" }}
      >
        <Grid xs={12} sm={12} md={6} lg={4} xl={3}>
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
          >
            <Header
              title="PAYMENT REQUESTS"
              subtitle="Manage Payment Requests"
            />
          </Box>
        </Grid>
        <Box display="flex" marginTop={1} justifyContent="end" paddingX={1.3}>
          <Box>
            <SearchTable
              handleSearch={handleSearch}
              placeholder="Reference Id"
            />
          </Box>
          <Box>
            <Button
              onClick={() =>
                setModal({ open: true, title: "Send Payment Request" })
              }
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginLeft: "15px",
              }}
            >
              <AddIcon sx={{ mr: "10px" }} />
              New Payment Request
            </Button>
          </Box>
        </Box>

        <Box
          m="10px"
          width="100%"
          height="70vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={result}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default PaymentRequest;
