import React, { useState } from "react";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import PasswordField from "../../components/PasswordField";
import BackButton from "../../components/BackButton";

const FORGET_PASSWORD_URL = "/auth-service/api/v1/password/forgot-password";

const ForgetPassword = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { snackError, success, otpEmailOrPhone } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = async (values, actions) => {
    const { newPassword, OTP } = values;
    try {
      const response = await axios.post(FORGET_PASSWORD_URL, {
        newPassword,
        OTP,
        emailOrPhone: otpEmailOrPhone,
      });

      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const initialValues = {
    newPassword: "",
    confirmNewPassword: "",
    OTP: "",
  };

  const checkoutSchema = yup.object().shape({
    newPassword: yup
      .string()
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
        "Password must be atleast 8 characters and contain one uppercase letter, one special character"
      )
      .required("Password is required"),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
    OTP: yup
      .string()
      .matches(/^[0-9]{6}$/, "Value must be exactly six 0s")
      .required("Value is required"),
  });

  return (
    <Box
      m="20px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="90vh"
      alignItems="center"
      textAlign="center"
    >
      <BackButton path="/login" />
      <Box mb="30px">
        <Typography
          variant="h2"
          color={colors.grey[100]}
          fontWeight="bold"
          sx={{ mb: "5px" }}
        >
          CREATE NEW PASSWORD
        </Typography>
        <Typography variant="h5" color={colors.orangeAccent[600]}>
          Enter your new password and the OTP sent to your email
        </Typography>
      </Box>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
              }}
            >
              <PasswordField
                fullWidth
                variant="filled"
                type={showPassword ? "text" : "password"}
                label="New Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.newPassword}
                name="newPassword"
                error={!!touched.newPassword && !!errors.newPassword}
                helperText={touched.newPassword && errors.newPassword}
                sx={{ gridColumn: "span 16" }}
                iconEnd={<Visibility />}
                setShowPassword={setShowPassword}
              />
              <PasswordField
                fullWidth
                variant="filled"
                type={showPassword ? "text" : "password"}
                label="Confirm New Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmNewPassword}
                name="confirmNewPassword"
                error={
                  !!touched.confirmNewPassword && !!errors.confirmNewPassword
                }
                helperText={
                  touched.confirmNewPassword && errors.confirmNewPassword
                }
                sx={{ gridColumn: "span 16" }}
                iconEnd={<Visibility />}
                setShowPassword={setShowPassword}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="OTP"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.OTP}
                name="OTP"
                error={!!touched.OTP && !!errors.OTP}
                helperText={touched.OTP && errors.OTP}
                sx={{ gridColumn: "span 16" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                fullWidth
                disabled={isSubmitting}
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ gridColumn: "span 16", p: 2, mt: 1 }}
              >
                Submit
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ForgetPassword;
