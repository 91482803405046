import {
  TextField,
  Modal,
  Button,
  Box,
  useTheme,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { tokens } from "../../theme";

const style = {
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: 480,
  height: 320,
  border: "1px solid #000",
  boxShadow: 24,
  p: 5,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
};

let currentOTPIndex = 0;

export default ({ handleOTPSubmit, modal, setModal }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeOtp, setActiveOtp] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const otpRef = useRef();

  const handleClose = () => {
    setModal({ open: false });
    setOtp(new Array(6).fill(""));
  };

  useEffect(() => {
    otpRef.current?.focus();
  }, [modal.open, activeOtp]);

  const handleChange = ({ target }) => {
    const { value } = target;
    const newOTP = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);
    if (!value) setActiveOtp(currentOTPIndex - 1);
    else setActiveOtp(currentOTPIndex + 1);
    setOtp(newOTP);
  };

  const handleKeyDown = ({ key }, index) => {
    currentOTPIndex = index;
    if (key === "Backspace") setActiveOtp(currentOTPIndex - 1);
  };

  return (
    <div>
      <Modal
        open={modal.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        display="flex"
      >
        <Box sx={style} bgcolor={colors.primary[400]} textAlign="center">
          <Box mb="10px">
            <Typography
              variant="h2"
              color={colors.grey[100]}
              fontWeight="bold"
              sx={{ mb: "5px" }}
            >
              OTP Authentication
            </Typography>
            <Typography variant="h5" color={colors.orangeAccent[600]}>
              Please enter OTP sent to you
            </Typography>
          </Box>
          <Box>
            {otp.map((_, index) => {
              return (
                <Fragment key={index}>
                  <TextField
                    type="number"
                    value={otp[index]}
                    inputRef={index === activeOtp ? otpRef : null}
                    onChange={handleChange}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    sx={{
                      width: 50,
                      height: 40,
                      marginX: 1,
                    }}
                  />
                </Fragment>
              );
            })}
          </Box>
          <Box>
            <Button
              onClick={() => {
                handleOTPSubmit(otp, setIsSubmitting);
                handleClose();
              }}
              fullWidth
              disabled={isSubmitting}
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ gridColumn: "span 16", py: 2, px: 4, mt: 1 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
