import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import SearchTable from "../../components/SearchTable";
import RejectRequestModal from "../../components/modals/RejectRequestModal";
import WarningModal from "../../components/modals/WarningModal";

const WayaquickRequestLogs = () => {
  const [details, setDetails] = useState();
  const [newDetails, setNewDetails] = useState([]);
  const [rejectModal, setRejectModal] = useState({
    open: false,
    url: "",
    user: {},
  });
  const [modal, setModal] = useState({
    openConfirm: false,
    url: "",
    action: "",
  });
  const { success, snackError, cookies } = useAuth();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const getRequestLogs = async () => {
    try {
      const response = await axiosPrivate.get(
        "/activity-logservice/api/v1/requestlog/list"
      );
      return response?.data;
    } catch (error) {
      if (error.response.status === 403) {
        snackError("Not Authorized");
      } else {
        console.log(error);
      }
    }
  };

  const getTotalRequestLogs = async () => {
    try {
      const response = await axiosPrivate.get(
        "/activity-logservice/api/v1/requestlog/total-request"
      );
      return response?.data;
    } catch (error) {
      if (error.response.status === 403) {
        snackError("Not Authorized");
      } else {
        console.log(error);
      }
    }
  };

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.requestLogs?.filter(
        (request) =>
          request.requestId === searchKey ||
          request?.createdBy?.toLowerCase() === searchKey.toLowerCase()
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const QRDetails = async () => {
      const [requestLogs, totalRequestLogs] = await Promise.all([
        getRequestLogs(),
        getTotalRequestLogs(),
      ]);

      setDetails({
        requestLogs,
        totalRequestLogs,
      });

      let pendingLogs = requestLogs?.filter(
        (request) => request?.status?.toLowerCase() == "initiated"
      );
      setNewDetails(pendingLogs);
    };
    QRDetails();
  }, []);

  const columns = [
    { field: "requestId", headerName: "Request Id", width: 150 },
    {
      field: "createdBy",
      headerName: "Requested By",
      width: 130,
      renderCell: ({ row: { createdBy } }) =>
        createdBy ? createdBy : "------------",
    },
    {
      field: "initiatorRole",
      headerName: "Initiator Role",
      cellClassName: "name-column--cell",
      width: 150,
    },
    {
      field: "customerName",
      headerName: "Requested For",
      headerAlign: "left",
      align: "left",
      width: 130,
      renderCell: ({ row: { customerName } }) =>
        customerName ? customerName : "------------",
    },
    {
      field: "requestType",
      headerName: "Request Type",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: ({ row: { status } }) => {
        return (
          <Typography
            color={
              status == "Approved"
                ? colors.greenAccent[400]
                : colors.redAccent[400]
            }
          >
            {status == "Initiated" ? "Pending" : status}
          </Typography>
        );
      },
    },
    {
      field: "dateCreated",
      headerName: "Date",
      width: 150,
      renderCell: ({ row: { dateCreated } }) => {
        return new Date(
          dateCreated[0],
          dateCreated[1] - 1,
          dateCreated[2],
          dateCreated[3],
          dateCreated[4]
        ).toLocaleString();
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Button
              onClick={() =>
                setModal({
                  openConfirm: true,
                  user: row,
                  action: "accept",
                  url: "/activity-logservice/api/v1/requestlog/process-request",
                })
              }
              disabled={row.status === "APPROVED"}
              underline="none"
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
              }}
            >
              Accept
            </Button>
            <Button
              onClick={() =>
                setRejectModal({
                  open: true,
                  user: row,
                  url: "/activity-logservice/api/v1/requestlog/process-request",
                })
              }
              disabled={row.status === "DECLINED"}
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                ml: 1,
                color: colors.grey[100],
              }}
            >
              Reject
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}

      <RejectRequestModal
        isOpen={rejectModal.open}
        setIsOpen={setRejectModal}
        url={rejectModal.url}
        user={rejectModal.user}
      />

      <WarningModal
        open={modal.openConfirm}
        action={modal.action}
        url={modal.url}
        user={modal.user}
        setOpen={setModal}
      />

      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title="REQUEST LOGS" subtitle="Manage WayaQuick Request Logs" />

        <Box display={"flex"} justifyContent={"space-between"}>
          <SearchTable
            handleSearch={handleSearch}
            placeholder="Request Id/ Requested By"
          />
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={details?.totalRequestLogs?.totalRequest}
              subtitle="Total Requests"
              icon={
                <PlaylistAddIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={details?.totalRequestLogs?.totalInitiated}
              subtitle="Total Initiated"
              icon={
                <ExitToAppIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={details?.totalRequestLogs?.totalApproved}
              subtitle="Total Approved"
              icon={
                <AssignmentTurnedInIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={details?.totalRequestLogs?.totalRejected}
              subtitle="Total Rejected"
              icon={
                <AssignmentLateIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={details?.totalRequestLogs?.totalFailed}
              subtitle="Total Failed"
              icon={
                <AssignmentLateIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Box
          m="10px"
          width="100%"
          height="63vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={newDetails ? newDetails : []}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default WayaquickRequestLogs;
