import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import SearchTable from "../../components/SearchTable";
import EventModal from "../../components/modals/EventModal";

const ManageEvent = () => {
  const [details, setDetails] = useState([]);
  const [newDetails, setNewDetails] = useState([]);
  const [modal, setModal] = useState({ open: false });
  const { cookies } = useAuth();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const getEvents = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayagram.ng/wayagram-service/all-posts/admin-get-all-events",
        {
          params: {
            page: 1,
            limit: 20,
            sso_id: cookies?.auth?.user?.id,
          },
        }
      );
      setDetails(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.data?.filter(
        (event) =>
          event?.Profile?.username?.toLowerCase() === searchKey.toLowerCase() ||
          event?.Profile?.email?.toLowerCase() === searchKey.toLowerCase() ||
          event?.Profile?.phone?.toLowerCase() === searchKey.toLowerCase()
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  const columns = [
    {
      field: "eventName",
      headerName: "Event Name",
      width: 100,
    },
    {
      field: "eventOrganizer",
      headerName: "Event Organizer",
      width: 100,
    },
    {
      field: "username",
      headerName: "Username",
      width: 135,
      renderCell: ({
        row: {
          Profile: { username },
        },
      }) => username,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      renderCell: ({
        row: {
          Profile: { email },
        },
      }) => email,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 135,
      renderCell: ({
        row: {
          Profile: { phone },
        },
      }) => phone,
    },
    {
      field: "details",
      headerName: "Description",
      width: 200,
    },
    {
      field: "location",
      headerName: "Location",
      width: 100,
    },
    {
      field: "isPaid",
      headerName: "Type",
      width: 100,
      renderCell: ({ row: { isPaid } }) => (
        <Typography
          color={!isPaid ? colors.greenAccent[400] : colors.redAccent[400]}
        >
          {isPaid ? "Paid" : "Free"}
        </Typography>
      ),
    },
    {
      field: "no_of_ticket_allowed",
      headerName: "Tickets Allowed",
      width: 100,
    },
    {
      field: "no_of_ticket_bought",
      headerName: "Tickets Bought",
      width: 100,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
    },
    {
      field: "currentState",
      headerName: "Status",
      width: 100,

      renderCell: ({ row: { currentState } }) => (
        <Typography
          color={
            currentState == "activate"
              ? colors.greenAccent[400]
              : colors.redAccent[400]
          }
        >
          {currentState}
        </Typography>
      ),
    },
    {
      field: "shareCount",
      headerName: "Share",
      width: 100,
    },
    {
      field: "likeCount",
      headerName: "Likes",
      width: 100,
      renderCell: ({
        row: {
          Engagement: { likeCount },
        },
      }) => likeCount,
    },
    {
      field: "eventStart",
      headerName: "Start Date",
      width: 98,
      renderCell: ({ row: { eventStart } }) => Date(eventStart),
    },
    {
      field: "eventEnd",
      headerName: "End Date",
      width: 98,
      renderCell: ({ row: { eventEnd } }) => Date(eventEnd),
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <EventModal modal={modal} setModal={setModal} />
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ justifyContent: "space-between" }}
      >
        <Grid xs={12} sm={12} md={6} lg={3.6} xl={3}>
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
          >
            <Header title="EVENTS" subtitle="Manage Events" />
          </Box>
        </Grid>
        <Box display="flex" height="45px" paddingRight={1.3} marginTop={1}>
          <Box display="flex">
            <SearchTable
              handleSearch={handleSearch}
              placeholder="email/username/phone"
            />
            <Button
              onClick={() => setModal({ open: true })}
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginLeft: "15px",
              }}
            >
              Create New Event
            </Button>
          </Box>
        </Box>

        <Box
          m="10px"
          width="100%"
          height="65vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={newDetails ? newDetails : []}
            columns={columns}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default ManageEvent;
