import useCookies from "react-cookie/cjs/useCookies";
import { createContext, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { addDays } from "date-fns";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [queryUserDetails, setQueryUserDetails] = useState({
    user: [],
    manageProfile: {},
    manageAccount: {},
    manageTransaction: {},
    manageKYC: {},
    loginTracker: {},
  });
  const [queryTransaction, setQueryTransaction] = useState({});
  const [notify, setNotify] = useState({
    open: false,
    message: "",
    severity: "",
  });
  // date state
  const [range, setRange] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [cookies, setCookies, removeCookie] = useCookies(["auth"]);
  const [cookieTheme, setCookieTheme] = useCookies(["theme"]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [otpEmailOrPhone, setOTPEmailOrPhone] = useState(null);

  // Function to handle login
  const login = (userAuth) => {
    // Perform login logic here and update the state accordingly
    setIsLoggedIn(true);
    const expirationTime = new Date().getTime() + 900000; //expires in 15min
    setCookies("auth", userAuth, { expires: new Date(expirationTime) });
  };

  // Function to handle logout
  const logout = () => {
    // Perform logout logic here and update the state accordingly
    setIsLoggedIn(false);
    removeCookie("auth");
  };

  // Notification Handler
  const info = (message) => {
    setNotify((prev) => ({
      open: true,
      message: typeof message !== String ? JSON.stringify(message) : message,
      severity: "info",
    }));
  };
  const snackError = (message) => {
    setNotify((prev) => ({
      open: true,
      message: typeof message !== String ? JSON.stringify(message) : message,
      severity: "error",
    }));
  };
  const warning = (message) => {
    setNotify((prev) => ({
      open: true,
      message: typeof message !== String ? JSON.stringify(message) : message,
      severity: "warning",
    }));
  };
  const success = (message) => {
    setNotify((prev) => ({
      open: true,
      message: typeof message !== String ? JSON.stringify(message) : message,
      severity: "success",
    }));
  };

  return (
    <AuthContext.Provider
      value={{
        range,
        setRange,
        queryTransaction,
        setQueryTransaction,
        otpEmailOrPhone,
        setOTPEmailOrPhone,
        queryUserDetails,
        setQueryUserDetails,
        logout,
        isLoggedIn,
        login,
        setCookieTheme,
        cookieTheme,
        cookies,
        prefersDarkMode,
        notify,
        setNotify,
        info,
        snackError,
        warning,
        success,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
