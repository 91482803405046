import {
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import SearchTable from "../../components/SearchTable";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Settlements = () => {
  const [details, setDetails] = useState([]);
  const [newDetails, setNewDetails] = useState([]);

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setQueryTransaction } = useAuth();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const getAllSettlements = async () => {
    try {
      const response = await axiosPrivate.get(
        "/wayapay-settlement-service/api/v1/transactions/settlements/all/byAdmin",
        {
          params: {
            field: "All",
            value: "All",
          },
        }
      );
      setDetails(response?.data?.data?.content);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.filter(
        (transaction) =>
          transaction.settlementReferenceId?.toLowerCase() ===
            searchKey.trim().toLowerCase() ||
          transaction.paymentRefNo?.toLowerCase() ===
            searchKey.trim().toLowerCase()
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSettlements();
  }, []);

  const columns = [
    {
      field: "settlementReferenceId",
      headerName: "Reference Id",
      width: 150,
    },
    {
      field: "settlementAccountName",
      headerName: "Business Name",
      width: 200,
    },
    {
      field: "settlementBeneficiaryAccount",
      headerName: "Beneficiary Account",
      width: 150,
    },
    {
      field: "settlementGrossAmount",
      headerName: "Actual Amount",
      width: 100,
    },
    {
      field: "settlementNetAmount",
      headerName: "Settled Amount",
      width: 100,
    },
    {
      field: "dateCreated",
      headerName: "Date",
      width: 150,
    },
    {
      field: "settlementType",
      headerName: "Type",
      width: 100,
    },
    {
      field: "settlementStatus",
      headerName: "Status",
      width: 150,
      renderCell: ({ row: { settlementStatus } }) => {
        return (
          <Typography
            color={
              settlementStatus == "SETTLED"
                ? colors.greenAccent[400]
                : colors.redAccent[400]
            }
          >
            {settlementStatus}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: ({ row }) => {
        return (
          <Button
            onClick={() => {
              setQueryTransaction(row);
              navigate("/settlement_details_wayaquick");
            }}
            underline="none"
            sx={{
              backgroundColor: colors.orangeAccent[700],
              color: colors.grey[100],
              fontSize: "12px",
            }}
          >
            View More
          </Button>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      {/* GRID & CHARTS */}
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ justifyContent: "space-between" }}
      >
        <Grid xs={12} sm={12} md={6} lg={3.6} xl={3}>
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
          >
            <Header
              title="SETTLEMENTS"
              subtitle="Manage WayaQuick Settlements"
            />
          </Box>
        </Grid>
        <Box mx={2} mt={1}>
          <SearchTable handleSearch={handleSearch} placeholder="Reference Id" />
        </Box>
        <Box
          m="10px"
          width="100%"
          height="70vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={newDetails ? newDetails : []}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default Settlements;
