import React from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { tokens } from "../../theme";
import FileUploadInput from "../fileUploadInput";

const OptionsModal = ({ modal, setModal, pollOptions, setPollOptions }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const handleFormSubmit = async (values, actions) => {
    const option = { ...values, id: pollOptions.length + 1 };
    setPollOptions((prev) => [...prev, option]);
    actions.resetForm();
  };

  const handleDelete = (id) => {
    setPollOptions((prev) => prev.filter((poll) => poll.id !== id));
  };

  const initialValues = {
    option: "",
    description: "",
    image: null,
  };

  const checkoutSchema = yup.object().shape({
    option: yup.string().required("Required"),
    description: yup.string().required("Required"),
    image: yup.mixed(),
  });

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: 450,
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "20px" }}
                >
                  Set Poll Options
                </Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Option"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.option}
                  name="option"
                  error={!!touched.option && !!errors.option}
                  helperText={touched.option && errors.option}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  name="description"
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                  sx={{ mb: 2 }}
                />
                <FileUploadInput
                  name="image"
                  label="Option Image"
                  accept="image/jpeg, image/png"
                />
                <Box display="flex">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{
                      width: "100%",
                      p: 2,
                      mt: 2,
                    }}
                  >
                    Add Option
                  </Button>
                  <Button
                    onClick={() =>
                      setModal((prev) => ({ ...prev, open: false }))
                    }
                    variant="contained"
                    disabled={pollOptions.length < 2}
                    sx={{
                      width: "100%",
                      p: 2,
                      mt: 2,
                      ml: 2,
                      bgcolor: colors.blueAccent[400],
                    }}
                  >
                    Done
                  </Button>
                </Box>
                <Typography variant="h5" mt={2}>
                  NOTE:{" "}
                  <span style={{ color: colors.grey[200], marginLeft: 5 }}>
                    You must set 2 options atleast
                  </span>
                </Typography>
              </Box>
            </form>
          )}
        </Formik>
        <Box>
          {pollOptions.map(({ image, option, description, id }) => {
            // console.log(image);
            let url;
            if (image) {
              const blob = new Blob([image], { type: "image/jpeg" });
              url = URL.createObjectURL(blob);
            }
            return (
              <Box key={id}>
                <hr></hr>
                <Box display="flex">
                  <Box width="90%" display="flex" alignItems="center">
                    {image && (
                      <img
                        src={url}
                        width={40}
                        height={40}
                        style={{ marginRight: 10 }}
                      />
                    )}
                    <Box>
                      <Typography variant="h5">{option}</Typography>
                      <Typography variant="h6">{description}</Typography>
                    </Box>
                  </Box>
                  <IconButton sx={{ px: 1.5 }} onClick={() => handleDelete(id)}>
                    <DeleteIcon />{" "}
                  </IconButton>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Modal>
  );
};

export default OptionsModal;
