import { useState } from "react";
import { Tabs, Tab, Modal, Box, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import Tier1 from "../tiers/Tier1";
import Tier2 from "../tiers/Tier2";
import Tier3 from "../tiers/Tier3";
import Tier4 from "../tiers/Tier4";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export function KYCStatusModal({ modal, setModal, setDocModal }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNext = () => {
    setValue((prevValue) => (prevValue + 1) % 5);
  };

  const handlePrev = () => {
    setValue((prevValue) => (prevValue + 4) % 5);
  };

  const handleClose = () => {
    setModal((prevValue) => ({ ...prevValue, open: false }));
  };

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: "60%",
          height: "70%",
          backgroundColor: colors.primary[400],
          p: 3,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
          borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton onClick={handlePrev}>
              <ArrowBackIos />
            </IconButton>
            <Tabs value={value} onChange={handleChange} aria-label="My Tabs">
              <Tab
                sx={{
                  borderRadius: "5px",
                  bgcolor: colors.primary[400],
                  "&.Mui-selected": {
                    color: colors.grey[100],
                    bgcolor: colors.orangeAccent[700],
                  },
                }}
                label="Tier 1"
                id="tier-0"
                aria-controls="tabpanel-0"
              />
              <Tab
                sx={{
                  borderRadius: "5px",
                  bgcolor: colors.primary[400],
                  "&.Mui-selected": {
                    color: colors.grey[100],
                    bgcolor: colors.orangeAccent[700],
                  },
                }}
                label="Tier 2"
                id="tier-1"
                aria-controls="tabpanel-1"
              />
              <Tab
                sx={{
                  borderRadius: "5px",
                  bgcolor: colors.primary[400],
                  "&.Mui-selected": {
                    color: colors.grey[100],
                    bgcolor: colors.orangeAccent[700],
                  },
                }}
                label="Tier 3"
                id="tier-2"
                aria-controls="tabpanel-2"
              />
              <Tab
                sx={{
                  borderRadius: "5px",
                  bgcolor: colors.primary[400],
                  "&.Mui-selected": {
                    color: colors.grey[100],
                    bgcolor: colors.orangeAccent[700],
                  },
                }}
                label="Tier 4"
                id="tier-3"
                aria-controls="tabpanel-3"
              />
              <Tab
                sx={{
                  borderRadius: "5px",
                  bgcolor: colors.primary[400],
                  "&.Mui-selected": {
                    color: colors.grey[100],
                    bgcolor: colors.orangeAccent[700],
                  },
                }}
                label="Tier 5"
                id="tier-4"
                aria-controls="tabpanel-4"
              />
            </Tabs>
            <IconButton onClick={handleNext}>
              <ArrowForwardIos />
            </IconButton>
          </Box>
          <TabPanel value={value} index={0}>
            <Tier1 userId={modal.userId} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Tier2 userId={modal.userId} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Tier3 userId={modal.userId} setDocModal={setDocModal} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Tier4 userId={modal.userId} setDocModal={setDocModal} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Tier4 userId={modal.userId} setDocModal={setDocModal} />
          </TabPanel>
        </Box>
      </Box>
    </Modal>
  );
}
