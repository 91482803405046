import { Box, CardContent, Typography } from "@mui/material";
import React from "react";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  content: {
    width: "100%",
    paddingTop: 0,
    paddingBottom: 5,
    paddingLeft: 30,
    paddingRight: 30,
  },
}));

const TransactionCardList = ({ title, value }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const classes = useStyles();

  return (
    <CardContent className={classes.content}>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>

        <Typography
          variant="h5"
          sx={{ color: colors.grey[200], width: "60%", textAlign: "right" }}
        >
          {value}
        </Typography>
      </Box>
      <hr />
    </CardContent>
  );
};

export default TransactionCardList;
