import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import useAuth from "../hooks/useAuth";
import Slide from "@mui/material/Slide";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TransitionLeft = (props) => {
  return <Slide {...props} direction="left" />;
};

export default function CustomSnackbars() {
  const { notify, setNotify } = useAuth();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotify({ open: false });
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        TransitionComponent={TransitionLeft}
        open={notify.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={notify.severity}
          sx={{ width: "100%", maxWidth: 560 }}
        >
          {notify.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
