import React, { useEffect, useRef, useState } from "react";
import {
  Tabs,
  Tab,
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import UsersWithForm from "./screens/UsersWithForm";
import { generateReqId } from "../../utils/generateReqId";

const MultipleFormModal = ({ modal, setModal }) => {
  const [forms, setForms] = useState([{ id: 1 }]);
  const [formValues, setFormValues] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const { success } = useAuth();
  const [businessTypes, setBusinessTypes] = useState([]);
  const submitRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleTabChange = (event, newValue) => {
    // Handle tab change logic
    submitRef.current.click();
    setCurrentTab(newValue);
  };

  const handleAddForm = () => {
    submitRef.current.click();
    const newFormId = forms.length + 1;
    setForms([...forms, { id: newFormId }]);
    setCurrentTab(newFormId - 1);
  };

  const handleSubmitAll = async () => {
    setIsLoading(true);
    submitRef.current.click();
    console.log("All Form values:", formValues);
    const forms = [];
    for (let form in formValues) {
      let {
        businessType,
        dateOfBirth,
        email,
        firstName,
        gender,
        lastName,
        organisationCity,
        organisationEmail,
        organisationName,
        organisationPhone,
        organisationState,
        organizationAddress,
        password,
        phoneNumber,
        referenceCode,
      } = formValues[form];

      forms.push({
        email,
        businessType,
        officeAddress: organizationAddress,
        state: organisationState,
        city: organisationCity,
        firstName,
        surname: lastName,
        dateOfBirth,
        phoneNumber,
        password,
        gender,
        referenceCode,
        admin: false,
        orgName: organisationName,
        orgEmail: organisationEmail,
        orgPhone: organisationPhone,
      });
    }

    try {
      const response = await axiosPrivate.post(
        "https://services.staging.wayabank.ng/logs-service/api/v1/requestlog/create",
        {
          customerName: "Multiple Users",
          payload: JSON.stringify(forms),
          requestId: generateReqId(),
          requestType: "MULTIPERSONALACCOUNT",
        }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    handleClose();
  };

  const getBusinessIndustries = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayapay.ng/identity-manager-service/api/v1/waya-merchant/business-kyc/business-industry"
      );
      setBusinessTypes(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBusinessIndustries();
  }, []);

  const handleClose = () => {
    setModal({ open: false });
  };

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: "60%",
          //   height: "40%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Tabs
          sx={{
            bgcolor: colors.primary[400],
          }}
          value={currentTab}
          onChange={handleTabChange}
        >
          {forms.map((form) => (
            <Tab
              sx={{
                borderRadius: "5px",
                bgcolor: colors.primary[400],
                "&.Mui-selected": {
                  color: colors.grey[100],
                  bgcolor: colors.orangeAccent[700],
                },
              }}
              key={form.id}
              label={`Form ${form.id}`}
            />
          ))}
        </Tabs>
        <Box>
          <Typography variant="h4" sx={{ textAlign: "center", mt: 1.5, mb: 2 }}>
            Create Users With Form
          </Typography>
        </Box>
        {forms.map((form, index) => (
          <Box
            key={form.id}
            sx={{ display: currentTab === index ? "block" : "none" }}
          >
            <UsersWithForm
              businessTypes={businessTypes}
              form={form}
              forms={forms}
              submitRef={submitRef}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              setFormValues={setFormValues}
              setForms={setForms}
            />
          </Box>
        ))}
        <IconButton
          onClick={handleAddForm}
          sx={{ ml: 5, mr: 1, p: 2, mb: 2, bgcolor: colors.grey[700] }}
        >
          <AddIcon />
        </IconButton>
        <Button
          onClick={handleSubmitAll}
          color="secondary"
          variant="contained"
          disabled={isLoading}
          sx={{ p: 2, width: "80%", mb: 2 }}
        >
          Create Users
        </Button>
      </Box>
    </Modal>
  );
};

export default MultipleFormModal;
