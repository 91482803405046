import React from "react";
import { Box, Button, IconButton, MenuItem, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import naijaStateLocalGovernment from "naija-state-local-government";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ReactDatePicker from "react-datepicker";
import { tokens } from "../../../theme";

const UsersWithForm = ({
  businessTypes,
  form,
  forms,
  submitRef,
  currentTab,
  setCurrentTab,
  setFormValues,
  setForms,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const initialValues = {
    organisationName: "",
    organisationEmail: "",
    organisationPhone: "",
    organisationState: "",
    organisationCity: "",
    organizationAddress: "",
    businessType: "",
    accountType: "Individual",
    email: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    phoneNumber: "",
    password: "",
    referenceCode: "",
  };

  const checkoutSchema = yup.object().shape({
    organisationName: yup.string().when("accountType", {
      is: (value) => value === "Corporate",
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    organisationEmail: yup.string().when("accountType", {
      is: (value) => value === "Corporate",
      then: yup.string().email().required("Required"),
      otherwise: yup.string().email(),
    }),
    organisationPhone: yup.string().when("accountType", {
      is: (value) => value === "Corporate",
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    organisationState: yup.string().when("accountType", {
      is: (value) => value === "Corporate",
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    organisationCity: yup.string().when("accountType", {
      is: (value) => value === "Corporate",
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    organizationAddress: yup.string().when("accountType", {
      is: (value) => value === "Corporate",
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    businessType: yup.string().when("accountType", {
      is: (value) => value === "Corporate",
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    accountType: yup.string().required("Required"),
    email: yup.string().email().required("Required"),
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    dateOfBirth: yup.string(),
    gender: yup.string().required("Required"),
    phoneNumber: yup.string().required("Required"),
    password: yup.string().required("Required"),
    referenceCode: yup.string(),
  });

  const options = [
    {
      name: "Individual",
    },
    {
      name: "Corporate",
    },
  ];

  const genders = [
    {
      name: "Male",
    },
    {
      name: "Female",
    },
  ];

  const handleDeleteForm = (formId) => {
    const updatedForms = forms.filter((form) => form.id !== formId);
    setForms(updatedForms);
    setCurrentTab(formId == forms.length ? forms.length - 2 : formId - 1);
  };

  const handleFormSubmit = (values) => {
    // Handle form submission logic
    const formId = forms[currentTab].id;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [formId]: values,
    }));
  };

  return (
    <Formik
      key={form.id}
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box padding={5}>
            <Box
              display="grid"
              gap={values.accountType == "Corporate" ? "10px" : "20px"}
              gridTemplateColumns={
                values.accountType == "Corporate"
                  ? "repeat(6, minmax(0, 1fr))"
                  : "repeat(4, minmax(0, 1fr))"
              }
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
                marginBottom: "10px",
              }}
            >
              <TextField
                label="Account Type"
                fullWidth
                select
                name="accountType"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.accountType}
                sx={{ gridColumn: "span 2" }}
              >
                {options.map((option, index) => (
                  <MenuItem key={index} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              {values.accountType == "Corporate" && (
                <>
                  <TextField
                    fullWidth
                    select
                    label="Business Type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.businessType}
                    name="businessType"
                    error={!!touched.businessType && !!errors.businessType}
                    helperText={touched.businessType && errors.businessType}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {businessTypes.map((type, index) => (
                      <MenuItem key={index} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Organisation Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organisationName}
                    name="organisationName"
                    error={
                      !!touched.organisationName && !!errors.organisationName
                    }
                    helperText={
                      touched.organisationName && errors.organisationName
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Organisation Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organisationEmail}
                    name="organisationEmail"
                    error={
                      !!touched.organisationEmail && !!errors.organisationEmail
                    }
                    helperText={
                      touched.organisationEmail && errors.organisationEmail
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Organisation Phone Number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organisationPhone}
                    name="organisationPhone"
                    error={
                      !!touched.organisationPhone && !!errors.organisationPhone
                    }
                    helperText={
                      touched.organisationPhone && errors.organisationPhone
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    select
                    label="Organisation State"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organisationState}
                    name="organisationState"
                    error={
                      !!touched.organisationState && !!errors.organisationState
                    }
                    helperText={
                      touched.organisationState && errors.organisationState
                    }
                    sx={{ gridColumn: "span 2" }}
                  >
                    {naijaStateLocalGovernment.states().map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    select
                    label="Organisation City"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organisationCity}
                    error={
                      !!touched.organisationCity && !!errors.organisationCity
                    }
                    helperText={
                      touched.organisationCity && errors.organisationCity
                    }
                    name="organisationCity"
                    sx={{ gridColumn: "span 2" }}
                  >
                    {values?.organisationState
                      ? naijaStateLocalGovernment
                          .lgas(values?.organisationState)
                          .lgas?.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))
                      : []}
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Organisation Address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organizationAddress}
                    error={
                      !!touched.organizationAddress &&
                      !!errors.organizationAddress
                    }
                    helperText={
                      touched.organizationAddress && errors.organizationAddress
                    }
                    name="organizationAddress"
                    sx={{ gridColumn: "span 2" }}
                  />
                </>
              )}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <Box sx={{ gridColumn: "span 2" }}>
                <ReactDatePicker
                  selected={values.dateOfBirth}
                  onChange={(date) => setFieldValue("dateOfBirth", date)}
                  name="dateOfBirth"
                  dateFormat="d MMMM, yyyy"
                  customInput={
                    <TextField
                      fullWidth
                      InputProps={{ readOnly: true }}
                      label="Date of Birth"
                      error={!!touched.dateOfBirth && !!errors.dateOfBirth}
                      helperText={touched.dateOfBirth && errors.dateOfBirth}
                    />
                  }
                />
              </Box>
              <TextField
                label="Gender"
                fullWidth
                select
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.gender}
                name="gender"
                error={!!touched.gender && !!errors.gender}
                helperText={touched.gender && errors.gender}
                sx={{ gridColumn: "span 2" }}
              >
                {genders.map((gender, index) => (
                  <MenuItem key={index} value={gender.name}>
                    {gender.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneNumber}
                name="phoneNumber"
                error={!!touched.phoneNumber && !!errors.phoneNumber}
                helperText={touched.phoneNumber && errors.phoneNumber}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Reference"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.referenceCode}
                name="referenceCode"
                error={!!touched.referenceCode && !!errors.referenceCode}
                helperText={touched.referenceCode && errors.referenceCode}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Button
              type="submit"
              ref={currentTab + 1 == form.id ? submitRef : null}
              sx={{ display: "none" }}
            />
            <IconButton
              onClick={() => handleDeleteForm(form.id)}
              sx={{ position: "absolute", top: 80, right: 50 }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default UsersWithForm;
