import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import SearchTable from "../../components/SearchTable";
import PageModal from "../../components/modals/PageModal";

const ManagePage = () => {
  const [details, setDetails] = useState();
  const [newDetails, setNewDetails] = useState([]);
  const { cookies } = useAuth();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [modal, setModal] = useState({ open: false });

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.filter(
        (page) =>
          page?.title?.toLowerCase() === searchKey.toLowerCase() ||
          page?.username?.toLowerCase() === searchKey.toLowerCase()
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getPages = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayagram.ng/wayagram-service/main/admin-page/admin-get-all-pages",
        {
          params: {
            page: 1,
            sso_id: cookies?.auth?.user?.id,
          },
        }
      );
      setDetails(response?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPages();
  }, []);

  const columns = [
    {
      field: "headerImageUrl",
      headerName: "Header Image",
      width: 100,
      renderCell: ({ row: { headerImageUrl } }) => (
        <img src={headerImageUrl} width={100} height={60} />
      ),
    },
    {
      field: "username",
      headerName: "Username",
      width: 100,
    },
    {
      field: "title",
      headerName: "Page Title",
      width: 100,
    },
    {
      field: "description",
      headerName: "Page Description",
      width: 250,
    },
    {
      field: "isPublic",
      headerName: "Page Type",
      width: 100,
      renderCell: ({ row: { isPublic } }) => (
        <Typography
          color={isPublic ? colors.blueAccent[400] : colors.redAccent[400]}
        >
          {isPublic ? "Public" : "Private"}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: ({ row: { status } }) => (
        <Typography
          color={
            status == "APPROVED"
              ? colors.greenAccent[400]
              : colors.redAccent[400]
          }
        >
          {status}
        </Typography>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Last Activity",
      width: 120,
      renderCell: ({ row: { updatedAt } }) => updatedAt.split("T")[0],
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 120,
      renderCell: ({ row: { createdAt } }) => createdAt.split("T")[0],
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <PageModal modal={modal} setModal={setModal} />
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ justifyContent: "space-between" }}
      >
        <Grid xs={12} sm={12} md={6} lg={3.6} xl={3}>
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
          >
            <Header title="PAGES" subtitle="Manage Pages" />
          </Box>
        </Grid>
        <Box mx={1.5} display="flex" height={45} mt={2}>
          <SearchTable
            handleSearch={handleSearch}
            placeholder="username/title"
          />
          <Button
            onClick={() => setModal({ open: true })}
            underline="none"
            sx={{
              backgroundColor: colors.orangeAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "15px",
            }}
          >
            Create New Page
          </Button>
        </Box>

        <Box
          m="10px"
          width="100%"
          height="70vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={newDetails ? newDetails : []}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default ManagePage;
