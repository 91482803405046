import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import FileUploadInput from "../fileUploadInput";
import { useEffect } from "react";
import SearchableSelect from "../SearchableSelect";
import ReactDatePicker from "react-datepicker";
import { format } from "date-fns";
import { useState } from "react";

const DonationModal = ({ modal, setModal }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { success, snackError, cookies } = useAuth();
  const { id } = cookies?.auth?.user || {};
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    // console.log(values);
    const {
      postOwner: { id: userid },
      images,
      title,
      description,
      ownerDesc,
      estAmount,
      minAmount,
      justAmount,
      impact,
      expireDate,
      displayDonation,
      displayDonors,
    } = values;

    try {
      const response = await axiosPrivate.postForm(
        "https://services.staging.wayagram.ng/wayagram-service/all-posts/admin-create-donation",
        {
          profile_id: userid,
          donationImage: images,
          description,
          ownerDescription: ownerDesc,
          impact,
          title,
          estimatedAmount: estAmount,
          minimumAmount: minAmount,
          amountJustification: justAmount,
          displayTotalDonation: displayDonation == "True",
          displayNumberOfDonors: displayDonors == "True",
          donationExpiresAt: format(expireDate, "yyyy-MM-dd"),
        },
        {
          params: {
            sso_id: id,
          },
        }
      );
      console.log(response?.data?.data);
      success(response?.data?.message);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    resetForm();
    handleClose();
  };

  const initialValues = {
    images: "",
    postOwner: null,
    title: "",
    description: "",
    ownerDesc: "",
    estAmount: "",
    minAmount: "",
    justAmount: "",
    impact: "",
    expireDate: "",
    displayDonation: "",
    displayDonors: "",
  };

  const checkoutSchema = yup.object().shape({
    images: yup.mixed().required("Required"),
    postOwner: yup.object().required("Required"),
    title: yup.string().required("Required"),
    description: yup.string().required("Required"),
    ownerDesc: yup.string().required("Required"),
    estAmount: yup.number().required("Required"),
    minAmount: yup.number().required("Required"),
    justAmount: yup.number().required("Required"),
    impact: yup.string().required("Required"),
    expireDate: yup.string().required("Required"),
    displayDonation: yup.string().required("Required"),
    displayDonors: yup.string().required("Required"),
  });

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  const boolOption = [
    {
      name: "True",
    },
    {
      name: "False",
    },
  ];

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "30px" }}
                >
                  Create New Donation
                </Typography>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                    marginBottom: "10px",
                  }}
                >
                  <FileUploadInput
                    name="images"
                    label="Images"
                    sx={{ gridColumn: "span 2" }}
                    error={!!touched.images && !!errors.images}
                    helperText={touched.images && errors.images}
                  />
                  <SearchableSelect
                    value={values.postOwner}
                    setValue={setFieldValue}
                    label="Post Owner"
                    name="postOwner"
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Donation Title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    name="title"
                    error={!!touched.title && !!errors.title}
                    helperText={touched.title && errors.title}
                    sx={{ gridColumn: "span 2" }}
                  />

                  <TextField
                    // className={classes.textArea}
                    // minRows={3}
                    variant="filled"
                    type="text"
                    label="Description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    name="description"
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    // className={classes.textArea}
                    // minRows={3}
                    variant="filled"
                    type="text"
                    label="Owner Description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.ownerDesc}
                    name="ownerDesc"
                    error={!!touched.ownerDesc && !!errors.ownerDesc}
                    helperText={touched.ownerDesc && errors.ownerDesc}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Estimated Amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.estAmount}
                    name="estAmount"
                    error={!!touched.estAmount && !!errors.estAmount}
                    helperText={touched.estAmount && errors.estAmount}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Minimum Amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.minAmount}
                    name="minAmount"
                    error={!!touched.minAmount && !!errors.minAmount}
                    helperText={touched.minAmount && errors.minAmount}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Amount Justification"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.justAmount}
                    name="justAmount"
                    error={!!touched.justAmount && !!errors.justAmount}
                    helperText={touched.justAmount && errors.justAmount}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    // className={classes.textArea}
                    // minRows={3}
                    variant="filled"
                    type="text"
                    label="Impact"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.impact}
                    name="impact"
                    error={!!touched.impact && !!errors.impact}
                    helperText={touched.impact && errors.impact}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <Box sx={{ gridColumn: "span 2" }}>
                    <ReactDatePicker
                      selected={values.expireDate}
                      onChange={(date) => setFieldValue("expireDate", date)}
                      name="expireDate"
                      dateFormat="d MMMM, yyyy"
                      customInput={
                        <TextField
                          fullWidth
                          InputProps={{ readOnly: true }}
                          label="Expire Date"
                        />
                      }
                    />
                  </Box>
                  <TextField
                    label="Display Donations"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.displayDonation}
                    name="displayDonation"
                    error={
                      !!touched.displayDonation && !!errors.displayDonation
                    }
                    helperText={
                      touched.displayDonation && errors.displayDonation
                    }
                    sx={{ gridColumn: "span 2" }}
                  >
                    {boolOption?.map((type, index) => (
                      <MenuItem key={index} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Display Donors"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.displayDonors}
                    name="displayDonors"
                    error={!!touched.displayDonors && !!errors.displayDonors}
                    helperText={touched.displayDonors && errors.displayDonors}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {boolOption?.map((type, index) => (
                      <MenuItem key={index} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  disabled={isLoading}
                  variant="contained"
                  sx={{ width: "100%", p: 2, mt: 3 }}
                >
                  Add Donation
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default DonationModal;
