import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import SearchTable from "../../components/SearchTable";
import OptionsModal from "../../components/modals/OptionsModal";
import PostModal from "../../components/modals/PostModal";

const ManagePost = () => {
  const [details, setDetails] = useState([]);
  const [modal, setModal] = useState({ open: false });
  const [optionsModal, setOptionsModal] = useState({ open: false });
  const [pollOptions, setPollOptions] = useState([]);
  const [newDetails, setNewDetails] = useState([]);
  const { cookies } = useAuth();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const getPosts = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayagram.ng/wayagram-service/all-posts/admin-get-all-posts",
        {
          params: {
            page: 1,
            limit: 20,
            sso_id: cookies?.auth?.user?.id,
          },
        }
      );
      setDetails(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.data?.filter(
        (post) =>
          post?.repostedBy?.toLowerCase() === searchKey.toLowerCase() ||
          post?.Profile?.username?.toLowerCase() === searchKey.toLowerCase()
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPosts();
  }, []);

  const columns = [
    {
      field: "repostedBy",
      headerName: "Post Creator",
      width: 200,
    },
    {
      field: "username",
      headerName: "Username",
      width: 100,
      renderCell: ({
        row: {
          Profile: { username },
        },
      }) => username,
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
    },
    // {
    //   field: "coverImage",
    //   headerName: "Cover Image",
    //   width: 100,
    //   renderCell: ({
    //     row: {
    //       Profile: { avatar },
    //     },
    //   }) => <img width={85} height={55} src={avatar} />,
    // },
    {
      field: "likes",
      headerName: "Likes",
      width: 100,
      renderCell: ({ row: { Engagement } }) => {
        return Engagement?.likeCount;
      },
    },
    {
      field: "reposts",
      headerName: "Reposts",
      width: 100,
      renderCell: ({ row: { Engagement } }) => {
        return Engagement?.repostCount;
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 100,
      renderCell: ({ row: { Engagement } }) => {
        return Engagement?.commentCount;
      },
    },
    {
      field: "shareCount",
      headerName: "Share",
      width: 100,
    },
    {
      field: "likeCount",
      headerName: "Likes",
      width: 100,
      renderCell: ({ row: { Post } }) => {
        return Post?.likeCount;
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 100,
      renderCell: ({ row: { createdAt } }) => createdAt.split("T")[0],
    },
  ];

  console.log(newDetails);

  return (
    <Box m="20px">
      {/* HEADER */}
      <OptionsModal
        modal={optionsModal}
        setModal={setOptionsModal}
        pollOptions={pollOptions}
        setPollOptions={setPollOptions}
      />
      <PostModal
        modal={modal}
        setModal={setModal}
        setOptionsModal={setOptionsModal}
      />
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ justifyContent: "space-between" }}
      >
        <Grid xs={12} sm={12} md={6} lg={3.6} xl={3}>
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
          >
            <Header title="POSTS" subtitle="Manage Posts" />
          </Box>
        </Grid>
        <Box display="flex" height="45px" paddingRight={1.3} marginTop={1}>
          <Box display="flex">
            <SearchTable handleSearch={handleSearch} placeholder="Username" />
            <Button
              onClick={() => setModal({ open: true })}
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginLeft: "15px",
              }}
            >
              <SaveAsIcon sx={{ mr: "10px" }} />
              Create New Post
            </Button>
          </Box>
        </Box>

        <Box
          m="10px"
          width="100%"
          height="65vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={newDetails ? newDetails : []}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default ManagePost;
