import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  colors,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ComputerIcon from "@mui/icons-material/Computer";
import ReplayIcon from "@mui/icons-material/Replay";
import MouseIcon from "@mui/icons-material/Mouse";
import ErrorIcon from "@mui/icons-material/Error";
import React from "react";
import { tokens } from "../../theme";
import CardList from "../../components/cardComponents/list";
import useAuth from "../../hooks/useAuth";
import TitlesList from "../../components/cardComponents/titlesList";
import BackButton from "../../components/BackButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 25,
  },
  card: {
    width: "48%",
    borderRadius: 8,
  },
  content: {
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
}));

function TransactionDetails(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const classes = useStyles();
  const { queryTransaction } = useAuth();
  const {
    amount,
    channel,
    customerEmail,
    customerId,
    customerIpAddress,
    customerName,
    customerPhone,
    fee,
    maskedPan,
    merchantId,
    merchantName,
    mode,
    processedBy,
    rcre_time,
    refNo,
    scheme,
    settlementStatus,
    status,
    tranDate,
    vendorDate,
  } = queryTransaction;

  return (
    <div className={classes.root}>
      <BackButton path="/transaction_history_wayaquick" />
      <Card className={classes.card} sx={{ bgcolor: colors.primary[400] }}>
        <CardContent className={classes.content}>
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            mb={3}
          >
            <Box textAlign="center">
              <Typography variant="h3" className={classes.title}>
                Amount
              </Typography>
              <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                500
              </Typography>
            </Box>

            <Typography
              variant="h3"
              className={classes.title}
              sx={{
                height: "50%",
                alignSelf: "center",
                border: 2,
                borderColor: colors.greenAccent[300],
                borderRadius: 2,
                px: 2,
                py: 1,
                bgcolor: colors.greenAccent[500],
                color: colors.greenAccent[300],
              }}
            >
              {status}
            </Typography>
          </Box>
        </CardContent>
        <CardList title="Reference Id" value={refNo} />
        <CardList title="Channel" value={channel} />
        <CardList title="Wayapaychat Fee" value={fee} />
        <CardList title="Date Paid" value={tranDate} />
        <CardList title="Message" value={status} />
        <Card sx={{ width: "100%", mt: 7 }}>
          <CardContent className={classes.cardContent}>
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                <AccountCircleIcon sx={{ fontSize: "50px", mr: 2 }} />
                <Box>
                  <Typography variant="h4">{customerName}</Typography>
                  <Typography variant="h6">{customerEmail}</Typography>
                </Box>
              </Box>
              <Button
                underline="none"
                sx={{
                  backgroundColor: colors.orangeAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  height: 45,
                }}
              >
                View Customer
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Card>
      <hr />

      <Card className={classes.card} sx={{ bgcolor: colors.primary[400] }}>
        <CardContent className={classes.content}>
          <Box border={1} borderColor={colors.orangeAccent[400]} p={2}>
            <Button
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
              }}
            >
              <ReplayIcon sx={{ mr: 1 }} />
              Refund Customer
            </Button>
          </Box>
          <TitlesList
            t1="Card Type"
            t2="Issuing Bank & Country"
            s1={scheme}
            s2="Not Available"
          />
          <TitlesList
            t1="Card Number"
            t2="IP Address"
            s1={maskedPan}
            s2={customerIpAddress}
          />
          <TitlesList
            t1="Authorization"
            t2="Payment Processor"
            s1="Not Available"
            s2={scheme}
          />
          <hr />
          <Box display="flex" mt={2} justifyContent="space-between">
            <Box height="100%" width="45%">
              <Box display="flex" alignItems="center" my={3} mx={1}>
                <ComputerIcon sx={{ fontSize: 30, mr: 2 }} />
                <Box>
                  <Typography variant="h4">DEVICE TYPE</Typography>
                  <Typography variant="h5" color={colors.grey[400]}>
                    Desktop
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" my={3} mx={1}>
                <MouseIcon sx={{ fontSize: 30, mr: 2 }} />
                <Box>
                  <Typography variant="h4">PAY BUTTON CLICK TIME</Typography>
                  <Typography variant="h5" color={colors.grey[400]}>
                    1 time(s)
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" my={3} mx={1}>
                <ErrorIcon sx={{ fontSize: 30, mr: 2 }} />
                <Box>
                  <Typography variant="h4">ERRORS</Typography>
                  <Typography variant="h5" color={colors.grey[400]}>
                    0 Errors
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box width="45%" position="relative" mt={3}>
              <Box
                position="relative"
                border={1}
                borderColor={colors.orangeAccent[400]}
                borderRadius="50%"
                height={200}
                width={200}
              >
                <Box
                  position="absolute"
                  width={200}
                  textAlign="center"
                  bottom={-10}
                  right={0}
                  p={1}
                  sx={{
                    bgcolor:
                      theme?.palette?.mode == "light"
                        ? colors.primary[400]
                        : "#2a3421",
                  }}
                >
                  <Typography variant="h4">GATEWAY MODAL LOAD TIME</Typography>
                </Box>
                <Box
                  position="absolute"
                  width={80}
                  textAlign="center"
                  top={60}
                  right={60}
                  p={1}
                  sx={{
                    bgcolor:
                      theme?.palette?.mode == "light"
                        ? colors.primary[400]
                        : "#2a3421",
                  }}
                >
                  <Typography variant="h4">
                    <span style={{ color: colors.grey[400] }}>12:34</span>{" "}
                    Minuites
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default TransactionDetails;
