import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Header from "../../components/Header";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import EditIcon from "@mui/icons-material/Edit";
import BackButton from "../../components/BackButton";

const KYCSettings = () => {
  const [details, setDetails] = useState([]);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const getTierList = async () => {
    try {
      const response = await axiosPrivate.get(
        "/kyc-servicev2/kyc/manage-tier/list/all"
      );
      setDetails(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTierList();
  }, []);

  const columns = [
    { field: "tiers", headerName: "Tiers", width: 100 },
    {
      field: "maximumLimit",
      headerName: "Maximum Day Limit",
      width: 150,
    },
    {
      field: "singleTransferLimit",
      headerName: "Single Transfer Limit",
      width: 150,
    },
    {
      field: "maxNumberOfTxnAllowed",
      headerName: "Max Number Of Transaction Allowed",
      width: 150,
    },
    {
      field: "",
      headerName: "Action",
      width: 100,
      align: "center",
      renderCell: (params) => {
        return <EditIcon rowId={params.id} />;
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <BackButton path="/manage_kyc" />
      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title="KYC SETTINGS" subtitle="Manage KYC Settings" />

        {/* <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Button
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginLeft: "15px",
              }}
            >
              <ExitToAppIcon sx={{ mr: "10px" }} />
              Update Tier Levels
            </Button>
          </Box>
        </Box> */}
      </Box>

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Box
          m="10px"
          width="100%"
          height="50vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={details}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default KYCSettings;
