import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { tokens } from "../../theme";
import * as yup from "yup";
import { useTheme } from "@emotion/react";
import { Formik } from "formik";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";

const FORGOT_PASSWRD_BYMAIL_URL =
  "/auth-service/api/v1/password/forgot-password/byEmail";
const FORGOT_PASSWRD_BYPHONE_URL =
  "/auth-service/api/v1/password/forgot-password/byPhone";

const ForgetPasswordOTPModal = ({ modal, setModal }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const { success, snackError, setOTPEmailOrPhone } = useAuth();
  const navigate = useNavigate();

  const emailRegex =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  // const phoneRegex = /^(?:\+?234|0)?\d{10}$/; // Change this regex based on requirement
  const phoneRegex = /^234\d{10}$/; // Change this regex based on requirement

  const handleFormSubmit = async (values, actions) => {
    if (values.emailOrPhone.match(emailRegex)) {
      try {
        const response = await axios.get(FORGOT_PASSWRD_BYMAIL_URL, {
          params: {
            email: values.emailOrPhone,
          },
        });
        success(response.data.message);
        actions.resetForm();
        navigate("/forget-password");
      } catch (error) {
        snackError(error.response?.data?.message);
      }
    } else if (values.emailOrPhone.match(phoneRegex)) {
      try {
        const response = await axios.get(FORGOT_PASSWRD_BYPHONE_URL, {
          params: {
            phoneNumber: values.emailOrPhone,
          },
        });
        success(response.data.message);
        actions.resetForm();
        navigate("/forget-password");
      } catch (error) {
        snackError(error.response?.data?.data?.phoneNumber);
      }
    }
    setOTPEmailOrPhone(values.emailOrPhone);
  };

  const initialValues = {
    emailOrPhone: "",
  };

  const checkoutSchema = yup.object().shape({
    emailOrPhone: yup
      .string("Enter your Email/Phone Number")
      .required("Email/Phone Number is required")
      .test("emailOrPhone", "Enter Valid Phone/Email", function (value) {
        let isValidEmail = emailRegex.test(value);
        let isValidPhone = phoneRegex.test(value);
        if (!isValidEmail && !isValidPhone) {
          return false;
        }
        return true;
      }),
  });

  // Close the modal
  const handleClose = () => {
    setModal((prev) => {
      return { ...prev, open: false };
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 520,
    height: 330,
    bgcolor: colors.primary[400],
    border: "1px solid #000",
    boxShadow: 24,
    p: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  };

  return (
    <Modal
      open={modal.open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box mb="30px">
          <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            sx={{ mb: "5px" }}
          >
            REQUEST OTP
          </Typography>
          <Typography variant="h5" color={colors.orangeAccent[600]}>
            Enter email to generate OTP for forget password
          </Typography>
        </Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email / Phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.emailOrPhone}
                name="emailOrPhone"
                error={!!touched.emailOrPhone && !!errors.emailOrPhone}
                helperText={touched.emailOrPhone && errors.emailOrPhone}
                sx={{ gridColumn: "span 16" }}
              />
              <Button
                fullWidth
                disabled={isSubmitting}
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ gridColumn: "span 16", p: 2, mt: 3 }}
              >
                Submit
              </Button>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default ForgetPasswordOTPModal;
