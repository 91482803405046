// Function to generate a random 16-digit ID
export const generateReqId = () => {
  const prefix = "REQ";
  const digits = "0123456789";
  let id = prefix;

  while (id.length < 16) {
    id += digits[Math.floor(Math.random() * digits.length)];
  }

  return id;
};
