import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import * as yup from "yup";
import { useTheme } from "@emotion/react";
import { Formik } from "formik";
import { tokens } from "../../theme";
import { useState } from "react";

const VerifyBVNModal = ({ modal, setModal, handleFormSubmit }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    BVN: "",
  };

  const checkoutSchema = yup.object().shape({
    BVN: yup.number("Enter user BVN").required("Required"),
  });

  // Close the modal
  const handleClose = () => {
    setModal((prev) => {
      return { ...prev, open: false };
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    width: 520,
    height: 280,
    bgcolor: colors.primary[400],
    border: "1px solid #000",
    boxShadow: 24,
    p: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  };

  return (
    <Modal
      open={modal.open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h4" align="center">
          Verify BVN
        </Typography>
        <Formik
          onSubmit={(values, actions) =>
            handleFormSubmit(values, actions, setIsLoading)
          }
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Enter BVN"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.BVN}
                name="BVN"
                error={!!touched.BVN && !!errors.BVN}
                helperText={touched.BVN && errors.BVN}
                sx={{ gridColumn: "span 16", marginBottom: "20px" }}
              />
              <Button
                variant="outlined"
                type="submit"
                disabled={isLoading}
                sx={{
                  backgroundColor: colors.orangeAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                underline="none"
              >
                Verify BVN
              </Button>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default VerifyBVNModal;
