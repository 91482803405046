import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import SearchableSelector from "../SearchableSelector";
import { useEffect } from "react";
import { generatePaymentRef } from "../../utils/generatePaymentRef";
import { generateReqId } from "../../utils/generateReqId";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  inputFile: {
    "& input[type=file]": {
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    "& input[type=file]::-webkit-file-upload-button": {
      display: "none",
    },
  },
});

const TransferToOtherBankModal = ({
  isOpen,
  setModal,
  setOTPModal,
  OTPModal,
}) => {
  const classes = useStyles();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const { success, snackError, cookies } = useAuth();
  const [banks, setBanks] = useState([]);
  const [walletOptions, setWalletOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post(
        `https://services.staging.wayabank.ng/temporal-service/api/v1/wallet/otp/generate/${cookies?.auth?.user?.phoneNumber}`
      );
      success(response?.data?.data?.message);
      setOTPModal((prev) => ({
        ...prev,
        open: true,
        values: values,
        caller: "otherBank",
      }));
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const makeTransfer = async () => {
    const { amount, pin } = OTPModal.values;
    const { acct_name, accountNo } = OTPModal.values?.fromUser || {};
    const {
      accountNumber: crAccount,
      name: crAccountName,
      bankCode,
      bankName,
    } = OTPModal.values?.bankOrBaneficiary || {};
    try {
      const response = await axiosPrivate.post(
        "/logs-service/api/v1/requestlog/create",
        {
          customerName: acct_name,
          payload: JSON.stringify({
            crAccount,
            crAccountName,
            saveBen: true,
            bankCode,
            bankName,
            amount,
            walletAccountNo: accountNo,
            acctName: acct_name,
            transactionPin: pin,
            transRef: generatePaymentRef(),
            userId: cookies.auth.user.id,
          }),
          requestId: generateReqId(),
          requestType: "WITHDRAW",
        }
      );
      success(response.data?.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (OTPModal.status?.otherBank == "success") {
      makeTransfer();
    }
  }, [OTPModal.status?.otherBank]);

  const handleFileChange = (event, field, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      try {
        const reader = new FileReader();
        reader.onloadend = async () => {
          setFieldValue(field.name, file);
        };
        reader.readAsArrayBuffer(file);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const initialValues = {
    formType: "Single User",
    username: null,
    beneficiaryOption: "",
    bankOrBaneficiary: "",
    recipientAccountNumber: "",
    recipientAccountName: "",
    amount: "",
    fromUser: "",
    narration: "",
    pin: "",
  };

  const checkoutSchema = yup.object().shape({
    formType: yup.string().required("Required"),
    username: yup.object().required("Required"),
    beneficiaryOption: yup.string().required("Required"),
    bankOrBaneficiary: yup.object().required("Required"),
    recipientAccountNumber: yup.number().required("Required"),
    recipientAccountName: yup.string().required("Required"),
    amount: yup.number().required("Required"),
    fromUser: yup.object().required("Required"),
    narration: yup.string(),
    pin: yup.number().required("Required"),
  });

  const options = [
    {
      name: "Transfer to saved beneficiary",
    },
    {
      name: "Transfer to saved bank account",
    },
    {
      name: "Transfer to new bank account",
    },
  ];

  const formTypes = [
    {
      name: "Single User",
    },
    // {
    //   name: "Web Forms",
    // },
    {
      name: "Excel Upload",
    },
  ];

  const getWallets = async ({ userId }) => {
    try {
      const response = await axiosPrivate.get(
        "/temporal-service/api/v1/wallet/accounts/" + userId
      );
      setWalletOptions(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (e, newValue, setFieldValue) => {
    setFieldValue("username", newValue);
    getWallets(newValue);
  };

  const handleBeneficiaryChange = async (
    e,
    setFieldValue,
    { username: { userId } }
  ) => {
    const selected = e.target.value;
    setFieldValue("beneficiaryOption", selected);
    if (selected == "Transfer to new bank account") {
      try {
        const response = await axiosPrivate.get(
          "/card-service/bankAccount/getBanks/WD"
        );
        console.log(response);
        setBanks(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    } else if (selected == "Transfer to saved beneficiary") {
      try {
        const response = await axiosPrivate.get(
          "https://services.staging.wayabank.ng/withdrawal-service/getWithdrawalBen",
          {
            params: {
              userId: userId,
            },
          }
        );
        console.log(response);
        setBanks(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAccountNumberChange = async (
    e,
    setFieldValue,
    { bankOrBaneficiary: { bankCode } }
  ) => {
    const accountNumber = e.target.value;
    if (accountNumber.length == 10) {
      try {
        const response = await axiosPrivate.post(
          "/card-service/bankAccount/resolveAccountNumber/WD",
          {
            accountNumber,
            bankCode,
          }
        );
        setFieldValue("recipientAccountName", response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    setFieldValue("recipientAccountNumber", accountNumber);
  };

  const handleBeneficiarySelect = (e, setFieldValue) => {
    const { accountNumber, name } = e.target.value;
    setFieldValue("recipientAccountNumber", accountNumber);
    setFieldValue("recipientAccountName", name);
  };

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={isOpen.open} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          //   height: "40%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "30px" }}
                >
                  Transfer To Other Bank
                </Typography>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                    marginBottom: "10px",
                  }}
                >
                  <TextField
                    label="form Type"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.formType}
                    name="formType"
                    sx={{ gridColumn: "span 2" }}
                  >
                    {formTypes.map((option, index) => (
                      <MenuItem key={index} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  {(values.formType == "Single User" ||
                    values.formType == "Web Forms") && (
                    <>
                      <SearchableSelector
                        value={values.username}
                        name="username"
                        sx={{ gridColumn: "span 2" }}
                        handleChange={(e, newValue) =>
                          handleSelectChange(e, newValue, setFieldValue)
                        }
                      />
                      <TextField
                        label="Beneficiary Option"
                        fullWidth
                        select
                        onBlur={handleBlur}
                        onChange={(e) =>
                          handleBeneficiaryChange(e, setFieldValue, values)
                        }
                        value={values.beneficiaryOption}
                        name="beneficiaryOption"
                        error={
                          !!touched.beneficiaryOption &&
                          !!errors.beneficiaryOption
                        }
                        helperText={
                          touched.beneficiaryOption && errors.beneficiaryOption
                        }
                        sx={{ gridColumn: "span 2" }}
                      >
                        {options.map((option, index) => (
                          <MenuItem key={index} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        fullWidth
                        select
                        label={
                          values.beneficiaryOption ==
                          "Transfer to saved beneficiary"
                            ? "Select saved beneficiary"
                            : values.beneficiaryOption ==
                              "Transfer to saved bank account"
                            ? "Select saved bank"
                            : "Select new bank"
                        }
                        onBlur={handleBlur}
                        onChange={
                          values.beneficiaryOption ==
                          "Transfer to saved beneficiary"
                            ? (e) => {
                                handleChange(e);
                                handleBeneficiarySelect(e, setFieldValue);
                              }
                            : handleChange
                        }
                        value={values.bankOrBaneficiary}
                        name="bankOrBaneficiary"
                        error={
                          !!touched.bankOrBaneficiary &&
                          !!errors.bankOrBaneficiary
                        }
                        helperText={
                          touched.bankOrBaneficiary && errors.bankOrBaneficiary
                        }
                        sx={{ gridColumn: "span 2" }}
                      >
                        {banks?.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {values.beneficiaryOption ==
                            "Transfer to new bank account"
                              ? option?.bankName
                              : option?.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        label="Recipient Account Number"
                        onBlur={handleBlur}
                        onChange={
                          values.beneficiaryOption ==
                          "Transfer to new bank account"
                            ? (e) =>
                                handleAccountNumberChange(
                                  e,
                                  setFieldValue,
                                  values
                                )
                            : handleChange
                        }
                        value={values.recipientAccountNumber}
                        name="recipientAccountNumber"
                        error={
                          !!touched.recipientAccountNumber &&
                          !!errors.recipientAccountNumber
                        }
                        helperText={
                          touched.recipientAccountNumber &&
                          errors.recipientAccountNumber
                        }
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        disabled
                        type="text"
                        label="Recipient Account Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.recipientAccountName}
                        name="recipientAccountName"
                        error={
                          !!touched.recipientAccountName &&
                          !!errors.recipientAccountName
                        }
                        helperText={
                          touched.recipientAccountName &&
                          errors.recipientAccountName
                        }
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        label="Amount"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.amount}
                        name="amount"
                        error={!!touched.amount && !!errors.amount}
                        helperText={touched.amount && errors.amount}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        select
                        label="Wallet"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.fromUser}
                        name="fromUser"
                        error={!!touched.fromUser && !!errors.fromUser}
                        helperText={touched.fromUser && errors.fromUser}
                        sx={{ gridColumn: "span 2" }}
                      >
                        {walletOptions?.map((option, index) => {
                          const { acct_name, accountNo } = option;
                          return (
                            <MenuItem key={index} value={option}>
                              {acct_name + " - " + accountNo}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Add a note"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.narration}
                        name="narration"
                        error={!!touched.narration && !!errors.narration}
                        helperText={touched.narration && errors.narration}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Pin"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.pin}
                        name="pin"
                        error={!!touched.pin && !!errors.pin}
                        helperText={touched.pin && errors.pin}
                        sx={{ gridColumn: "span 2" }}
                      />
                    </>
                  )}
                  {values.formType == "Excel Upload" && (
                    <Field label="Upload JPG file" name="file">
                      {({ field, form }) => {
                        const { setFieldValue } = form;
                        return (
                          <TextField
                            {...field}
                            fullWidth
                            type="file"
                            variant="outlined"
                            value={undefined}
                            className={classes.inputFile}
                            onChange={(e) =>
                              handleFileChange(e, field, setFieldValue)
                            }
                            sx={{ gridColumn: "span 2" }}
                          />
                        );
                      }}
                    </Field>
                  )}
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  disabled={isLoading}
                  variant="contained"
                  sx={{ width: "100%", p: 2, mt: 2 }}
                >
                  Transfer
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default TransferToOtherBankModal;
