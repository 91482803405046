import { Modal, Box, Card, Typography, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import TransactionCardList from "../cardComponents/transCardList";
import { convertToWords } from "../../utils/convertToWords";

export function ViewDisburseModal({ modal, setModal }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { createdAt, paymentRef, amount, RecipientProfile } = modal?.data || {};
  const { displayName } = RecipientProfile || {};

  const handleClose = () => {
    setModal({ open: false });
  };
  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          // height: "75%",
          backgroundColor: colors.primary[400],
          p: 5,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
          borderRadius: "5px",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
        >
          <Typography variant="h3" sx={{ mb: 4 }}>
            Transaction Reciept
          </Typography>
          <TransactionCardList
            title="Transaction Date"
            value={createdAt?.split("T")[0]}
          />
          <TransactionCardList
            title="Reference Number"
            value={paymentRef ? paymentRef : "----"}
          />
          <TransactionCardList title="Sender Name" value="----" />
          <TransactionCardList title="Transaction Amount" value={amount} />
          <TransactionCardList
            title="Amount in words"
            value={convertToWords(amount)}
          />
          <TransactionCardList title="Transaction Type" value="----" />
          <TransactionCardList
            title="Recipient"
            value={displayName ? displayName : "----"}
          />
          <TransactionCardList title="Recipient Account" value="-----" />
          <TransactionCardList title="Recipient Bank" value="-----" />
        </Box>
      </Box>
    </Modal>
  );
}
