import React, { useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const SearchableSelectTransfer = ({ value, handleChange, sx }) => {
  const [searchKey, setSearchKey] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const getMerchants = async () => {
      try {
        const response = await axiosPrivate.get(
          "/auth-service/api/v1/search/search-profile-name/" + searchKey
        );
        console.log(response);

        const merchants = response?.data?.data?.map(
          ({ firstName, surname, userId, email }) =>
            firstName + " " + surname + " - " + email + " - " + userId
        );
        setOptions(merchants);
      } catch (error) {
        console.log(error);
      }
    };

    getMerchants();
  }, [searchKey]);

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      options={options}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            onChange={(e) => setSearchKey(e.target.value)}
            label="Search User"
            variant="outlined"
          />
        );
      }}
      sx={{ gridColumn: "span 2" }}
    />
  );
};

export default SearchableSelectTransfer;
