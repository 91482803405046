import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import SearchTable from "../../components/SearchTable";
import RejectRequestModal from "../../components/modals/RejectRequestModal";
import WarningModal from "../../components/modals/WarningModal";

const PaymentLogs = () => {
  const [details, setDetails] = useState();
  const [newDetails, setNewDetails] = useState([]);
  const [rejectModal, setRejectModal] = useState({
    open: false,
    url: "",
    user: {},
  });
  const [modal, setModal] = useState({
    openConfirm: false,
    url: "",
    action: "",
  });
  const { success, snackError, cookies } = useAuth();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const getPaymentLogs = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayagram.ng/wayagram-service/all-posts/admin-get-pending-requests",
        {
          params: {
            page: 1,
            limit: 20,
            sso_id: cookies.auth.user.id,
          },
        }
      );
      console.log(response);
      setDetails(response?.data?.data?.data);
    } catch (error) {
      if (error.response.status === 403) {
        snackError("Sorry, Not Authorized");
      } else {
        console.log(error);
      }
    }
  };

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.filter(
        (log) => log.accountNo === searchKey || log.paymentRef === searchKey
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPaymentLogs();
  }, []);

  const columns = [
    {
      field: "paymentRef",
      headerName: "Reference Id",
      width: 100,
      renderCell: ({ row: { paymentRef } }) =>
        paymentRef ? paymentRef : "----------",
    },
    { field: "type", headerName: "Type", width: 100 },
    {
      field: "accountNo",
      headerName: "Account Number",
      width: 110,
      renderCell: ({ row: { accountNo } }) =>
        accountNo ? accountNo : "----------",
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 90,
    },
    {
      field: "disbursed_amount",
      headerName: "Disburse Amount",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      field: "paymentStatus",
      headerName: "Status",
      width: 100,
      renderCell: ({ row: { paymentStatus } }) => {
        return (
          <Typography
            color={
              paymentStatus == "APPROVED"
                ? colors.greenAccent[400]
                : colors.redAccent[400]
            }
          >
            {paymentStatus}
          </Typography>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 100,
      renderCell: ({ row: { createdAt } }) => createdAt.split("T")[0],
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Button
              onClick={() =>
                setModal({
                  openConfirm: true,
                  user: row,
                  action: "approve payment log",
                  url: "https://services.staging.wayagram.ng/wayagram-service/all-posts/admin-approve-payment-request",
                })
              }
              disabled={row.status === "APPROVED"}
              underline="none"
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
              }}
            >
              Approve
            </Button>
            <Button
              onClick={() =>
                setRejectModal({
                  open: true,
                  user: row,
                  url: "https://services.staging.wayagram.ng/wayagram-service/all-posts/admin-reject-payment-request",
                  action: "reject payment log",
                })
              }
              disabled={row.status === "DECLINED"}
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                ml: 1,
                color: colors.grey[100],
              }}
            >
              Reject
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}

      <RejectRequestModal
        isOpen={rejectModal.open}
        setIsOpen={setRejectModal}
        url={rejectModal.url}
        user={rejectModal.user}
        action={rejectModal.action}
      />

      <WarningModal
        open={modal.openConfirm}
        action={modal.action}
        url={modal.url}
        user={modal.user}
        setOpen={setModal}
      />

      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header
          title="PAYMENT LOGS"
          subtitle="Manage Wayagram Users Payment Logs"
        />

        <Box display={"flex"} justifyContent={"space-between"}>
          <SearchTable
            handleSearch={handleSearch}
            placeholder="ref. id/account number"
          />
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Box
          m="10px"
          width="100%"
          height="70vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={newDetails ? newDetails : []}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default PaymentLogs;
