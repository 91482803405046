import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";

const PaymentRequestModal = ({ isOpen, setModal, title }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const { success, snackError } = useAuth();

  const handleFormSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    delete values.recipientType;
    try {
      const response = await axiosPrivate.post(
        "/payment-request/payment-request-single-waya",
        {
          ...values,
          createdAt: new Date(),
        }
      );
      success(response?.data?.message);
    } catch (error) {
      if (error.response) {
        snackError(error.response.data);
      } else {
        console.log(error);
      }
    }
    setIsLoading(false);
    resetForm();
    handleClose();
  };

  const initialValues = {
    recipientType: "Waya User",
    amount: "",
    senderEmail: "",
    senderId: "",
    senderName: "",
    receiverEmail: "",
    receiverId: "",
    receiverName: "",
    receiverPhoneNumber: "",
    reason: "",
  };
  const checkoutSchema = yup.object().shape({
    recipientType: yup.string().required("Required"),
    amount: yup.number().required("Required"),
    senderEmail: yup.string().email().required("Required"),
    senderId: yup.string().required("Required"),
    senderName: yup.string().required("Required"),
    receiverEmail: yup.string().email().required("Required"),
    receiverId: yup.string().when("recipientType", {
      is: (value) => value == "Waya User",
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    receiverName: yup.string().required("Required"),
    receiverPhoneNumber: yup
      .string()
      .matches(/^234\d{10}$/, "Must be 13 digits and start with 234"),
    reason: yup.string(),
  });

  const options = [
    {
      name: "Waya User",
    },
    {
      name: "Non Waya User",
    },
  ];

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          //   height: "40%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "10px" }}
                >
                  {title}
                </Typography>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                    marginBottom: "10px",
                  }}
                >
                  <TextField
                    label="Recipient Type"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.recipientType}
                    name="recipientType"
                    sx={{ gridColumn: "span 2" }}
                  >
                    {options.map((option, index) => (
                      <MenuItem key={index} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.amount}
                    name="amount"
                    error={!!touched.amount && !!errors.amount}
                    helperText={touched.amount && errors.amount}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Sender Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.senderEmail}
                    name="senderEmail"
                    error={!!touched.senderEmail && !!errors.senderEmail}
                    helperText={touched.senderEmail && errors.senderEmail}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Sender Id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.senderId}
                    name="senderId"
                    error={!!touched.senderId && !!errors.senderId}
                    helperText={touched.senderId && errors.senderId}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Sender Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.senderName}
                    name="senderName"
                    error={!!touched.senderName && !!errors.senderName}
                    helperText={touched.senderName && errors.senderName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Reciever Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.receiverEmail}
                    name="receiverEmail"
                    error={!!touched.receiverEmail && !!errors.receiverEmail}
                    helperText={touched.receiverEmail && errors.receiverEmail}
                    sx={{ gridColumn: "span 2" }}
                  />
                  {values.recipientType == "Waya User" && (
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Reciever Id"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.receiverId}
                      name="receiverId"
                      error={!!touched.receiverId && !!errors.receiverId}
                      helperText={touched.receiverId && errors.receiverId}
                      sx={{ gridColumn: "span 2" }}
                    />
                  )}
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Reciever Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.receiverName}
                    name="receiverName"
                    error={!!touched.receiverName && !!errors.receiverName}
                    helperText={touched.receiverName && errors.receiverName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Reciever Phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.receiverPhoneNumber}
                    name="receiverPhoneNumber"
                    error={
                      !!touched.receiverPhoneNumber &&
                      !!errors.receiverPhoneNumber
                    }
                    helperText={
                      touched.receiverPhoneNumber && errors.receiverPhoneNumber
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Reason"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.reason}
                    name="reason"
                    error={!!touched.reason && !!errors.reason}
                    helperText={touched.reason && errors.reason}
                    sx={{ gridColumn: "span 2" }}
                  />
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  disabled={isLoading}
                  variant="contained"
                  sx={{ width: "100%", p: 2, mt: 2 }}
                >
                  Send Payment Request
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default PaymentRequestModal;
