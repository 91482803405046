import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import VerifyBVNModal from "../modals/VerifyBVNModal";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";

function createData(name, status, detail, action) {
  return { name, status, detail, action };
}

export default function Tier2Table({ tier2Details }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [modal, setModal] = useState({ open: false });
  const axiosPrivate = useAxiosPrivate();
  const { success, snackError, queryUserDetails } = useAuth();
  const { userId } = queryUserDetails?.user[0] || {};

  // console.log(tier2Details);

  const rows = tier2Details
    ? tier2Details?.map((tier2Details) =>
        createData(
          tier2Details?.requirementType,
          tier2Details?.status,
          tier2Details?.verificationReference
            ? tier2Details?.verificationReference
            : "-----------------------------------",
          "verify"
        )
      )
    : [createData("BVN", "pending", "", "verify")];

  const complete =
    rows.length > 0 ? rows?.every((row) => row.status == "APPROVED") : false;

  const handleFormSubmit = async ({ BVN }, actions, setIsLoading) => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.put(
        `/kyc-servicev2/api/v1/admin/kyc/updateCustomerTierData/${71}/${userId}`,
        null,
        {
          params: {
            reference: BVN,
            status: "APPROVED",
            isBvn: true,
          },
        }
      );
      success(response?.data?.data);
    } catch (error) {
      if (error.response) {
        snackError(error?.response?.data?.data);
      } else {
        console.log(error);
      }
    }
    setIsLoading(false);
    actions.resetForm();
    setModal({ open: false });
  };

  return (
    <TableContainer sx={{ bgcolor: colors.primary[400] }}>
      <VerifyBVNModal
        modal={modal}
        setModal={setModal}
        handleFormSubmit={handleFormSubmit}
      />
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Typography
          variant="h2"
          color={colors.grey[500]}
          fontWeight="bold"
          sx={{ mb: "15px" }}
        >
          Tier 2
        </Typography>
        <Typography
          variant="h4"
          bgcolor={complete ? colors.greenAccent[600] : colors.redAccent[600]}
          color={complete ? colors.greenAccent[200] : colors.redAccent[200]}
          p={1.2}
          height={44}
          borderRadius={1}
        >
          {complete ? "completed" : "not completed"}
        </Typography>
      </Box>
      <Table sx={{ minHeight: 180 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", fontSize: "14px" }}>
              KYC Requirement
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", fontSize: "14px" }}
              align="right"
            >
              Status
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", fontSize: "14px" }}
              align="right"
            >
              Details
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", fontSize: "14px" }}
              align="right"
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontSize: "14px",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontSize: "14px",
                  color:
                    row.status == "verified"
                      ? colors.greenAccent[300]
                      : colors.orangeAccent[300],
                }}
              >
                {row.status}
              </TableCell>
              <TableCell align="right">
                <TextField value={row.detail} disabled />
              </TableCell>
              <TableCell align="right">
                <Button
                  color="secondary"
                  variant="contained"
                  underline="none"
                  onClick={() => setModal({ open: true })}
                  sx={{
                    backgroundColor: colors.orangeAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "8px 20px",
                  }}
                  disabled={row.status == "verified"}
                >
                  {row.action}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
