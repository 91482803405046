import { useTheme } from "@emotion/react";
import { Box, Button, TextField } from "@mui/material";
import { tokens } from "../theme";

export function CounterField({
  name,
  label,
  value,
  onChange,
  onIncrement,
  onDecrement,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box display="flex" flexDirection="column" width="130px">
      <TextField
        name={name}
        label={label}
        variant="outlined"
        value={value}
        onChange={onChange}
        disabled
      />
      <Box display="flex" mt={1}>
        <Button
          type="button"
          color="secondary"
          sx={{ fontSize: "20px" }}
          onClick={onDecrement}
          disabled={value === 0}
        >
          -
        </Button>
        <Button
          type="button"
          color="secondary"
          sx={{ fontSize: "20px" }}
          onClick={onIncrement}
        >
          +
        </Button>
      </Box>
    </Box>
  );
}
