import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { tokens } from "../../theme";
import { generateReqId } from "../../utils/generateReqId";

const QRCodeModal = ({ title, open, setOpen }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const { cookies, success, snackError } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { firstName, lastName } = cookies.auth.user;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 480,
    height: options.length > 0 ? 355 : 310,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  };

  useEffect(() => {
    const getAdminList = async () => {
      if (title === "export") {
        try {
          const response = await axiosPrivate.get(
            "/auth-service/api/v1/admin/users-admin-users",
            {
              params: {
                page: 0,
                size: 100,
              },
            }
          );
          setOptions(response?.data?._embedded?.userResponse);
        } catch (error) {
          console.log(error);
        }
      }
    };

    getAdminList();

    return () => setOptions([]);
  }, [title === "export"]);

  const handleClose = () => {
    setOpen((prev) => ({ ...prev, open: false }));
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
  };

  const handleSubmit = async () => {
    // Handle form submission here

    let response;
    if (title === "export") {
      const { firstName, lastName, email } = selectValue;
      try {
        response = await axiosPrivate.post(
          "/logs-service/api/v1/requestlog/create",
          {
            customerName: `${firstName} ${lastName}`,
            payload: JSON.stringify({
              noOfQrCode: inputValue,
              adminEmail: email,
            }),
            requestId: generateReqId(),
            requestType: "exportQRCode",
          }
        );
        success(response?.data?.message);
      } catch (error) {
        snackError(error?.response?.data?.message);
      }
    } else if (title == "generate") {
      try {
        response = await axiosPrivate.post(
          "/logs-service/api/v1/requestlog/create",
          {
            customerName: `${firstName} ${lastName}`,
            payload: JSON.stringify({
              count: inputValue,
            }),
            requestId: generateReqId(),
            requestType: "generateQRCode",
          }
        );
        success(response?.data?.message);
      } catch (error) {
        snackError(error?.response?.data?.message);
      }
    }
    setInputValue("");
    setSelectValue("");
    handleClose();
  };

  //   console.log(options);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      display="flex"
    >
      <Box sx={style}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{ position: "absolute", right: 16, top: 10 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>{title} QR Code</DialogTitle>
        <DialogContent>
          <TextField
            label={`No of QR Code to ${title}`}
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
            sx={{ marginBottom: "1rem", marginTop: "1rem" }}
          />
          {options.length > 0 && (
            <TextField
              label="Select Admin"
              fullWidth
              select
              value={selectValue}
              onChange={handleSelectChange}
            >
              {options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option.firstName} {option.lastName}
                </MenuItem>
              ))}
            </TextField>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            underline="none"
            sx={{
              backgroundColor: colors.orangeAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
};

export default QRCodeModal;
