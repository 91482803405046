import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useAuth from "./useAuth";

const useAxiosPrivate = () => {
  const { cookies } = useAuth();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `${cookies?.auth?.token}`;
          config.headers["CLIENT-ID"] = 'WAYABANK';
          config.headers["CLIENT-TYPE"] = 'ADMIN';
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // const responseIntercept = axiosPrivate.interceptors.response.use(
    //   (respnse) => respnse,
    //   async (error) => {
    //     const { prevRequest } = error?.config;
    //     if (error?.response?.status === 403 && !prevRequest.sent) {
    //       prevRequest.sent = true;
    //       return axiosPrivate(prevRequest);
    //     }
    //     return Promise.reject(error);
    //   }
    // );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      // axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [cookies?.auth]);
  return axiosPrivate;
};

export default useAxiosPrivate;
