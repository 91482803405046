import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import SearchTable from "../../components/SearchTable";
import TransferModal from "../../components/modals/TransferModal";
import SpreadSheetUploadModal from "../../components/modals/SpreadSheetUploadModal";
import MultipleNonWayaTransferModal from "../../components/modals/MultipleNonWayaTransferModal";

const NonWayaTransfer = () => {
  const [details, setDetails] = useState({ nonWayaList: [] });
  const [result, setResult] = useState([]);
  const [modal, setModal] = useState({ open: false });
  const [spreadSheetModal, setSpreadSheetModal] = useState({ open: false });
  const [multipleNonWayaModal, setMultipleNonWayaModal] = useState({
    open: false,
  });
  const [reversalDays, setReversalDays] = useState({});

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleOption = (e) => {
    if (e.target.textContent == "Send to Single User") {
      setModal({ open: true });
    } else if (e.target.textContent == "Send Using Webforms") {
      setMultipleNonWayaModal({ open: true });
    } else if (e.target.textContent == "Upload Excel") {
      setSpreadSheetModal({
        open: true,
        action: "bulk transfer",
        title: "NonWaya Bulk Transfer",
      });
    }
    setAnchorEl(null);
  };

  const getNonWayaTransfer = async () => {
    try {
      const response = await axiosPrivate.get(
        "/temporal-service/api/v1/wallet/non-waya/payment/list-transactions",
        {
          params: {
            page: 0,
            size: 1000,
          },
        }
      );
      setDetails(response?.data.data);
      setResult(response?.data?.data?.nonWayaList);
    } catch (error) {
      console.log(error);
    }
  };

  const getReversalDays = async () => {
    try {
      const response = await axiosPrivate.get(
        "/temporal-service/api/v1/config"
      );
      setReversalDays(response?.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReversalDays();
    getNonWayaTransfer();
  }, []);

  const handleSearch = async ({ searchKey }) => {
    const emailRegex =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    const phoneRegex = /^(\+234-|\+234|0)?\d{10}$/;

    if (searchKey.match(emailRegex) || searchKey.match(phoneRegex)) {
      try {
        let result = details?.nonWayaList?.filter(
          (request) => request.emailOrPhone === searchKey
        );
        setResult(result);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const columns = [
    {
      field: "fullName",
      headerName: "Sender Name",
      width: 200,
    },
    {
      field: "emailOrPhone",
      headerName: "Reciepient Email/Phone",
      width: 200,
    },
    {
      field: "tranAmount",
      headerName: "Amount",
      width: 100,
    },
    {
      field: "tokenId",
      headerName: "Token",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 200,
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <MultipleNonWayaTransferModal
        modal={multipleNonWayaModal}
        setModal={setMultipleNonWayaModal}
      />
      <TransferModal isOpen={modal.open} setModal={setModal} />
      <SpreadSheetUploadModal
        modal={spreadSheetModal}
        setModal={setSpreadSheetModal}
      />
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ justifyContent: "space-between" }}
      >
        <Grid xs={12} sm={12} md={6} lg={3.6} xl={3}>
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
          >
            <Header
              title="NON WAYABANK TRANSFERS"
              subtitle="Manage Non Wayabank Transfers"
            />
          </Box>
        </Grid>
        <Box display="flex" justifyContent="end" paddingRight={1} height="45px">
          <SearchTable handleSearch={handleSearch} placeholder="Email/Phone" />
          <Box>
            <Button
              onClick={(e) => toggleMenu(e)}
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginLeft: "15px",
              }}
            >
              <AddIcon sx={{ mr: "10px" }} />
              New Transfer
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              //   classes={{ paper: "dropdown-menu2" }}
            >
              <MenuItem onClick={(e) => handleOption(e)}>
                Send to Single User
              </MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>
                Send Using Webforms
              </MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>Upload Excel</MenuItem>
            </Menu>
          </Box>
        </Box>

        <Box
          m="10px"
          width="100%"
          height="65vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={result}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default NonWayaTransfer;
