const qrcode = require("qrcode");

export const generateQRCode = async (base64String) => {
  try {
    const qrCodeImage = await qrcode.toDataURL(base64String);
    return qrCodeImage;
  } catch (error) {
    console.error(error);
  }
};

generateQRCode();
