import {
  TextField,
  Modal,
  Button,
  Box,
  useTheme,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { tokens } from "../../theme";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: 540,
  border: "1px solid #000",
  boxShadow: 24,
  p: 7,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
};

let currentOTPIndex = 0;

export default ({ handleOTPSubmit, modal, setModal }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeOtp, setActiveOtp] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { cookies } = useAuth();

  const {
    amount,
    fromUser,
    toUser,
    username,
    recipientEmail,
    recipientPhone,
    recipientId,
    recipientAccountName,
  } = modal.values || {};

  const otpRef = useRef();

  const handleClose = () => {
    setModal({ open: false });
    setOtp(new Array(6).fill(""));
  };

  useEffect(() => {
    otpRef.current?.focus();
  }, [modal.open, activeOtp]);

  const handleChange = ({ target }) => {
    const { value } = target;
    const newOTP = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);
    if (!value) setActiveOtp(currentOTPIndex - 1);
    else setActiveOtp(currentOTPIndex + 1);
    setOtp(newOTP);
  };

  const handleKeyDown = ({ key }, index) => {
    currentOTPIndex = index;
    if (key === "Backspace") setActiveOtp(currentOTPIndex - 1);
  };

  return (
    <div>
      <Modal
        open={modal.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        display="flex"
      >
        <Box sx={style} bgcolor={colors.primary[400]} textAlign="center">
          <Box mb="10px">
            <Typography
              variant="h2"
              color={colors.grey[100]}
              fontWeight="bold"
              sx={{ mb: "5px" }}
            >
              OTP Authentication
            </Typography>
            <Typography variant="h5" color={colors.orangeAccent[400]}>
              Please enter OTP to complete transaction
            </Typography>
          </Box>
          <Box
            my={3}
            bgcolor={colors.primary[500]}
            p={2}
            borderRadius={2}
            minWidth={380}
            minHeight={80}
            textAlign="left"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6">
                Send from:
                <span
                  style={{
                    color: colors.greenAccent[500],
                    marginLeft: 4,
                    fontWeight: "bold",
                    fontSize: 15,
                  }}
                >
                  {fromUser?.accountNo}
                </span>
              </Typography>
              <Typography variant="h6">
                Amount:
                <span
                  style={{
                    color: colors.greenAccent[500],
                    marginLeft: 4,
                    fontWeight: "bold",
                    fontSize: 15,
                  }}
                >
                  {amount}
                </span>
              </Typography>
            </Box>
            <Typography variant="h6">
              Send to:
              <span
                style={{
                  color: colors.greenAccent[500],
                  marginLeft: 4,
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                {toUser?.acct_name
                  ? toUser?.acct_name
                  : recipientEmail
                  ? recipientEmail
                  : recipientPhone
                  ? recipientPhone
                  : recipientId
                  ? recipientId
                  : recipientAccountName}
              </span>
            </Typography>
          </Box>
          <Box>
            {otp.map((_, index) => {
              return (
                <Fragment key={index}>
                  <TextField
                    type="number"
                    value={otp[index]}
                    inputRef={index === activeOtp ? otpRef : null}
                    onChange={handleChange}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    sx={{
                      width: 50,
                      height: 40,
                      marginX: 1,
                    }}
                  />
                </Fragment>
              );
            })}
          </Box>
          <Box mt={3}>
            <Button
              onClick={() => {
                handleOTPSubmit(
                  otp.join(""),
                  cookies?.auth?.user?.phoneNumber,
                  setIsSubmitting
                );
                handleClose();
              }}
              fullWidth
              disabled={isSubmitting}
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ py: 2, px: 4, mt: 1 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
