import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

const TransactionFormModal = ({
  isOpen,
  setModal,
  setTransferToOwnAccountModal,
  setTransferToOtherAccountModal,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: 450,
          height: 400,
          backgroundColor: colors.primary[400],
          p: 3,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          sx={{ textAlign: "center", marginBottom: "10px" }}
        >
          Transfer To Wayabank
        </Typography>
        <Button
          onClick={() => setTransferToOwnAccountModal({ open: true })}
          sx={{
            bgcolor: colors.greenAccent[700],
            color: colors.grey[100],
            width: "70%",
            p: 2,
            mt: 4,
            fontWeight: "bold",
          }}
        >
          Transfer to Own account
        </Button>
        <Button
          onClick={() => setTransferToOtherAccountModal({ open: true })}
          sx={{
            bgcolor: colors.blueAccent[700],
            color: colors.grey[100],
            width: "70%",
            p: 2,
            mt: 6,
            fontWeight: "bold",
          }}
        >
          Transfer to Other account
        </Button>
      </Box>
    </Modal>
  );
};

export default TransactionFormModal;
