import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useEffect } from "react";
import { useState } from "react";

const useFieldMarketerDetails = () => {
  const axiosPrivate = useAxiosPrivate();
  const [details, setDetails] = useState([]);

  const getFieldMarketer = async () => {
    try {
      const response = await axiosPrivate.post(
        "/qrcode-service/api/v1/log/all-marketer"
      );
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalActive = async () => {
    try {
      const response = await axiosPrivate.get(
        "/qrcode-service/api/v1/log/total-active"
      );
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalInactive = async () => {
    try {
      const response = await axiosPrivate.get(
        "/qrcode-service/api/v1/log/total-inactive"
      );
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const QRDetails = async () => {
      const [fieldMarketer, totalActive, totalInactive] = await Promise.all([
        getFieldMarketer(),
        getTotalActive(),
        getTotalInactive(),
      ]);

      setDetails({
        fieldMarketer,
        totalActive,
        totalInactive,
      });
    };
    QRDetails();
  }, []);

  return details;
};

export default useFieldMarketerDetails;
