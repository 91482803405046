import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { tokens } from "../../theme";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import FileUploadInput from "../fileUploadInput";
import { useState } from "react";
import { useEffect } from "react";
import SearchableSelect from "../SearchableSelect";

const useStyles = makeStyles({
  textArea: {
    gridColumn: "span 2",
  },
});

const PageModal = ({ modal, setModal }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { success, snackError, cookies } = useAuth();
  const { id } = cookies?.auth?.user || {};
  const axiosPrivate = useAxiosPrivate();
  const [groupCategory, setGroupCategory] = useState();
  const classes = useStyles();

  const handleFormSubmit = async (values) => {
    console.log(values);
    const {
      imageUrl,
      headerImageUrl,
      userId: { id: userId },
      categoryId,
      customCategory,
      username,
      title,
      description,
      websiteUrl,
      isPublic,
    } = values;

    try {
      const response = await axiosPrivate.postForm(
        "https://services.staging.wayagram.ng/wayagram-service/main/pages/admin/create",
        {
          imageUrl,
          headerImageUrl,
          userId,
          categoryId,
          customCategory,
          username,
          title,
          description,
          websiteUrl,
          isPublic,
        }
      );
      console.log(response);
      success(response?.data?.message);
    } catch (error) {
      console.log(error);
    }
    handleClose();
  };

  const initialValues = {
    imageUrl: "",
    headerImageUrl: "",
    userId: null,
    categoryId: "",
    customCategory: "",
    username: "",
    title: "",
    description: "",
    websiteUrl: "",
    isPublic: "",
  };

  const checkoutSchema = yup.object().shape({
    imageUrl: yup.mixed(),
    headerImageUrl: yup.mixed(),
    userId: yup.object().required("Required"),
    categoryId: yup.string().required("Required"),
    customCategory: yup.string(),
    username: yup.string().required("Required"),
    title: yup.string().required("Required"),
    description: yup.string().required("Required"),
    websiteUrl: yup.string(),
    isPublic: yup.boolean().required("Required"),
  });

  useEffect(() => {
    const getGroupCategory = async () => {
      try {
        const response = await axiosPrivate.get(
          "https://services.staging.wayagram.ng/wayagram-service/main/page/get-all-page-categories",
          {
            params: {
              page: 1,
              limit: 100,
              sso_id: id,
            },
          }
        );
        setGroupCategory(response?.data?.interests?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getGroupCategory();
  }, []);

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  const pageType = [
    {
      name: "Public",
      value: true,
    },
    {
      name: "Private",
      value: false,
    },
  ];

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "30px" }}
                >
                  Create New Page
                </Typography>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                    marginBottom: "10px",
                  }}
                >
                  <FileUploadInput
                    name="imageUrl"
                    label="Image"
                    sx={{ gridColumn: "span 2" }}
                    error={!!touched.imageUrl && !!errors.imageUrl}
                    helperText={touched.imageUrl && errors.imageUrl}
                  />
                  <FileUploadInput
                    name="headerImageUrl"
                    label="Header Image"
                    sx={{ gridColumn: "span 2" }}
                    error={!!touched.headerImageUrl && !!errors.headerImageUrl}
                    helperText={touched.headerImageUrl && errors.headerImageUrl}
                  />
                  <SearchableSelect
                    value={values.userId}
                    setValue={setFieldValue}
                    label="Search Page Owner"
                    name="userId"
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    label="Page Category"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.categoryId}
                    name="categoryId"
                    error={!!touched.categoryId && !!errors.categoryId}
                    helperText={touched.categoryId && errors.categoryId}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {groupCategory?.map((type, index) => (
                      <MenuItem key={index} value={type.title}>
                        {type.title}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Custom Category"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customCategory}
                    name="customCategory"
                    error={!!touched.customCategory && !!errors.customCategory}
                    helperText={touched.customCategory && errors.customCategory}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    error={!!touched.username && !!errors.username}
                    helperText={touched.username && errors.username}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Page Title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    name="title"
                    error={!!touched.title && !!errors.title}
                    helperText={touched.title && errors.title}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextareaAutosize
                    className={classes.textArea}
                    minRows={3}
                    variant="filled"
                    type="text"
                    label="Description"
                    placeholder="A brief description of the page..."
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    name="description"
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                  />
                  <TextField
                    label="Website"
                    fullWidth
                    type="text"
                    variant="filled"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.websiteUrl}
                    name="websiteUrl"
                    error={!!touched.websiteUrl && !!errors.websiteUrl}
                    helperText={touched.websiteUrl && errors.websiteUrl}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    label="Page Type"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.isPublic}
                    name="isPublic"
                    error={!!touched.isPublic && !!errors.isPublic}
                    helperText={touched.isPublic && errors.isPublic}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {pageType?.map((type, index) => (
                      <MenuItem key={index} value={type.value}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ width: "100%", p: 2, mt: 3 }}
                >
                  Create Page
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default PageModal;
