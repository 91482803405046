import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { tokens } from "../../theme";
import SearchableSelect from "../SearchableSelect";
import { useState } from "react";
import UpwardDateRangePicker from "../UpwardDateRangePicker";
import useAuth from "../../hooks/useAuth";
import { format, getTime } from "date-fns";

const useStyles = makeStyles({
  textArea: {
    width: "100%",
    marginBottom: 10,
  },
});

const PollModal = ({
  modal,
  setModal,
  setOptionsModal,
  pollOptions,
  setPollOptions,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const { cookies } = useAuth();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleFormSubmit = async (values, { resetForm }) => {
    // console.log(values, range[0]);
    setIsLoading(true);
    const {
      amount,
      creator: { id },
      description,
      voteCategory,
      voteLimit,
      voteType,
    } = values;
    const { startDate, endDate } = range[0];
    try {
      const response = await axiosPrivate.postForm(
        "https://services.staging.wayagram.ng/wayagram-service/all-posts/admin-poll-create",
        {
          profile_id: id,
          pollCategory: voteCategory.toLowerCase(),
          type: "user",
          description,
          voteLimit,
          amount,
          isPaid: voteType == "FREE",
          mentions: null,
          hashtags: null,
          startDate: format(startDate, "dd/MM/yyyy"),
          endDate: format(endDate, "dd/MM/yyyy"),
          startTime: getTime(startDate),
          endTime: getTime(endDate),
        },
        {
          params: {
            sso_id: cookies?.auth?.user?.id,
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    resetForm();
    setPollOptions([]);
    handleClose();
  };

  const initialValues = {
    creator: null,
    voteType: "",
    voteCategory: "",
    description: "",
    voteLimit: "",
    amount: "",
  };

  const checkoutSchema = yup.object().shape({
    creator: yup.object().required("Required"),
    voteType: yup.string().required("Required"),
    voteCategory: yup.string().required("Required"),
    description: yup.string().required("Required"),
    voteLimit: yup.number().required("Required"),
    amount: yup.number().required("Required"),
  });

  const options = [
    {
      name: "FREE",
    },
    {
      name: "PAID",
    },
  ];

  const options2 = [
    {
      name: "TEXT",
    },
    {
      name: "IMAGE",
    },
  ];

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: 500,
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "30px" }}
                >
                  Create New Poll
                </Typography>
                <SearchableSelect
                  value={values.creator}
                  setValue={setFieldValue}
                  name="creator"
                  label="Search Creator"
                  error={!!touched.creator && !!errors.creator}
                  sx={{ mb: 1.5 }}
                />
                <TextField
                  label="Vote Type"
                  fullWidth
                  select
                  name="voteType"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.voteType}
                  error={!!touched.voteType && !!errors.voteType}
                  helperText={touched.voteType && errors.voteType}
                  sx={{ marginBottom: "10px" }}
                >
                  {options.map((option, index) => (
                    <MenuItem key={index} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Vote Category"
                  fullWidth
                  select
                  name="voteCategory"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.voteCategory && !!errors.voteCategory}
                  helperText={touched.voteCategory && errors.voteCategory}
                  value={values.voteCategory}
                  sx={{ mb: 2 }}
                >
                  {options2.map((option, index) => (
                    <MenuItem key={index} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextareaAutosize
                  className={classes.textArea}
                  minRows={4}
                  variant="filled"
                  type="text"
                  label="Description"
                  placeholder="Description of the vote"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  name="description"
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Vote Limit"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.voteLimit}
                  name="voteLimit"
                  error={!!touched.voteLimit && !!errors.voteLimit}
                  helperText={touched.voteLimit && errors.voteLimit}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Amount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount}
                  name="amount"
                  error={!!touched.amount && !!errors.amount}
                  helperText={touched.amount && errors.amount}
                  sx={{ mb: 2 }}
                />
                <UpwardDateRangePicker
                  range={range}
                  setRange={setRange}
                  sx={{ width: "100%" }}
                />
                <Box display="flex">
                  <Button
                    disabled={pollOptions.length < 2 || isLoading}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ width: "100%", p: 2, mt: 2 }}
                  >
                    Create Poll
                  </Button>
                  <Button
                    onClick={() => setOptionsModal({ open: true })}
                    variant="contained"
                    sx={{
                      width: "100%",
                      p: 2,
                      mt: 2,
                      ml: 2,
                      bgcolor: colors.blueAccent[400],
                    }}
                  >
                    Edit Options
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default PollModal;
