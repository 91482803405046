import React, { useEffect } from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import SearchableSelectTransfer from "../SearchableSelectTransfer";
import { generateReqId } from "../../utils/generateReqId";

const CreateWalletModal = ({ isOpen, setModal }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { success, snackError } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { queryUserDetails } = useAuth();
  const { user } = queryUserDetails;
  const handleFormSubmit = async (values) => {
    const { description, userType } = values;

    try {
      const response = await axiosPrivate.post(
        "/logs-service/api/v1/requestlog/create",
        {
          customerName: user[0].firstName + " " + user[0].lastName,
          payload: JSON.stringify({
            accountType: "Savings",
            description,
            simulated: userType,
            userId: user[0].userId,
          }),
          requestId: generateReqId(),
          requestType: "ADDWALLET",
        }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }

    handleClose();
  };

  const initialValues = {
    description: "",
    userType: "",
  };

  useEffect(() => {}, []);

  const checkoutSchema = yup.object().shape({
    description: yup.string().required("Required"),
    userType: yup.string().required("Required"),
  });

  const options = [
    {
      name: "Stimulated User",
      value: "true",
    },
    {
      name: "Non Stimulated User",
      value: "false",
    },
  ];

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: "40%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "30px" }}
                >
                  Add New Account
                </Typography>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                    marginBottom: "10px",
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    name="description"
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    label="User Type"
                    fullWidth
                    select
                    name="userType"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.userType}
                    sx={{ gridColumn: "span 4" }}
                  >
                    {options?.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ width: "100%", p: 2, mt: 2 }}
                >
                  Create Wallet
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default CreateWalletModal;
