import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Button, TextField, Typography } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { useTheme } from "@emotion/react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState } from "react";
import { generateReqId } from "../../utils/generateReqId";
import OTPModal from "../modals/OTPModal";
import { tokens } from "../../theme";

function createData(name, status, detail, action) {
  return { name, status, detail, action };
}

export default function Tier1Table() {
  const { queryUserDetails, success } = useAuth();
  const { manageKYC } = queryUserDetails;
  const {
    email,
    emailVerified,
    phoneNumber,
    phoneVerified,
    firstName,
    lastName,
  } = manageKYC;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [otpModal, setOTPModal] = useState({ open: false, emailOrPhone: null });

  const handleOTPSubmit = async (otp, setIsSubmitting) => {
    setIsSubmitting(true);
    const emailRegex =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const phoneRegex = /^(\+234-|\+234|0)?\d{10}$/;

    if (otpModal?.emailOrPhone?.match(phoneRegex)) {
      try {
        const response = await axiosPrivate.post(
          "/logs-service/api/v1/requestlog/create",
          {
            customerName: firstName + " " + lastName,
            payload: JSON.stringify({
              otp: otp.join(""),
              phoneOrEmail: otpModal?.emailOrPhone,
            }),
            requestId: generateReqId(),
            requestType: "phoneKYC",
          }
        );
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    } else if (otpModal?.emailOrPhone?.match(emailRegex)) {
      try {
        const response = await axiosPrivate.post(
          "/logs-service/api/v1/requestlog/create",
          {
            customerName: firstName + " " + lastName,
            payload: JSON.stringify({
              otp: otp.join(""),
              phoneOrEmail: otpModal?.emailOrPhone,
            }),
            requestId: generateReqId(),
            requestType: "emailKYC",
          }
        );
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    }
    setIsSubmitting(false);
  };

  const handleVerify = async (row) => {
    setIsLoading(true);
    if (row.name == "Phone Number Verification") {
      try {
        const response = await axiosPrivate.get(
          "/auth-service/api/v1/auth/resend-otp/" +
            row?.detail?.slice(1, row?.detail?.length)
        );
        success(response?.data?.message);
        setOTPModal((prev) => ({
          ...prev,
          open: true,
          emailOrPhone: row?.detail,
        }));
      } catch (error) {
        console.log(error);
      }
    } else if (row.name == "Email Address Verification") {
      try {
        const response = await axiosPrivate.get(
          "/auth-service/api/v1/auth/resend-otp-mail/" + row?.detail
        );
        success(response?.data?.message);
        setOTPModal((prev) => ({
          ...prev,
          open: true,
          emailOrPhone: row?.detail,
        }));
      } catch (error) {
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  const rows = [
    createData(
      "Phone Number Verification",
      phoneVerified ? "verified" : "pending",
      phoneNumber ? phoneNumber : "-----------------------------------",
      "verify"
    ),
    createData(
      "Email Address Verification",
      emailVerified ? "verified" : "pending",
      email ? email : "-----------------------------------",
      "verify"
    ),
  ];

  const complete = rows?.every((row) => row.status == "verified");

  return (
    <TableContainer sx={{ bgcolor: colors.primary[400] }}>
      <OTPModal
        setModal={setOTPModal}
        modal={otpModal}
        handleOTPSubmit={handleOTPSubmit}
      />

      <Box display="flex" justifyContent="space-between" mb={3}>
        <Typography
          variant="h2"
          color={colors.grey[500]}
          fontWeight="bold"
          sx={{ mb: "15px" }}
        >
          Tier 1
        </Typography>
        <Typography
          variant="h4"
          bgcolor={complete ? colors.greenAccent[600] : colors.redAccent[600]}
          color={complete ? colors.greenAccent[200] : colors.redAccent[200]}
          p={1.2}
          height={44}
          borderRadius={1}
        >
          {complete ? "completed" : "not completed"}
        </Typography>
      </Box>
      <Table sx={{ minHeight: 180 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", fontSize: "14px" }}>
              KYC Requirement
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", fontSize: "14px" }}
              align="right"
            >
              Status
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", fontSize: "14px" }}
              align="right"
            >
              Details
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", fontSize: "14px" }}
              align="right"
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontSize: "14px",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontSize: "14px",
                  color:
                    row.status == "verified"
                      ? colors.greenAccent[300]
                      : colors.orangeAccent[300],
                }}
              >
                {row.status}
              </TableCell>
              <TableCell align="right">
                <TextField value={row.detail} disabled />
              </TableCell>
              <TableCell align="right">
                <Button
                  color="secondary"
                  variant="contained"
                  underline="none"
                  onClick={() => handleVerify(row)}
                  sx={{
                    backgroundColor: colors.orangeAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "8px 20px",
                  }}
                  disabled={row.status == "verified" || isLoading}
                >
                  {row.action}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
