import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import SearchTable from "../../components/SearchTable";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import QRCodeModal from "../../components/modals/QRCodeModal";

const WayaQuickTransaction = () => {
  const [details, setDetails] = useState({});
  const [newDetails, setNewDetails] = useState([]);
  const { setQueryTransaction } = useAuth();
  const [qrCodeModal, setQrCodeModal] = useState({
    open: false,
    title: "",
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const getTransactions = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayapay.ng/payment-gateway/api/v1/transaction/report",
        {
          params: {
            pageNo: 1,
            pageSize: 20,
          },
        }
      );
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.transactions?.filter(
        (transaction) =>
          transaction.refNo === searchKey ||
          transaction.merchantName?.toLowerCase() === searchKey.toLowerCase()
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getDetails = async () => {
      const [transactions] = await Promise.all([getTransactions()]);
      setDetails({ transactions });
    };
    getDetails();
  }, []);

  const columns = [
    { field: "refNo", headerName: "Reference Id", width: 200 },
    {
      field: "merchantName",
      headerName: "Business Name",
      width: 200,
    },
    {
      field: "channel",
      headerName: "Payment Method",
      width: 100,
    },
    {
      field: "amount",
      headerName: "Amount",
      cellClassName: "name-column--cell",
      width: 100,
    },
    {
      field: "tranDate",
      headerName: "Created At",
      width: 150,
    },
    {
      field: "status",
      headerName: "Payment Status",
      width: 100,
      renderCell: ({ row: { status } }) => {
        return (
          <Typography
            variant="h6"
            sx={{
              color:
                status == "SUCCESSFUL"
                  ? colors.greenAccent[400]
                  : colors.orangeAccent[400],
            }}
          >
            {status}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: ({ row }) => {
        return (
          <Button
            onClick={() => {
              setQueryTransaction(row);
              navigate("/transaction_details_wayaquick");
            }}
            underline="none"
            sx={{
              backgroundColor: colors.orangeAccent[700],
              color: colors.grey[100],
              fontSize: "12px",
            }}
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <QRCodeModal
        open={qrCodeModal.open}
        setOpen={setQrCodeModal}
        title={qrCodeModal.title}
      />

      {/* GRID & CHARTS */}
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ justifyContent: "space-between" }}
      >
        <Grid xs={12} sm={12} md={6} lg={3.6} xl={3}>
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
          >
            <Header
              title="TRANSACTION HISTORY"
              subtitle="Manage WayaQuick Terminals History"
            />
          </Box>
        </Grid>
        <Box mx={2} mt={1}>
          <SearchTable
            handleSearch={handleSearch}
            placeholder="Ref. Id/Business Name"
          />
        </Box>

        <Box
          m="10px"
          width="100%"
          height="65vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row?.refNo;
            }}
            rows={newDetails ? newDetails : []}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default WayaQuickTransaction;
