// docs https://github.com/azouaoui-med/react-pro-sidebar
import { useEffect, useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import {
  useTheme,
  Box,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import BookIcon from "@mui/icons-material/Book";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PaymentsIcon from "@mui/icons-material/Payments";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import TagIcon from "@mui/icons-material/Tag";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import RuleFolderIcon from "@mui/icons-material/RuleFolder";
import PagesIcon from "@mui/icons-material/Pages";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { tokens } from "../../../theme";
import useAuth from "../../../hooks/useAuth";
import { ExpandMore } from "@mui/icons-material";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === to) {
      setSelected(title);
    }
  }, []);

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const MyProSidebar = () => {
  const { cookies } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Manage Users");
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (key) => {
    setExpanded((prev) => {
      if (key === prev) {
        return false;
      } else {
        return key;
      }
    });
  };

  return !cookies?.auth?.user ? (
    <></>
  ) : (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 10000,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          // padding: "5px 35px 5px 20px !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: `${colors.redAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
        "& .menu-item.active": {
          color: `${colors.orangeAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
      }}
    >
      <Sidebar breakPoint="md" backgroundColor={colors.primary[400]}>
        <Menu
          iconshape="square"
          style={{
            paddingRight: collapsed ? "10px" : "25px",
          }}
        >
          <MenuItem
            icon={
              collapsed ? (
                <MenuOutlinedIcon onClick={() => collapseSidebar()} />
              ) : (
                <PersonPinIcon />
              )
            }
            style={{
              margin: "10px 0 10px 0",
              color: colors.grey[100],
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="5px"
              >
                <Typography variant="h6" color={colors.grey[100]}>
                  {cookies.auth.user.roles[0].slice(5).replace("_", " ")}
                </Typography>
                <IconButton
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!collapsed && (
            <Box mb="15px">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  "& .avater-image": {
                    backgroundColor: colors.primary[500],
                  },
                }}
              >
                <img
                  className="avater-image"
                  alt="profile user"
                  width="70px"
                  height="70px"
                  src={
                    cookies?.auth?.user?.profileImage
                      ? cookies?.auth?.user?.profileImage
                      : "../../assets/avatar-person.jpg"
                  }
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "0 0 40px 0" }}
                >
                  {`${cookies?.auth?.user?.firstName} ${cookies?.auth?.user?.lastName}`}
                </Typography>
              </Box>
            </Box>
          )}
          <Box paddingLeft={collapsed ? undefined : "10%"}>
            <Item
              title="Manage Users"
              to="/"
              icon={<ManageAccountsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Accordion
              expanded={expanded === "WayaBank"}
              onChange={() => handleChange("WayaBank")}
              sx={{
                backgroundColor: colors.primary[400],
                border: "none",
                boxShadow: "none",
              }}
            >
              <AccordionSummary expandIcon={collapsed ? "" : <ExpandMore />}>
                <Typography
                  variant="h5"
                  color={
                    [
                      "Request Logs  ",
                      "Transactions",

                      "Payment Requests",

                      "Non Waya Transfers",

                      "Manage Fraud",

                      "KYC Management ",

                      "QR Code",

                      "Manage OTP",
                    ].includes(selected) &
                    (expanded !== "WayaBank")
                      ? colors.orangeAccent[400]
                      : colors.grey[300]
                  }
                >
                  {collapsed ? "Bank" : "WayaBank"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px" }}>
                <Item
                  title="Transactions"
                  to="/transactions"
                  icon={<ReceiptLongIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Payment Requests"
                  to="/payment_requests"
                  icon={<PaymentsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Request Logs  "
                  to="/wayabank_request_log"
                  icon={<BookIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Non Waya Transfers"
                  to="/non_waya_transfers"
                  icon={<ManageAccountsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Manage Fraud"
                  to="/manage_fraud"
                  icon={<RuleFolderIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="KYC Management "
                  to="/manage_kyc"
                  icon={<FolderSharedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="QR Code"
                  to="/qr_code"
                  icon={<QrCode2Icon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Manage OTP"
                  to="/otp"
                  icon={<HelpOutlineOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "WayaPos"}
              onChange={() => handleChange("WayaPos")}
              sx={{
                backgroundColor: colors.primary[400],
                border: "none",
                boxShadow: "none",
              }}
            >
              <AccordionSummary expandIcon={collapsed ? "" : <ExpandMore />}>
                <Typography
                  variant="h5"
                  color={
                    [
                      "Request Logs ",

                      "Terminal Types",

                      "Manage Pos",

                      "Transaction History",

                      "Settlement History",
                    ].includes(selected) &
                    (expanded !== "WayaPos")
                      ? colors.orangeAccent[400]
                      : colors.grey[300]
                  }
                >
                  {collapsed ? "Pos" : "WayaPos"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px" }}>
                <Item
                  title="Request Logs "
                  to="/request_logs_wayapos"
                  icon={<BookIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Terminal Types"
                  to="/manage_terminals"
                  icon={<PagesIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Manage Pos"
                  to="/manage_pos"
                  icon={<PointOfSaleIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Transaction History"
                  to="/transaction_history"
                  icon={<ReceiptLongIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Settlement History"
                  to="/settlement_history"
                  icon={<AutoAwesomeMosaicIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "WayaQuick"}
              onChange={() => handleChange("WayaQuick")}
              sx={{
                backgroundColor: colors.primary[400],
                border: "none",
                boxShadow: "none",
              }}
            >
              <AccordionSummary expandIcon={collapsed ? "" : <ExpandMore />}>
                <Typography
                  variant="h5"
                  color={
                    [
                      "KYC Management",
                      "Settlement History ",
                      "Request Log ",
                      "Transaction History ",
                    ].includes(selected) &
                    (expanded !== "WayaQuick")
                      ? colors.orangeAccent[400]
                      : colors.grey[300]
                  }
                >
                  {collapsed ? "Quick" : "WayaQuick"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px" }}>
                <Item
                  title="Request Log "
                  to="/request_logs"
                  icon={<BookIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="KYC Management"
                  to="/kyc_management"
                  icon={<ManageAccountsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Settlement History "
                  to="/settlements"
                  icon={<AutoAwesomeMosaicIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Transaction History "
                  to="/transaction_history_wayaquick"
                  icon={<ReceiptLongIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "WayaGram"}
              onChange={() => handleChange("WayaGram")}
              sx={{
                backgroundColor: colors.primary[400],
                border: "none",
                boxShadow: "none",
              }}
            >
              <AccordionSummary expandIcon={collapsed ? "" : <ExpandMore />}>
                <Typography
                  variant="h5"
                  color={
                    [
                      "Request Log",

                      "Manage Group",

                      "Manage Page",

                      "Manage Donation",

                      "Manage Poll",
                    ].includes(selected) &
                    (expanded !== "WayaGram")
                      ? colors.orangeAccent[400]
                      : colors.grey[300]
                  }
                >
                  {collapsed ? "Gram" : "WayaGram"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px" }}>
                <Item
                  title="Request Log"
                  to="/request_log"
                  icon={<BookIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Payment Log"
                  to="/payment_log"
                  icon={<ListAltIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Account"
                  to="/account"
                  icon={<AccountBalanceWalletIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Manage Group"
                  to="/manage_group"
                  icon={<GroupIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Manage Page"
                  to="/manage_page"
                  icon={<TagIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Manage Donation"
                  to="/manage_donation"
                  icon={<VolunteerActivismIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Manage Poll"
                  to="/manage_poll"
                  icon={<HowToVoteIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Manage Post"
                  to="/manage_post"
                  icon={<PostAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Manage Event"
                  to="/manage_event"
                  icon={<CalendarMonthIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </AccordionDetails>
            </Accordion>
            {/* 
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 20px 5px 20px" }}
            >
              Others
            </Typography>
            <Item
              title="Dispute"
              to="/dispute"
              icon={<ManageAccountsIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
