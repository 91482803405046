import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { tokens } from "../../theme";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import FileUploadInput from "../fileUploadInput";
import { useState } from "react";
import { useEffect } from "react";
import SearchableSelect from "../SearchableSelect";

const useStyles = makeStyles({
  textArea: {
    gridColumn: "span 2",
  },
});

const GroupModal = ({ modal, setModal, terminalTypes }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { success, snackError, cookies } = useAuth();
  const { id } = cookies?.auth?.user || {};
  const axiosPrivate = useAxiosPrivate();
  const [groupCategory, setGroupCategory] = useState();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    // console.log(values);
    const {
      category,
      description,
      groupType,
      groupMute,
      title,
      username: { id: userid },
      profilePicture,
      headerImage,
    } = values;

    try {
      const response = await axiosPrivate.postForm(
        "https://services.staging.wayagram.ng/wayagram-service/main/groups/admin/create",
        {
          userId: userid,
          category,
          description,
          name: title,
          isPublic: groupType == "Public",
          mute: Boolean(groupMute),
        },
        {
          params: {
            sso_id: id,
          },
        }
      );
      success(response?.data?.message);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    handleClose();
  };

  const initialValues = {
    profilePicture: "",
    headerImage: "",
    category: "",
    username: null,
    title: "",
    description: "",
    mute: "",
    groupType: "",
  };

  const checkoutSchema = yup.object().shape({
    profilePicture: yup.mixed().required("Required"),
    headerImage: yup.mixed().required("Required"),
    category: yup.string().required("Required"),
    username: yup.object().required("Required"),
    title: yup.string().required("Required"),
    description: yup.string().required("Required"),
    mute: yup.string().required("Required"),
    groupType: yup.string().required("Required"),
  });

  useEffect(() => {
    const getGroupCategory = async () => {
      try {
        const response = await axiosPrivate.get(
          "https://services.staging.wayagram.ng/wayagram-service/main/page/get-all-page-categories",
          {
            params: {
              page: 1,
              limit: 100,
              sso_id: id,
            },
          }
        );
        setGroupCategory(response?.data?.interests?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getGroupCategory();
  }, []);

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  const groupMute = [
    {
      name: "TRUE",
    },
    {
      name: "FALSE",
    },
  ];

  const groupType = [
    {
      name: "Public",
    },
    {
      name: "Private",
    },
  ];

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "30px" }}
                >
                  Create New Group
                </Typography>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                    marginBottom: "10px",
                  }}
                >
                  <FileUploadInput
                    name="profilePicture"
                    label="Profile Picture"
                    sx={{ gridColumn: "span 2" }}
                    error={!!touched.profilePicture && !!errors.profilePicture}
                    helperText={touched.profilePicture && errors.profilePicture}
                  />
                  <FileUploadInput
                    name="headerImage"
                    label="Header Image"
                    sx={{ gridColumn: "span 2" }}
                    error={!!touched.headerImage && !!errors.headerImage}
                    helperText={touched.headerImage && errors.headerImage}
                  />
                  <TextField
                    label="Group Category"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.category}
                    name="category"
                    error={!!touched.category && !!errors.category}
                    helperText={touched.category && errors.category}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {groupCategory?.map((type, index) => (
                      <MenuItem key={index} value={type.title}>
                        {type.title}
                      </MenuItem>
                    ))}
                  </TextField>
                  <SearchableSelect
                    value={values.username}
                    setValue={setFieldValue}
                    label="Search Creator"
                    name="username"
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Group Title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    name="title"
                    error={!!touched.title && !!errors.title}
                    helperText={touched.title && errors.title}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextareaAutosize
                    className={classes.textArea}
                    minRows={3}
                    variant="filled"
                    type="text"
                    label="Description"
                    placeholder="A brief description of the group..."
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    name="description"
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                  />
                  <TextField
                    label="Mute"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mute}
                    name="mute"
                    error={!!touched.mute && !!errors.mute}
                    helperText={touched.mute && errors.mute}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {groupMute?.map((type, index) => (
                      <MenuItem key={index} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Group Type"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.groupType}
                    name="groupType"
                    error={!!touched.groupType && !!errors.groupType}
                    helperText={touched.groupType && errors.groupType}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {groupType?.map((type, index) => (
                      <MenuItem key={index} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  disabled={isLoading}
                  variant="contained"
                  sx={{ width: "100%", p: 2, mt: 3 }}
                >
                  Add Terminal
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default GroupModal;
