import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { tokens } from "../../theme";

const useStyles = makeStyles({
  textArea: {
    width: "100%",
    marginBottom: 10,
  },
});

const PostModal = ({ modal, setModal, setOptionsModal }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const classes = useStyles();

  const handleFormSubmit = async (values) => {
    console.log(values);
  };

  const initialValues = {
    creator: "",
    voteType: "",
    voteCategory: "",
    description: "",
    voteLimit: "",
    amount: "",
  };

  const checkoutSchema = yup.object().shape({
    creator: yup.string().required("Required"),
    voteType: yup.string().required("Required"),
    voteCategory: yup.string().required("Required"),
    description: yup.string().required("Required"),
    voteLimit: yup.number().required("Required"),
    amount: yup.number().required("Required"),
  });

  const options = [
    {
      name: "FREE",
    },
    {
      name: "PAID",
    },
  ];

  const options2 = [
    {
      name: "TEXT",
    },
    {
      name: "IMAGE",
    },
  ];

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: 450,
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "10px" }}
                >
                  Create New Post
                </Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Creator"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.creator}
                  name="creator"
                  error={!!touched.creator && !!errors.creator}
                  helperText={touched.creator && errors.creator}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Vote Type"
                  fullWidth
                  select
                  name="voteType"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.voteType}
                  sx={{ marginBottom: "10px" }}
                >
                  {options.map((option, index) => (
                    <MenuItem key={index} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Vote Category"
                  fullWidth
                  select
                  name="voteCategory"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.voteCategory}
                  sx={{ marginBottom: "10px" }}
                >
                  {options2.map((option, index) => (
                    <MenuItem key={index} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextareaAutosize
                  className={classes.textArea}
                  minRows={4}
                  variant="filled"
                  type="text"
                  label="Description"
                  placeholder="Description of the vote"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  name="description"
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Vote Limit"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.voteLimit}
                  name="voteLimit"
                  error={!!touched.voteLimit && !!errors.voteLimit}
                  helperText={touched.voteLimit && errors.voteLimit}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Amount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount}
                  name="amount"
                  error={!!touched.amount && !!errors.amount}
                  helperText={touched.amount && errors.amount}
                />
                <Box display="flex">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ width: "100%", p: 2, mt: 2 }}
                  >
                    Create Post
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default PostModal;
