import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MyMenu from "./MyMenu";

const IconMenu = ({ rowId }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="Actions"
        aria-controls={`menu-${rowId}`}
        aria-haspopup="true"
        onClick={handleIconClick}
      >
        <MoreVertIcon />
      </IconButton>
      {/* Render your menu component */}
      <MyMenu
        id={`menu-${rowId}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        rowId={rowId}
      />
    </>
  );
};

export default IconMenu;
