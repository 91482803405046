import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  MenuItem,
  Menu,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { ViewDocumentModal } from "../../components/modals/ViewDocumentModal";
import { KYCStatusModal } from "../../components/modals/KYCStatusModal";
import WarningModal from "../../components/modals/WarningModal";
import RejectRequestModal from "../../components/modals/RejectRequestModal";

const ManageKYC = () => {
  const [details, setDetails] = useState({});
  const [modal, setModal] = useState({ open: false, id: null });
  const [docModal, setDocModal] = useState({ open: false, imgUrl: "" });
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [createUserAnchorEl, setCreateUserAnchorEl] = useState(null);
  const createUserOpen = Boolean(createUserAnchorEl);
  const [warning, setWarning] = useState({
    openConfirm: false,
    url: "",
    user: {},
    action: "",
  });
  const [reject, setReject] = useState({
    open: false,
    url: "",
    user: {},
    action: "",
  });
  const [viewDoc, setViewDoc] = useState({
    open: false,
    imgUrl: "",
  });

  useEffect(() => {}, []);

  const toggleCreateUserMenu = (event) => {
    setCreateUserAnchorEl(event?.currentTarget);
  };

  const handleCreateUserClose = () => {
    setCreateUserAnchorEl(null);
  };

  const handleOption = async (e) => {
    setCreateUserAnchorEl(null);
    let response;
    if (e.target.textContent === "UNDER REVIEW") {
      try {
        response = await axiosPrivate.get(
          "/kyc-servicev2/api/v1/admin/kyc/customerKycData/all",
          {
            params: {
              field: "status",
              pageNo: 1,
              pageSize: 10,
              value: "UNDER_REVIEW",
            },
          }
        );
        setDetails(response?.data?.data?.content);
      } catch (error) {
        console.log(error);
      }
    } else if (e.target.textContent === "PENDING") {
      try {
        response = await axiosPrivate.get(
          "/kyc-servicev2/api/v1/admin/kyc/customerKycData/all",
          {
            params: {
              field: "status",
              pageNo: 1,
              pageSize: 10,
              value: "PENDING",
            },
          }
        );
        setDetails(response?.data?.data?.content);
      } catch (error) {
        console.log(error);
      }
    } else if (e.target.textContent === "REJECTED") {
      try {
        response = await axiosPrivate.get(
          "/kyc-servicev2/api/v1/admin/kyc/customerKycData/all",
          {
            params: {
              field: "status",
              pageNo: 1,
              pageSize: 10,
              value: "REJECTED",
            },
          }
        );
        setDetails(response?.data?.data?.content);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const columns = [
    { field: "id", headerName: "Id", width: 100 },
    {
      field: "createdBy",
      headerName: "Email",
      width: 200,
      renderCell: ({ row: { createdBy } }) => {
        return createdBy ? createdBy : "------";
      },
    },
    {
      field: "kycRequirements",
      headerName: "Tier Level",
      headerAlign: "left",
      align: "left",
      width: 100,
      renderCell: ({ row: { kycTier } }) => {
        return kycTier?.name ? kycTier?.name?.replace("_", " ") : "------";
      },
    },
    {
      field: "dataType",
      headerName: "Data Type",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderCell: ({ row: { status } }) => {
        return status ? status?.replace("_", " ") : "------";
      },
    },
    {
      field: "view",
      headerName: "View Document",
      headerAlign: "left",
      align: "left",
      width: 90,
      renderCell: ({ row }) => {
        return (
          <Button
            onClick={() => setViewDoc({ open: true, imgUrl: row.attachment })}
            disabled={row.status === "DECLINED"}
            underline="none"
            sx={{
              backgroundColor: colors.orangeAccent[700],
              ml: 1,
              color: colors.grey[100],
            }}
          >
            View
          </Button>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: ({ row }) => {
        return (
          <Box>
            <IconButton
              onClick={() =>
                setWarning({
                  openConfirm: true,
                  url: "/kyc-servicev2/api/v1/admin/kyc/approveOrReject/",
                  user: row,
                  action: "accept kyc request",
                })
              }
              sx={{
                color: colors.greenAccent[500],
                fontSize: "14px",
              }}
            >
              <CheckCircleIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                setReject({
                  open: true,
                  url: "/kyc-servicev2/api/v1/admin/kyc/approveOrReject/",
                  user: row,
                  action: "reject kyc request",
                })
              }
              sx={{
                color: colors.redAccent[500],
                fontSize: "14px",
              }}
            >
              <ClearIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <ViewDocumentModal modal={viewDoc} setModal={setViewDoc} />
      <WarningModal
        open={warning.openConfirm}
        url={warning.url}
        user={warning.user}
        setOpen={setWarning}
        action={warning.action}
      />

      <RejectRequestModal
        isOpen={reject.open}
        setIsOpen={setReject}
        url={reject.url}
        user={reject.user}
        action={reject.action}
      />
      {/* HEADER */}
      <ViewDocumentModal modal={docModal} setModal={setDocModal} />
      <KYCStatusModal
        modal={modal}
        setModal={setModal}
        setDocModal={setDocModal}
      />
      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title="KYC Management" subtitle="Manage WayaBank KYC" />

        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Button
              onClick={(e) => toggleCreateUserMenu(e)}
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 38px",
                marginLeft: "15px",
              }}
            >
              Filter
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={createUserAnchorEl}
              open={createUserOpen}
              onClose={handleCreateUserClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              //   classes={{ paper: "dropdown-menu2" }}
            >
              <MenuItem onClick={(e) => handleOption(e)}>UNDER REVIEW</MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>PENDING</MenuItem>
              <MenuItem onClick={(e) => handleOption(e)}>REJECTED</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Box
          m="10px"
          width="100%"
          height="70vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={details ? details : []}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default ManageKYC;
