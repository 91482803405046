import React, { useState } from "react";
import { Modal, Box, Tabs, Tab, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ModalTab from "./ModalTab";

const ModalWithTabs = ({
  isOpen,
  onClose,
  setWalletModal,
  setAccountModal,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleWalletClick = async (event) => {
    setWalletModal({ open: true });
  };
  const handleAccountClick = (event) => {
    setAccountModal({ open: true });
  };
  const handleCardClick = (event) => {
    console.log("Card clicked");
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          width: 800,
          backgroundColor: colors.primary[400],
          p: 3,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          centered
        >
          <Tab label="ACCOUNTS" />
          <Tab label="BANKS" />
          <Tab label="BANK CARDS" />
        </Tabs>
        <Box
          sx={{
            p: 2,
          }}
        >
          {currentTab === 0 && (
            <ModalTab handleClick={handleWalletClick} title="Accounts" />
          )}
          {currentTab === 1 && (
            <ModalTab handleClick={handleAccountClick} title="Banks" />
          )}
          {currentTab === 2 && (
            <ModalTab handleClick={handleCardClick} title="Cards" />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalWithTabs;
