import { Formik, Form, Field } from "formik";
import {
  Button,
  Modal,
  Typography,
  TextField,
  MenuItem,
  Box,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import * as yup from "yup";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

const useStyles = makeStyles({
  inputFile: {
    "& input[type=file]": {
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    "& input[type=file]::-webkit-file-upload-button": {
      display: "none",
    },
  },
});

const UploadKYCDocumentModal = ({ modal, setModal }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const { success, snackError, queryUserDetails } = useAuth();
  const { userId = "" } = queryUserDetails?.user[0] || {};
  const classes = useStyles();
  const { name: fileType, id } = modal?.row || {};
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const { reference, file } = values;
    try {
      const response = await axiosPrivate.putForm(
        `/kyc-servicev2/api/v1/admin/kyc/updateCustomerTierData/${id}/` + userId,
        {
          file: file,
          fileType: fileType,
          reference: reference,
          status: "UNDER_REVIEW",
          isBvn: false,
          userId,
        }
      );
      console.log(response);
      success(response?.data?.message);
    } catch (error) {
      console.log(error);
      if (error.response) {
        snackError(error?.response?.data?.message);
      }
    }
    setIsLoading(false);
    handleClose();
  };

  const handleFileChange = (event, field, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setFieldValue(field.name, file);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleClose = () => {
    setModal({ open: false });
  };

  const initialValues = {
    file: "",
    selectOption: "NATIONAL ID",
    reference: "",
  };

  const options = [
    {
      name: "NATIONAL ID",
    },
    {
      name: "INTERNATIONAL PASSPORT",
    },
    {
      name: "VOTERS CARD",
    },
    {
      name: "DRIVER'S LICENSE",
    },
  ];

  const checkoutSchema = yup.object().shape({
    file: yup.mixed().required("Required"),
    selectOption: yup.string(),
    reference:
      fileType !== "PHOTO" && fileType !== "UTILITY"
        ? yup.string().required("Required")
        : yup.string(),
  });

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: "40%",
          //   height: "40%",
          backgroundColor: colors.primary[400],
          p: 3,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography textAlign="center" variant="h5" mb={3}>
          Upload KYC Document
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={checkoutSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            handleBlur,
            handleChange,
            setFieldValue,
            handleSubmit,
            touched,
            errors,
          }) => (
            <form onSubmit={handleSubmit}>
              {fileType == "ID" && (
                <TextField
                  label="Select Document Type"
                  fullWidth
                  select
                  name="selectOption"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.selectOption}
                  sx={{ gridColumn: "span 2" }}
                >
                  {options.map((option, index) => (
                    <MenuItem key={index} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {fileType !== "PHOTO" && fileType !== "UTILITY" && (
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Document Reference"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.reference}
                  name="reference"
                  error={!!touched.reference && !!errors.reference}
                  helperText={touched.reference && errors.reference}
                  sx={{ gridColumn: "span 16", mt: 3 }}
                />
              )}
              <Field label="Upload JPG file" name="file">
                {({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="file"
                    variant="outlined"
                    value={undefined}
                    inputProps={{
                      accept: "image/jpeg, image/png",
                    }}
                    className={classes.inputFile}
                    onChange={(e) => handleFileChange(e, field, setFieldValue)}
                    sx={{ gridColumn: "span 4", mt: 3 }}
                  />
                )}
              </Field>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                disabled={isLoading}
                color="secondary"
                sx={{ p: 2, mt: 3 }}
              >
                Submit
              </Button>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default UploadKYCDocumentModal;
