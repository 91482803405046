import { Modal, Box, Card, Typography, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { makeStyles } from "@mui/styles";
import TransactionCardList from "../cardComponents/transCardList";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 25,
  },
  card: {
    width: "100%",
    height: "100%",
    borderRadius: 8,
  },
  content: {
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
}));

export function ViewTransactionModal({ modal, setModal }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const classes = useStyles();

  const handleClose = () => {
    setModal({ open: false });
  };

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          // height: "75%",
          backgroundColor: colors.primary[400],
          p: 5,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
          borderRadius: "5px",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
        >
          <Typography variant="h3" sx={{ mb: 2 }}>
            Transaction Details
          </Typography>
          <TransactionCardList
            title="Transaction Amount"
            value={modal.data?.tranAmount}
          />
          <TransactionCardList
            title="Transaction Date"
            value={modal.data?.tranDate}
          />
          <TransactionCardList
            title="Reference Number"
            value={modal.data?.paymentReference}
          />
          <TransactionCardList
            title="Transaction Type"
            value={modal.data?.partTranType == "C" ? "Credit" : "Debit"}
          />
          <TransactionCardList
            title="Transaction Category"
            value={modal.data?.tranCategory}
          />
          <TransactionCardList
            title="Sender Name"
            value={modal.data?.senderName}
          />
          <TransactionCardList
            title="Sender Account"
            value={modal.data?.acctNum}
          />
          <TransactionCardList
            title="Reciever Name"
            value={modal.data?.recieverName}
          />
          <TransactionCardList
            title="Reciever Account"
            value={modal.data?.recieverAccount}
          />
          <TransactionCardList
            title="Reciever Bank"
            value={modal.data?.recieverBank}
          />
          <TransactionCardList
            title="Narration"
            value={modal.data?.tranNarrate}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" mx={3.5}>
          <Button
            // onClick={() => setViewTransModal({ open: true, data: row })}
            underline="none"
            sx={{
              backgroundColor: colors.orangeAccent[700],
              color: colors.grey[100],
            }}
          >
            Print
          </Button>

          <Box display="flex" height={5}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
              NOTE:
            </Typography>
            <Typography
              variant="h6"
              sx={{ ml: 1, textAlign: "end", color: colors.grey[600] }}
            >
              You'll be able to dispute this after <br />
              24 hours of transaction time
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
