import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import SearchTable from "../../components/SearchTable";
import RejectRequestModal from "../../components/modals/RejectRequestModal";
import WarningModal from "../../components/modals/WarningModal";
import { ViewDisburseModal } from "../../components/modals/ViewDisburseModal";

const WayaGramAccount = () => {
  const [details, setDetails] = useState();
  const [newDetails, setNewDetails] = useState([]);
  const [disburseModal, setDisburseModal] = useState({ open: false });
  const [rejectModal, setRejectModal] = useState({
    open: false,
    url: "",
    user: {},
  });
  const [modal, setModal] = useState({
    openConfirm: false,
    url: "",
    action: "",
  });
  const { success, snackError, cookies } = useAuth();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const getAccount = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayagram.ng/wayagram-service/all-posts/admin-get-requests",
        {
          params: {
            page: 1,
            limit: 20,
            sso_id: cookies.auth.user.id,
          },
        }
      );
      setDetails(response?.data?.data?.data);
    } catch (error) {
      if (error.response.status === 403) {
        snackError("Sorry, Not Authorized");
      } else {
        console.log(error);
      }
    }
  };

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.filter(
        (log) =>
          log?.RecipientProfile?.username?.toLowerCase() ===
            searchKey?.toLowerCase() ||
          log?.RecipientProfile?.phone?.toLowerCase() ===
            searchKey?.toLowerCase() ||
          log?.RecipientProfile?.email?.toLowerCase() ===
            searchKey?.toLowerCase()
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAccount();
  }, []);

  const columns = [
    {
      field: "username",
      headerName: "Username",
      width: 100,
      renderCell: ({
        row: {
          RecipientProfile: { username },
        },
      }) => username,
    },
    {
      field: "type",
      headerName: "Type",
      width: 80,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 80,
    },
    {
      field: "wayagramPercent",
      headerName: "Waya Percent",
      width: 80,
    },
    {
      field: "paymentBalance",
      headerName: "Balance",
      width: 80,
    },
    {
      field: "paymentStatus",
      headerName: "Status",
      width: 80,
      renderCell: ({ row: { paymentStatus } }) => (
        <Typography
          variant="h6"
          sx={{
            color:
              paymentStatus == "DISBURSED"
                ? colors.greenAccent[400]
                : colors.redAccent[400],
          }}
        >
          {paymentStatus}
        </Typography>
      ),
    },
    {
      field: "approvedBy",
      headerName: "Approved By",
      headerAlign: "left",
      align: "left",
      width: 100,
      renderCell: ({
        row: {
          ApprovalProfile: { displayName },
        },
      }) => (displayName ? displayName : "------------"),
    },
    {
      field: "disburseBy",
      headerName: "Disbursed By",
      headerAlign: "left",
      align: "left",
      width: 100,
      renderCell: ({
        row: {
          DisburserProfile: { displayName },
        },
      }) => (displayName ? displayName : "------------"),
    },
    {
      field: "rejectedBy",
      headerName: "Rejected By",
      headerAlign: "left",
      align: "left",
      width: 100,
      renderCell: ({
        row: {
          RejecterProfile: { displayName },
        },
      }) => (displayName ? displayName : "------------"),
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 100,
      renderCell: ({ row: { createdAt } }) => createdAt.split("T")[0],
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: ({ row }) => {
        return row.paymentStatus !== "DISBURSED" ? (
          <Box>
            <Button
              onClick={() =>
                setModal({
                  openConfirm: true,
                  user: row,
                  action: "disburse",
                  url: "https://services.staging.wayagram.ng/wayagram-service/all-posts/admin-disburse-money",
                })
              }
              underline="none"
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
              }}
            >
              Disburse
            </Button>
            <Button
              onClick={() =>
                setRejectModal({
                  open: true,
                  user: row,
                  action: "reject disbursement",
                  url: "https://services.staging.wayagram.ng/wayagram-service/all-posts/admin-reject-payment-request",
                })
              }
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                ml: 1,
                color: colors.grey[100],
              }}
            >
              Reject
            </Button>
          </Box>
        ) : (
          <Button
            onClick={() =>
              setDisburseModal({
                open: true,
                data: row,
              })
            }
            underline="none"
            sx={{
              backgroundColor: colors.orangeAccent[700],
              ml: 1,
              color: colors.grey[100],
            }}
          >
            View Reciept
          </Button>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <ViewDisburseModal modal={disburseModal} setModal={setDisburseModal} />

      <RejectRequestModal
        isOpen={rejectModal.open}
        setIsOpen={setRejectModal}
        url={rejectModal.url}
        user={rejectModal.user}
        action={rejectModal.action}
      />

      <WarningModal
        open={modal.openConfirm}
        action={modal.action}
        url={modal.url}
        user={modal.user}
        setOpen={setModal}
      />

      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title="ACCOUNTS" subtitle="Manage Wayagram Users accounts" />

        <Box display={"flex"} justifyContent={"space-between"}>
          <SearchTable
            handleSearch={handleSearch}
            placeholder="username/email/phone"
          />
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Box
          m="10px"
          width="100%"
          height="70vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={newDetails ? newDetails : []}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default WayaGramAccount;
