import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import FileUploadInput from "../fileUploadInput";
import { useState } from "react";
import SearchableSelect from "../SearchableSelect";
import DateRangePicker from "../DateRangePicker";
import { format } from "date-fns";
import naijaStateLocalGovernment from "naija-state-local-government";

const EventModal = ({ modal, setModal }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { success, snackError, cookies } = useAuth();
  const { id } = cookies?.auth?.user || {};
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleFormSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    // console.log(values);
    const {
      organizer: { id: userid },
      eventPoster,
      location,
      eventName,
      details,
      isPaid,
      isPrivate,
      virtual,
      eventUrl,
      amount,
    } = values;

    try {
      const response = await axiosPrivate.postForm(
        "https://services.staging.wayagram.ng/wayagram-service/all-posts/admin-create-event",
        {
          eventPoster,
          profile_id: userid,
          location,
          eventName,
          details,
          eventStart: format(range[0].startDate, "dd-MM-yyyy"),
          eventEnd: format(range[0].endDate, "dd-MM-yyyy"),
          isPaid: isPaid == "True",
          isPrivate: isPrivate == "True",
          virtual: virtual == "True",
          eventUrl,
          amount,
        }
      );
      console.log(response?.data?.data);
      success(response?.data?.message);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    resetForm();
    handleClose();
  };

  const initialValues = {
    eventPoster: "",
    organizer: null,
    location: "",
    eventName: "",
    details: "",
    isPaid: "",
    isPrivate: "",
    virtual: "",
    eventUrl: "",
    amount: "",
  };

  const checkoutSchema = yup.object().shape({
    eventPoster: yup.mixed().required("Required"),
    organizer: yup.object().required("Required"),
    location: yup.string().required("Required"),
    eventName: yup.string().required("Required"),
    details: yup.string().required("Required"),
    isPaid: yup.string().required("Required"),
    isPrivate: yup.string().required("Required"),
    virtual: yup.string().required("Required"),
    eventUrl: yup.string().required("Required"),
    amount: yup.number().required("Required"),
  });

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  const boolOption = [
    {
      name: "True",
    },
    {
      name: "False",
    },
  ];

  return (
    <Modal open={modal?.open} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "30px" }}
                >
                  Create New Event
                </Typography>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                    marginBottom: "10px",
                  }}
                >
                  <FileUploadInput
                    name="eventPoster"
                    label="Event Poster"
                    sx={{ gridColumn: "span 2" }}
                    error={!!touched.eventPoster && !!errors.eventPoster}
                    helperText={touched.eventPoster && errors.eventPoster}
                  />
                  <SearchableSelect
                    value={values.organizer}
                    setValue={setFieldValue}
                    label="Event Organizer"
                    name="organizer"
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    label="Location"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.location}
                    name="location"
                    error={!!touched.location && !!errors.location}
                    helperText={touched.location && errors.location}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {naijaStateLocalGovernment.states().map((state, index) => (
                      <MenuItem key={index} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Event Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.eventName}
                    name="eventName"
                    error={!!touched.eventName && !!errors.eventName}
                    helperText={touched.eventName && errors.eventName}
                    sx={{ gridColumn: "span 2" }}
                  />

                  <TextField
                    variant="filled"
                    type="text"
                    label="Description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.details}
                    name="details"
                    error={!!touched.details && !!errors.details}
                    helperText={touched.details && errors.details}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <DateRangePicker
                    range={range}
                    setRange={setRange}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    label="Paid"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.isPaid}
                    name="isPaid"
                    error={!!touched.isPaid && !!errors.isPaid}
                    helperText={touched.isPaid && errors.isPaid}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {boolOption?.map((type, index) => (
                      <MenuItem key={index} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Private"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.isPrivate}
                    name="isPrivate"
                    error={!!touched.isPrivate && !!errors.isPrivate}
                    helperText={touched.isPrivate && errors.isPrivate}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {boolOption?.map((type, index) => (
                      <MenuItem key={index} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Virtual"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.virtual}
                    name="virtual"
                    error={!!touched.virtual && !!errors.virtual}
                    helperText={touched.virtual && errors.virtual}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {boolOption?.map((type, index) => (
                      <MenuItem key={index} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Event Url"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.eventUrl}
                    name="eventUrl"
                    error={!!touched.eventUrl && !!errors.eventUrl}
                    helperText={touched.eventUrl && errors.eventUrl}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.amount}
                    name="amount"
                    error={!!touched.amount && !!errors.amount}
                    helperText={touched.amount && errors.amount}
                    sx={{ gridColumn: "span 2" }}
                  />
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  disabled={isLoading}
                  variant="contained"
                  sx={{ width: "100%", p: 2, mt: 3 }}
                >
                  Add Event
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default EventModal;
