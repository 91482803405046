import { Box, CardContent, Typography } from "@mui/material";
import React from "react";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 25,
  },
  card: {
    width: "48%",
    borderRadius: 8,
  },
  content: {
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
}));

const CardList = ({ title, value }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const classes = useStyles();

  return (
    <CardContent className={classes.content}>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>

        <Typography variant="h4" className={classes.title}>
          {value}
        </Typography>
      </Box>
      <hr />
    </CardContent>
  );
};

export default CardList;
