import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import SearchTable from "../../components/SearchTable";
import SettlementFormModal from "../../components/modals/SettlementFormModal";

const WayaPosSettlements = () => {
  const [details, setDetails] = useState({});
  const [newDetails, setNewDetails] = useState([]);

  const [modal, setModal] = useState({
    open: false,
    title: "",
  });
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const getTransactions = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayapos.ng/wayapos-settlement-service/api/v1/settlement/adminviewall",
        {
          params: {
            pageNo: 1,
            pageSize: 20,
          },
        }
      );
      setNewDetails(response?.data?.respBody?.content);
      return response?.data?.respBody?.content;
    } catch (error) {
      console.log(error);
    }
  };

  const getTransactionStats = async () => {
    try {
      const response = await axiosPrivate.post(
        "https://services.staging.wayapos.ng/wayapos-settlement-service/api/v1/settlement/stats/admin"
      );
      return response?.data?.respBody;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.transactions?.filter(
        (transaction) =>
          transaction.referenceID === searchKey ||
          transaction.beneficiary === searchKey
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getDetails = async () => {
      const [transactions, transactionStats] = await Promise.all([
        getTransactions(),
        getTransactionStats(),
      ]);
      setDetails({ transactions, transactionStats });
    };
    getDetails();
  }, []);

  const columns = [
    { field: "referenceID", headerName: "Reference Id", width: 100 },
    {
      field: "businessName",
      headerName: "Business Name",
      width: 200,
    },
    {
      field: "beneficiary",
      headerName: "Beneficiary",
      width: 200,
    },
    {
      field: "actualAmount",
      headerName: "Actual Amount",
      cellClassName: "name-column--cell",
      width: 100,
    },
    {
      field: "settledAmount",
      headerName: "Settled Amount",
      cellClassName: "name-column--cell",
      width: 100,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      cellClassName: "name-column--cell",
      width: 100,
      renderCell: ({ row: { status } }) => {
        return (
          <Typography
            color={
              status == "SETTLED"
                ? colors.greenAccent[400]
                : colors.redAccent[400]
            }
          >
            {status}
          </Typography>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <SettlementFormModal
        isOpen={modal.open}
        title={modal.title}
        setModal={setModal}
      />

      {/* GRID & CHARTS */}
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ justifyContent: "space-between" }}
      >
        <Grid xs={12} sm={12} md={6} lg={3.6} xl={3}>
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
          >
            <Header
              title="SETTLEMENT HISTORY"
              subtitle="Manage WayaPos Settlement History"
            />
          </Box>
        </Grid>
        <Box
          display="flex"
          justifyContent="end"
          height="55px"
          paddingRight={1.2}
          paddingTop={1}
        >
          <SearchTable
            handleSearch={handleSearch}
            placeholder="Reference Id/Beneficiary"
          />
          <Button
            underline="none"
            onClick={() => setModal({ open: true, title: "Manual Settlement" })}
            sx={{
              backgroundColor: colors.orangeAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "15px",
            }}
          >
            Manual Settlement
          </Button>
        </Box>
        <Box
          m="10px"
          width="100%"
          height="65vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row?.id;
            }}
            rows={newDetails}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default WayaPosSettlements;
