import useAxiosPrivate from "./useAxiosPrivate";
import { useEffect } from "react";
import { useState } from "react";

const useQRCodeDetails = () => {
  const axiosPrivate = useAxiosPrivate();
  const [details, setDetails] = useState([]);

  const getQRCodes = async () => {
    try {
      const response = await axiosPrivate.get(
        "/qrcode-service/api/v1/qrcode/find/all",
        {
          params: {
            page: 0,
            size: 20,
          },
        }
      );
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalExported = async () => {
    try {
      const response = await axiosPrivate.get(
        "/qrcode-service/api/v1/qrcode/get-total-exported"
      );
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalGenerated = async () => {
    try {
      const response = await axiosPrivate.get(
        "/qrcode-service/api/v1/qrcode/get-total-count"
      );
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalAssigned = async () => {
    try {
      const response = await axiosPrivate.get(
        "/qrcode-service/api/v1/qrcode/get-assigned-count"
      );
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalUnassigned = async () => {
    try {
      const response = await axiosPrivate.get(
        "/qrcode-service/api/v1/qrcode/get-unassigned-count"
      );
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const QRDetails = async () => {
      const [
        QrCode,
        TotalExported,
        TotalGenerated,
        TotalAssigned,
        TotalUnassigned,
      ] = await Promise.all([
        getQRCodes(),
        getTotalExported(),
        getTotalGenerated(),
        getTotalAssigned(),
        getTotalUnassigned(),
      ]);

      setDetails({
        QrCode,
        TotalExported,
        TotalGenerated,
        TotalAssigned,
        TotalUnassigned,
      });
    };
    QRDetails();
  }, []);

  return details;
};

export default useQRCodeDetails;
