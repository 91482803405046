import { Formik, Form, Field } from "formik";
import {
  Button,
  Modal,
  Typography,
  TextField,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { generateUrlId } from "../../utils/generateUrlID";
import useAuth from "../../hooks/useAuth";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

const initialValues = {
  file: "",
  selectOption: "",
};

const useStyles = makeStyles({
  inputFile: {
    "& input[type=file]": {
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    "& input[type=file]::-webkit-file-upload-button": {
      display: "none",
    },
  },
});

const UploadSpreadSheetModal = ({ open, setModal, title }) => {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const { success } = useAuth();

  const handleFormSubmit = async (values) => {
    console.log(values);
    try {
      const response = await axiosPrivate.postForm(
        `/logs-service/api/v1/requestlog/create/bulk/${generateUrlId()}/%20Bulk%20Users/BulkExcelUsers`,
        {
          file: values.file,
          isCorporate: values.selectOption == "Corporate",
        }
      );
      success(response?.data);
    } catch (error) {
      console.log(error);
    }
    handleClose();
  };

  const handleFileChange = (event, field, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setFieldValue(field.name, file);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleClose = () => {
    setModal({ open: false });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "40%",
          //   height: "40%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
          {(formikProps) => (
            <Form>
              <Field name="selectOption">
                {({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label="Select Option"
                    variant="outlined"
                    margin="normal"
                  >
                    <MenuItem value="Individual">INDIVIDUAL</MenuItem>
                    <MenuItem value="Corporate">CORPERATE</MenuItem>
                  </TextField>
                )}
              </Field>
              <Field label="Upload JPG file" name="file">
                {({ field, form }) => {
                  const { setFieldValue } = form;
                  return (
                    <TextField
                      {...field}
                      fullWidth
                      type="file"
                      variant="outlined"
                      value={undefined}
                      onChange={(e) =>
                        handleFileChange(e, field, setFieldValue)
                      }
                      className={classes.inputFile}
                      sx={{ gridColumn: "span 4" }}
                    />
                  );
                }}
              </Field>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ p: 2, mt: 2 }}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default UploadSpreadSheetModal;
