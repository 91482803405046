import React, { useEffect } from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import SearchableSelectTransfer from "../SearchableSelectTransfer";
import { generateReqId } from "../../utils/generateReqId";

const CreateAccountModal = ({ isOpen, setModal }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { success, snackError } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { queryUserDetails } = useAuth();
  const { user } = queryUserDetails;
  const [options, setOptions] = useState([]);
  const [values, setFormValue] = useState({
    selectBank: "",
    accountName: "",
    accountNumber: "",
    bvn: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { accountName, accountNumber, selectBank } = values;

    try {
      const response = await axiosPrivate.post(
        "/logs-service/api/v1/requestlog/create",
        {
          customerName: accountName,
          payload: JSON.stringify({
            accountName,
            accountNumber,
            bankCode: selectBank.bankCode,
            bankName: selectBank.bankName,
            userId: user[0].userId,
          }),
          requestId: generateReqId(),
          requestType: "ADDBANKACCOUNT",
        }
      );
      success(response?.data);
    } catch (error) {
      if (error.response.status === 403) {
        snackError("Not Authorized");
      }
      console.log(error);
    }

    handleClose();
  };

  const handleChange = async (e) => {
    const { value, name } = e.target;
    setFormValue((prev) => ({ ...prev, [name]: value }));

    if (name === "accountNumber" && value.length === 10) {
      setIsLoading(true);
      try {
        const response = await axiosPrivate.post(
          "/card-service/bankAccount/resolveAccountNumber/WD",
          {
            accountNumber: value,
            bankCode: values?.selectBank?.bankCode,
          }
        );
        setFormValue((prev) => ({
          ...prev,
          accountName: response?.data?.data,
        }));
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getBanks = async () => {
      try {
        const response = await axiosPrivate.get(
          "/card-service/bankAccount/getBanks/BK"
        );
        setOptions(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    setFormValue({
      selectBank: "",
      accountName: "",
      accountNumber: "",
      bvn: "",
    });
    getBanks();
  }, []);

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: "40%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <form onSubmit={handleFormSubmit}>
          <Box padding={5}>
            <Typography
              variant="h3"
              sx={{ textAlign: "center", marginBottom: "30px" }}
            >
              Add New Bank Account
            </Typography>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
                marginBottom: "10px",
              }}
            >
              <TextField
                label="Select Bank"
                fullWidth
                select
                name="selectBank"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.selectBank}
                sx={{ gridColumn: "span 4" }}
              >
                {options?.map((bank, index) => (
                  <MenuItem key={index} value={bank}>
                    {bank.bankName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="filled"
                type="number"
                disabled={isLoading}
                label="Account Number"
                onChange={handleChange}
                value={values.accountNumber}
                name="accountNumber"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                disabled
                label="Account Name"
                onChange={handleChange}
                value={values.accountName}
                name="accountName"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="BVN"
                onChange={handleChange}
                value={values.bvn}
                name="bvn"
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ width: "100%", p: 2, mt: 2 }}
            >
              Create Account
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateAccountModal;
