export function convertToWords(amount) {
  const units = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const scales = [
    "",
    "Thousand",
    "Million",
    "Billion",
    "Trillion",
    "Quadrillion",
    "Quintillion",
  ];

  if (amount == 0 || amount == undefined || amount == null) {
    return "Zero Naira";
  }
  try {
    let words = "";

    const amountStr = String(amount);
    const groups = amountStr
      .padStart(Math.ceil(amountStr.length / 3) * 3, "0")
      .match(/(\d{3})/g);

    for (let i = 0; i < groups.length; i++) {
      let group = parseInt(groups[i], 10);
      const groupWords = [];

      if (group >= 100) {
        groupWords.push(units[Math.floor(group / 100)] + " Hundred");
        group %= 100;
      }

      if (group >= 20) {
        groupWords.push(tens[Math.floor(group / 10)]);
        group %= 10;
      }

      if (group > 0) {
        groupWords.push(units[group]);
      }

      if (groupWords.length > 0) {
        words +=
          groupWords.join(" ") + " " + scales[groups.length - i - 1] + " ";
      }
    }

    return words.trim() + " Naira";
  } catch (error) {
    console.log(error);
  }
}
