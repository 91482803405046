import React, { useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { all } from "axios";

const MerchantSearchableSelector = ({ value, handleChange, sx }) => {
  const [searchKey, setSearchKey] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const getMerchants = async () => {
      try {
        const response = await axiosPrivate.get(
          "https://services.staging.wayapos.ng/wayapos-role-service/api/v1/fetchAllMerchantsAdmin",
          {
            params: {
              field: "all",
              pageNo: 1,
              pageSize: 5,
              value: searchKey,
            },
          }
        );
        setOptions(response?.data?.data ? response?.data?.data?.content : []);
      } catch (error) {
        console.log(error);
      }
    };

    getMerchants();
  }, [searchKey]);

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      options={options}
      getOptionLabel={({ firstName, surname, email, merchantId }) =>
        firstName + " " + surname + " - " + email + " - " + merchantId
      }
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            onChange={(e) => setSearchKey(e.target.value)}
            label="Search User"
            variant="outlined"
          />
        );
      }}
      sx={sx}
    />
  );
};

export default MerchantSearchableSelector;
