import React, { useEffect } from "react";
import Header from "../../components/Header";
import { Box, IconButton, Tab, Tabs } from "@mui/material";
import BackButton from "../../components/BackButton";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import Tier1Table from "../../components/tiersTable/Tier1Table";
import Tier2Table from "../../components/tiersTable/Tier2Table";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import Tier3Table from "../../components/tiersTable/Tier3Table";
import Tier4Table from "../../components/tiersTable/Tier4Table";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const UserKYC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = useState(0);
  const [tierDetails, setTierDetails] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { queryUserDetails } = useAuth();
  const { userId = "" } = queryUserDetails?.user[0] || {};

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNext = () => {
    setValue((prevValue) => (prevValue + 1) % 4);
  };

  const handlePrev = () => {
    setValue((prevValue) => (prevValue + 3) % 4);
  };

  useEffect(() => {
    async function getKycRequirementsData() {
      try {
        const response = await axiosPrivate.post(
          "/kyc-servicev2/api/v1/admin/kyc/requirementsData/" + userId
        );
        setTierDetails(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }

    getKycRequirementsData();
  }, []);

  return (
    <Box m="20px">
      <BackButton path="/" />
      <Header title="Update User KYC" subtitle="Manage User KYC requirements" />
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton onClick={handlePrev}>
              <ArrowBackIos />
            </IconButton>
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{
                bgcolor: colors.primary[400],
              }}
              aria-label="My Tabs"
            >
              <Tab
                sx={{
                  borderRadius: "5px",
                  bgcolor: colors.primary[400],
                  "&.Mui-selected": {
                    color: colors.grey[100],
                    bgcolor: colors.orangeAccent[700],
                  },
                }}
                label="Tier 1"
                id="tier-0"
                aria-controls="tabpanel-0"
              />
              <Tab
                sx={{
                  borderRadius: "5px",
                  bgcolor: colors.primary[400],
                  "&.Mui-selected": {
                    color: colors.grey[100],
                    bgcolor: colors.orangeAccent[700],
                  },
                }}
                label="Tier 2"
                id="tier-1"
                aria-controls="tabpanel-1"
              />
              <Tab
                sx={{
                  borderRadius: "5px",
                  bgcolor: colors.primary[400],
                  "&.Mui-selected": {
                    color: colors.grey[100],
                    bgcolor: colors.orangeAccent[700],
                  },
                }}
                label="Tier 3"
                id="tier-2"
                aria-controls="tabpanel-2"
              />
              <Tab
                sx={{
                  borderRadius: "5px",
                  bgcolor: colors.primary[400],
                  "&.Mui-selected": {
                    color: colors.grey[100],
                    bgcolor: colors.orangeAccent[700],
                  },
                }}
                label="Tier 4"
                id="tier-3"
                aria-controls="tabpanel-3"
              />
            </Tabs>
            <IconButton onClick={handleNext}>
              <ArrowForwardIos />
            </IconButton>
          </Box>
          <Box
            p={3}
            m={2}
            borderRadius={2}
            bgcolor={colors.primary[400]}
            sx={{ width: "90%" }}
          >
            <TabPanel value={value} index={0}>
              <Tier1Table />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Tier2Table tier2Details={tierDetails?.tier2} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Tier3Table tier3Details={tierDetails?.tier3} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Tier4Table tier4Details={tierDetails?.tier4} />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserKYC;
