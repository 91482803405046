import { Button, Typography } from "@mui/material";
import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { useProSidebar } from "react-pro-sidebar";
import useAuth from "../hooks/useAuth";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";

const BackButton = ({ path }) => {
  const { cookies } = useAuth();
  const { collapsed } = useProSidebar();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    auth: { user },
  } = cookies;
  const navigate = useNavigate();
  return (
    <Button
      color="secondary"
      variant="contained"
      onClick={() => navigate(path, { replace: true })}
      sx={{
        backgroundColor: colors.orangeAccent[700],
        color: colors.grey[100],
        position: "absolute",
        top: 14,
        left: !user ? 10 : collapsed ? 100 : 270,
        py: 1.2,
        px: 2,
      }}
    >
      <ArrowBackIosNewIcon />
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        -----
      </Typography>
    </Button>
  );
};

export default BackButton;
