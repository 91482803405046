import { Modal, Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

export function ViewDocumentModal({ modal, setModal, title }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClose = () => {
    setModal((prevValue) => ({ ...prevValue, open: false }));
  };
  return (
    <Modal open={modal?.open} onClose={handleClose}>
      <Box
        sx={{
          width: "30%",
          height: "60%",
          backgroundColor: colors.primary[400],
          p: 3,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
          borderRadius: "5px",
        }}
      >
        {title && (
          <Typography variant="h3" my={3} textAlign="center">
            {title}
          </Typography>
        )}
        {modal?.imgUrl ? (
          <img src={modal?.imgUrl} height="100%" width="100%" />
        ) : (
          <Typography variant="h5" textAlign="center" my={3} mt={30}>
            No document found
          </Typography>
        )}
      </Box>
    </Modal>
  );
}
