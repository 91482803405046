import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SearchTable from "../../components/SearchTable";
import OTPRecieverModal from "../../components/modals/OTPRecieverModal";
import SendOTPModal from "../../components/modals/SendOTPModal";

const ManageOTP = () => {
  const [OTP, setOTP] = useState([]);
  const [result, setResult] = useState([]);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [isOpen, setIsOpen] = useState(false);
  const [recieverModal, setRecieverModal] = useState({ open: false, url: "" });

  const getOTP = async () => {
    try {
      const response = await axiosPrivate.get(
        "/auth-service/api/v1/admin/get-all-otp",
        {
          params: {
            page: 0,
            size: 10,
          },
        }
      );
      setOTP(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOTP();
  }, []);

  const handleSearch = async ({ searchKey }) => {
    await getOTP();
    const emailRegex =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const phoneRegex = /^(\+234-|\+234|0)?\d{13}$/;
    const id = /\d{5}$/;

    let result;

    if (searchKey.match(emailRegex)) {
      try {
        result = OTP?.filter((request) => request.email === searchKey);
      } catch (error) {
        console.log(error);
      }
    } else if (searchKey.match(phoneRegex)) {
      try {
        result = OTP?.filter((request) => request.phoneNumber === searchKey);
      } catch (error) {
        console.log(error);
      }
    } else if (searchKey.match(id)) {
      try {
        result = OTP?.filter((request) => request.id === Number(searchKey));
      } catch (error) {
        console.log(error);
      }
    }
    setResult(result);
  };

  const columns = [
    { field: "id", headerName: "Id", width: 100 },
    {
      field: "email",
      headerName: "Reciever Email",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      field: "code",
      headerName: "OTP Recieved",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      field: "requestType",
      headerName: "Type",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      field: "valid",
      headerName: "Status",
      cellClassName: "name-column--cell",
      width: 100,
      renderCell: ({ row: { exported } }) => (
        <Typography
          color={exported ? colors.greenAccent[400] : colors.redAccent[400]}
        >
          {exported ? "valid" : "expired"}
        </Typography>
      ),
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      width: 100,
      renderCell: ({ row: { expiryDate } }) => {
        return (
          <Typography color={colors.greenAccent[400]}>
            {expiryDate.dayOfMonth} / {expiryDate.monthValue} /{expiryDate.year}
          </Typography>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <SendOTPModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setRecieverModal={setRecieverModal}
      />
      <OTPRecieverModal
        isOpen={recieverModal.open}
        setIsOpen={setRecieverModal}
        url={recieverModal.url}
      />

      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title="OTP" subtitle="Manage OTP" />

        <Box display="flex" alignItems="end">
          <Box>
            <SearchTable
              handleSearch={handleSearch}
              placeholder="Email/Phone/Id"
            />
          </Box>
          <Box marginTop={2}>
            <Button
              onClick={() => {
                setIsOpen(true);
              }}
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                ml: 2,
              }}
            >
              <ExitToAppIcon sx={{ mr: "10px" }} />
              Send OTP
            </Button>
          </Box>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Box
          m="10px"
          width="100%"
          height="65vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={result ? result : []}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default ManageOTP;
