import { useTheme } from "@emotion/react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import { useState } from "react";

const Tier3 = ({ userId, setDocModal }) => {
  const [details, setDetails] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const getTerminals = async () => {
      try {
        const response = await axiosPrivate.get(
          "/kyc-servicev2/kyc/fetch-status/" + userId
        );
        setDetails(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getTerminals();
  }, []);

  const rows = [
    {
      id: 1,
      kycRequirements: "Passport",
      status: details?.userPassportPhotograph?.adminStatus,
      url: details?.userPassportPhotograph?.imageUrl,
    },
    {
      id: 2,
      kycRequirements: "International Passport",
      status: details?.userInternationalPassport?.adminStatus,
      url: details?.userInternationalPassport?.imageUrl,
    },
    {
      id: 3,
      kycRequirements: "Utility Bill",
      status: details?.usersUtilityBill?.adminStatus,
      url: details?.usersUtilityBill?.imageUrl,
    },
  ];

  const completed =
    rows[0].status === "APPROVED" &&
    rows[1].status === "APPROVED" &&
    rows[2].status === "APPROVED";

  const columns = [
    { field: "kycRequirements", headerName: "KYC Requirements", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 90,
      renderCell: ({ row: { status } }) => {
        return (
          <Typography
            color={status ? colors.greenAccent[400] : colors.redAccent[400]}
          >
            {status ? "verified" : "pending"}
          </Typography>
        );
      },
    },
    {
      field: "url",
      headerName: "Documents",
      width: 140,
      renderCell: ({ row: { url } }) => {
        return (
          <Button
            underline="none"
            onClick={() => setDocModal({ open: true, imgUrl: url })}
            disabled={!url}
            sx={{
              backgroundColor: colors.orangeAccent[700],
              color: colors.grey[100],
              fontSize: "13px",
              padding: "5px 20px",
              marginLeft: "15px",
            }}
          >
            View Document
          </Button>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 320,
      renderCell: ({ row: { status } }) => {
        return (
          <Box>
            <Button
              underline="none"
              // onClick={() => setModal({ open: true })}
              color="secondary"
              disabled={status}
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "13px",
                padding: "5px 20px",
                marginLeft: "15px",
              }}
            >
              upload
            </Button>
            <Button
              underline="none"
              // onClick={() => setModal({ open: true })}
              color="secondary"
              disabled={status}
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "13px",
                padding: "5px 20px",
                marginLeft: "15px",
              }}
            >
              approve
            </Button>
            <Button
              underline="none"
              // onClick={() => setModal({ open: true })}
              color="secondary"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "13px",
                padding: "5px 20px",
                marginLeft: "15px",
              }}
            >
              reject
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box
      m="10px"
      width="700px"
      height="35vh"
      mt={10}
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.orangeAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.redAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.redAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.orangeAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
        textAlign: "right",
      }}
    >
      <Button
        sx={{
          bgcolor: completed ? colors.greenAccent[600] : colors.redAccent[600],
          mb: 2,
        }}
      >
        {completed ? "Completed" : "Incomplete"}
      </Button>
      <DataGrid
        getRowId={(row) => {
          return row.id;
        }}
        rows={rows}
        columns={columns}
      />
    </Box>
  );
};

export default Tier3;
