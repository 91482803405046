import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import * as yup from "yup";
import { useTheme } from "@emotion/react";
import { Formik } from "formik";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";

const OTPRecieverModal = ({ isOpen, setIsOpen, url }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const { success, snackError } = useAuth();

  const emailRegex =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  const phoneRegex = /^(\+234-|\+234|0)?\d{10}$/;

  // Formik
  const handleFormSubmit = async (values, actions) => {
    console.log(values);
    try {
      const response = await axiosPrivate.get(url, {
        params:
          url.match("/[^/]*$")[0] == "/byPhone"
            ? {
                phoneNumber: values.emailOrPhone,
              }
            : {
                email: values.emailOrPhone,
              },
      });
      success(response?.data?.data);
    } catch (error) {
      if (error.response) {
        snackError(error?.response?.data?.data);
      } else {
        console.log(error);
      }
    }
    actions.resetForm();
    handleClose();
  };

  const initialValues = {
    emailOrPhone: "",
  };

  const checkoutSchema = yup.object().shape({
    emailOrPhone: yup
      .string("Enter your Email/Phone Number")
      .required("Email/Phone Number is required")
      .test("emailOrPhone", "Enter Valid Phone/Email", function (value) {
        let isValidEmail = emailRegex.test(value);
        let isValidPhone = phoneRegex.test(value);
        if (!isValidEmail && !isValidPhone) {
          return false;
        }
        return true;
      }),
  });

  // Close the modal
  const handleClose = () => {
    setIsOpen((prev) => {
      return { ...prev, open: false };
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    width: 520,
    height: 280,
    bgcolor: colors.primary[400],
    border: "1px solid #000",
    boxShadow: 24,
    p: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h5" component="h2" align="center">
          Send OTP
        </Typography>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email / Phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.emailOrPhone}
                name="emailOrPhone"
                error={!!touched.emailOrPhone && !!errors.emailOrPhone}
                helperText={touched.emailOrPhone && errors.emailOrPhone}
                sx={{ gridColumn: "span 16", marginBottom: "10px" }}
              />
              <Button
                variant="outlined"
                type="submit"
                sx={{
                  backgroundColor: colors.orangeAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                underline="none"
              >
                Send OTP
              </Button>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default OTPRecieverModal;
