import React, { useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
} from "@mui/material";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { generateReqId } from "../../utils/generateReqId";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const WarningModal = ({ open, url, user, setOpen, action }) => {
  const [isLoading, setIsLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const { success, snackError, cookies } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClose = () => {
    setIsLoading(false);
    setOpen((prev) => {
      return { ...prev, openConfirm: false };
    });
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    if (action === "accept") {
      try {
        const response = await axiosPrivate.patch(url, {
          action: "APPROVE",
          logId: user.id,
        });
        success(response?.data?.message);
      } catch (error) {}
    } else if (action === "accept kyc request") {
      const { id } = user;
      try {
        const response = await axiosPrivate.put(url + id, null, {
          params: { status: "APPROVED" },
        });
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    } else if (action === "accept kyc") {
      const { merchantId } = user;
      try {
        const response = await axiosPrivate.post(
          `https://services.staging.wayapay.ng/identity-manager-service/api/v1/waya-merchant/business-kyc/${merchantId}/change-status/APPROVED`
        );
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    } else if (action === "approve kyc") {
      const { id } = user;
      try {
        const response = await axiosPrivate.put(url + id, null, {
          params: {
            status: "APPROVED",
          },
        });
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    } else if (action === "accept request") {
      try {
        const { id, userId } = user;
        const response = await axiosPrivate.post(url, {
          requestId: id,
          requestStatus: "APPROVED",
          userId: userId,
        });
        success(response?.data?.respDesc);
      } catch (error) {
        console.log(error);
      }
    } else if (action === "approve payment log") {
      try {
        const { id } = user;
        const response = await axiosPrivate.patch(
          url,
          {
            payment_request_id: id,
          },
          {
            params: {
              sso_id: cookies.auth.user.id,
            },
          }
        );
        console.log(response);
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    } else if (action === "disburse") {
      console.log(cookies.auth.user);
      const {
        ApprovalProfile: { id: requesterId },
      } = user;
      try {
        const response1 = await axiosPrivate.get(
          "/wayagram-service/role-access/get-user-data",
          {
            params: {
              user_id: cookies.auth.user.id,
              sso_id: cookies.auth.user.id,
            },
          }
        );
        const { RoleId } = response1?.data?.data;
        const response = await axiosPrivate.post(
          url,
          {
            payment_request_id: RoleId,
            requester_role_id: requesterId,
          },
          {
            params: {
              sso_id: cookies.auth.user.id,
            },
          }
        );
        console.log(response);
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    } else if (action === "reverse transaction") {
      const { tranCrncyCode, tranDate, tranId, tranAmount } = user;
      try {
        const response = await axiosPrivate.post(url, {
          customerName: "",
          payload: JSON.stringify({
            tranCrncy: tranCrncyCode,
            tranDate,
            tranId,
            tranAmount,
          }),
          requestId: generateReqId(),
          requestType: "reversal",
        });
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    } else {
      const { firstName, lastName, userId, email } = user;
      // Handle user consent here
      let response;
      if (action === "block" || action == "lock" || action == "unblock") {
        try {
          response = await axiosPrivate.post(url, null, {
            params: {
              userId,
            },
          });
          success(response?.data?.data);
        } catch (error) {
          snackError(error?.response?.data?.message);
        }
      } else if (action === "reset") {
        try {
          response = await axiosPrivate.get(url, {
            params: {
              email: email,
            },
          });
          success(response?.data?.message);
        } catch (error) {
          snackError(error?.response?.data?.message);
        }
      } else if (action === "deactivate" || action === "activate") {
        try {
          response = await axiosPrivate.post(url, {
            customerName: `${firstName} ${lastName}`,
            payload: JSON.stringify({ userId: userId }),
            requestId: generateReqId(),
            requestType: "ACTIVATIONTOGGLE",
          });
          success(response?.data?.message);
        } catch (error) {
          snackError(error?.response?.data?.message);
        }
      }
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ bgcolor: colors.primary[400] }}>
        <Typography variant="h3">
          Are you sure you want to {action}{" "}
          {action == "accept"
            ? "request"
            : action == "accept request"
            ? ""
            : action == "approve payment log"
            ? ""
            : action == "reverse transaction"
            ? ""
            : action == "disburse"
            ? "money"
            : action == "accept kyc"
            ? "for this merchant"
            : action == "approve kyc"
            ? "for this user"
            : "user"}
          ?
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: colors.primary[400] }}>
        <div style={{ textAlign: "center" }}>
          <ReportGmailerrorredIcon style={{ fontSize: "5rem", color: "red" }} />
        </div>
      </DialogContent>
      <DialogActions sx={{ bgcolor: colors.primary[400] }}>
        <Button
          disabled={isLoading}
          sx={{ fontSize: "16px" }}
          onClick={handleClose}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleConfirm}
          sx={{ fontSize: "16px", color: colors.primary[100] }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningModal;
