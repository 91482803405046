import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import { DataGrid } from "@mui/x-data-grid";
import useQRCodeDetails from "../../hooks/getQRCodes";
import { useState } from "react";
import useFieldMarketerDetails from "../../hooks/getFieldMarketer";
import IconMenu from "../../components/IconMenu";
import CreateFieldMarketer from "../../components/CreateFieldMarketer";
import QRCodeModal from "../../components/modals/QRCodeModal";
import { generateQRCode } from "../../utils/qrcodeGen";
import { ViewDocumentModal } from "../../components/modals/ViewDocumentModal";

const QRCode = () => {
  const [showFieldMarketer, setShowFieldMarketer] = useState(false);
  const [qrCodeModal, setQrCodeModal] = useState({
    open: false,
    title: "",
  });
  const [modal, setModal] = useState({
    open: false,
  });
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [viewDocModal, setViewDocModal] = useState();

  const {
    QrCode,
    TotalExported,
    TotalGenerated,
    TotalAssigned,
    TotalUnassigned,
  } = useQRCodeDetails();

  const { fieldMarketer, totalActive, totalInactive } =
    useFieldMarketerDetails();

  const toggleView = () => {
    setShowFieldMarketer(!showFieldMarketer);
  };

  const columns = [
    { field: "id", headerName: "Id", width: 100 },
    {
      field: "assigned",
      headerName: "Assigned Status",
      width: 100,
      renderCell: ({ row: { assigned } }) => (
        <Typography
          color={assigned ? colors.greenAccent[400] : colors.redAccent[400]}
        >
          {assigned ? "assigned" : "not assigned"}
        </Typography>
      ),
    },
    {
      field: "exported",
      headerName: "Exported Status",
      cellClassName: "name-column--cell",
      width: 100,
      renderCell: ({ row: { exported } }) => (
        <Typography
          color={exported ? colors.greenAccent[400] : colors.redAccent[400]}
        >
          {exported ? "exported" : "not exported"}
        </Typography>
      ),
    },
    {
      field: "fullName",
      headerName: "Name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      field: "qrCode",
      headerName: "QR Code",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 300,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: ({ row: { qrCode } }) => {
        return (
          <Button
            onClick={async () => {
              const imgUrl = await generateQRCode(qrCode);
              setViewDocModal({
                open: true,
                imgUrl,
              });
            }}
            underline="none"
            sx={{
              backgroundColor: colors.orangeAccent[700],
              color: colors.grey[100],
            }}
          >
            View QR Code
          </Button>
        );
      },
    },
  ];

  const columns2 = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 200,
      renderCell: ({ row: { firstName, lastName } }) => (
        <Typography>{firstName + " " + lastName}</Typography>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      width: 100,
    },
    {
      field: "email",
      headerName: "Email",
      cellClassName: "name-column--cell",
      width: 300,
    },
    {
      field: "assignedCode",
      headerName: "QR Assigned",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      field: "active",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      renderCell: ({ cellValue }) => {
        return Date(cellValue);
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 60,
      align: "center",
      renderCell: (params) => {
        return (
          <div data-gid={`row${params.id}-col-actions`}>
            <IconMenu rowId={params.id} /> {/* Render the icon for the menu */}
          </div>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <QRCodeModal
        open={qrCodeModal.open}
        setOpen={setQrCodeModal}
        title={qrCodeModal.title}
      />
      <ViewDocumentModal modal={viewDocModal} setModal={setViewDocModal} />
      <CreateFieldMarketer modal={modal} setModal={setModal} />

      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header
          title={showFieldMarketer ? "FIELD MARKETER" : "QR CODE"}
          subtitle={
            showFieldMarketer ? "Manage Field Marketers" : "Manage QR Codes"
          }
        />

        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Button
              onClick={() => toggleView()}
              underline="none"
              sx={{
                backgroundColor: colors.greenAccent[800],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              <LoyaltyIcon sx={{ mr: "10px" }} />
              {showFieldMarketer ? "Manage QRCode" : "Manage Field Marketer"}
            </Button>
          </Box>
          <Box>
            {!showFieldMarketer && (
              <Button
                onClick={() =>
                  setQrCodeModal((prev) => ({
                    open: true,
                    title: "export",
                  }))
                }
                underline="none"
                sx={{
                  backgroundColor: colors.orangeAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  marginLeft: "15px",
                }}
              >
                <SaveAsIcon sx={{ mr: "10px" }} />
                Export QRCode
              </Button>
            )}
          </Box>
          <Box>
            <Button
              onClick={() => {
                showFieldMarketer
                  ? setModal({ open: true })
                  : setQrCodeModal((prev) => ({
                      open: true,
                      title: "generate",
                    }));
              }}
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginLeft: "15px",
              }}
            >
              <ExitToAppIcon sx={{ mr: "10px" }} />
              {showFieldMarketer ? "Create Field Marketer" : "Generate QRCode"}
            </Button>
          </Box>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {showFieldMarketer ? (
              <StatBox
                title={fieldMarketer.length}
                subtitle="Field Marketers"
                icon={
                  <PlaylistAddIcon
                    sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                  />
                }
              />
            ) : (
              <StatBox
                title={TotalExported}
                subtitle="Total Exported"
                icon={
                  <SaveAsIcon
                    sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                  />
                }
              />
            )}
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {showFieldMarketer ? (
              <StatBox
                title={totalActive}
                subtitle="Total Active"
                icon={
                  <ExitToAppIcon
                    sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                  />
                }
              />
            ) : (
              <StatBox
                title={TotalGenerated}
                subtitle="Total Generated"
                icon={
                  <ExitToAppIcon
                    sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                  />
                }
              />
            )}
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {showFieldMarketer ? (
              <StatBox
                title={totalInactive}
                subtitle="Total Inactive"
                icon={
                  <AssignmentTurnedInIcon
                    sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                  />
                }
              />
            ) : (
              <StatBox
                title={TotalAssigned}
                subtitle="Total Assigned"
                icon={
                  <AssignmentTurnedInIcon
                    sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                  />
                }
              />
            )}
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={TotalUnassigned}
              subtitle="Unassigned"
              icon={
                <AssignmentLateIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Box
          m="10px"
          width="100%"
          height="58vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          {showFieldMarketer ? (
            <DataGrid
              getRowId={(row) => {
                return row.date;
              }}
              rows={fieldMarketer ? fieldMarketer : []}
              columns={columns2}
              // components={{ Toolbar: GridToolbar }}
            />
          ) : (
            <DataGrid
              rows={QrCode ? QrCode.qrcodeList : []}
              columns={columns}
              // components={{ Toolbar: GridToolbar }}
            />
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default QRCode;
