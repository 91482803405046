import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React from "react";
import { tokens } from "../../theme";
import CardList from "../../components/cardComponents/list";
import useAuth from "../../hooks/useAuth";
import BackButton from "../../components/BackButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 25,
  },
  card: {
    width: "100%",
    borderRadius: 8,
  },
  content: {
    width: "100%",
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
}));

function SettlementDetails(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const classes = useStyles();
  const { queryTransaction } = useAuth();
  const {
    id,
    dateCreated,
    dateModified,
    createdBy,
    modifiedBy,
    deleted,
    merchantConfiguredSettlementDate,
    dateSettled,
    settlementInitiationDate,
    merchantId,
    merchantUserId,
    settlementStatus,
    payoutStatus,
    settlementReferenceId,
    settlementNetAmount,
    settlementGrossAmount,
    totalFee,
    settlementAccount,
    settlementInterval,
    accountSettlementOption,
    settlementBeneficiaryAccount,
    paymentRefNo,
    settlementAccountName,
    settlementBankName,
    settlementBankCode,
    settlementType,
  } = queryTransaction;

  const rows = [
    {
      referenceId: settlementReferenceId,
      actualAmount: settlementGrossAmount,
      status: settlementStatus,
      fee: totalFee,
      settledAmount: settlementNetAmount,
      date: dateCreated,
    },
  ];

  return (
    <div className={classes.root}>
      <BackButton path="/settlements" />
      <Card
        className={classes.card}
        sx={{ bgcolor: colors.primary[900], mt: 5 }}
      >
        <CardContent
          className={classes.content}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Card sx={{ p: 3, width: "30%", bgcolor: colors.primary[400] }}>
            <Typography variant="h4" textAlign="center">
              Settled Amount
            </Typography>
            <Typography
              variant="h4"
              color={colors.orangeAccent[400]}
              textAlign="center"
              pt={1}
            >
              {settlementNetAmount}
            </Typography>
          </Card>
          <hr />
          <Card sx={{ p: 3, width: "33%", bgcolor: colors.primary[400] }}>
            <Typography variant="h4" textAlign="center">
              Activity
            </Typography>
            <Typography
              variant="h5"
              color={colors.orangeAccent[400]}
              textAlign="center"
              pt={1}
            >
              1 Transaction(s), 1 Deduction(s)
            </Typography>
          </Card>
          <hr />
          <Card sx={{ p: 3, width: "30%", bgcolor: colors.primary[400] }}>
            <Typography variant="h4" textAlign="center">
              Completed on
            </Typography>
            <Typography
              variant="h4"
              color={colors.orangeAccent[400]}
              textAlign="center"
              pt={1}
            >
              Not Available
            </Typography>
          </Card>
        </CardContent>
        <Card sx={{ width: "100%", mt: 4 }}>
          <CardContent
            className={classes.cardContent}
            sx={{ p: 1, bgcolor: colors.primary[400] }}
          >
            <Table sx={{ minHeight: 150 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", fontSize: "14px" }}>
                    Reference Id
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", fontSize: "14px" }}
                    align="right"
                  >
                    Actual Amount
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", fontSize: "14px" }}
                    align="right"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", fontSize: "14px" }}
                    align="right"
                  >
                    Fee
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", fontSize: "14px" }}
                    align="right"
                  >
                    Settled Amount
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", fontSize: "14px" }}
                    align="right"
                  >
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.referenceId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{ fontSize: 17 }}>
                      {row.referenceId}
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h5">{row.actualAmount}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="h5"
                        color={
                          row.status == "SETTLED"
                            ? colors.greenAccent[300]
                            : colors.orangeAccent[300]
                        }
                      >
                        {row.status}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h5">{row.fee}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h5">{row.settledAmount}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h5" textAlign="center">
                        {row.date}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Card>
    </div>
  );
}

export default SettlementDetails;
