import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";

const SettlementFormModal = ({ isOpen, setModal, title }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const { success } = useAuth();

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const handleFormSubmit = async (values, actions) => {
    const { paymentStatus, processor, referenceNumber } = values;

    try {
      const response = await axiosPrivate.post(
        "https://services.staging.wayapos.ng/wayapos-settlement-service/api/v1/settlement/doManual/byReferenceNumber",
        null,
        {
          params: {
            paymentStatus,
            processor,
            referenceNumber,
          },
        }
      );
      success(
        response?.data?.respBody
          ? response?.data?.respBody
          : "Successfully submitted"
      );
    } catch (error) {
      console.log(error);
    }
    actions.resetForm();
    handleClose();
  };

  const initialValues = {
    paymentStatus: "",
    processor: "",
    referenceNumber: "",
  };

  const checkoutSchema = yup.object().shape({
    paymentStatus: yup.string().required("Required"),
    processor: yup.string().required("Required"),
    referenceNumber: yup.number().required("Required"),
  });

  const options = [
    {
      name: "SUCCESSFUL",
    },
    {
      name: "FAILED",
    },
    {
      name: "ABANDONED",
    },
    {
      name: "REFUNDED",
    },
  ];

  const options2 = [
    {
      name: "UPSL",
    },
    {
      name: "NIBSS",
    },
    {
      name: "ISW",
    },
  ];

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: 450,
          height: 400,
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "10px" }}
                >
                  {title}
                </Typography>
                <TextField
                  label="Payment Status"
                  fullWidth
                  select
                  name="paymentStatus"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.paymentStatus}
                  sx={{ marginBottom: "10px" }}
                >
                  {options.map((option, index) => (
                    <MenuItem key={index} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Processor"
                  fullWidth
                  select
                  name="processor"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.processor}
                  sx={{ marginBottom: "10px" }}
                >
                  {options2.map((option, index) => (
                    <MenuItem key={index} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Reference Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.referenceNumber}
                  name="referenceNumber"
                  error={!!touched.referenceNumber && !!errors.referenceNumber}
                  helperText={touched.referenceNumber && errors.referenceNumber}
                />
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{ width: "100%", p: 2, mt: 2 }}
                >
                  Submit
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default SettlementFormModal;
