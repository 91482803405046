import React from "react";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { tokens } from "../theme";

const useStyles = makeStyles((props) => {
  return {
    cardContainer: {
      position: "relative",
      border: "1px solid #7c7c7c",
      background: "transparent",
      textAlign: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: 200,
      height: 110,
      borderRadius: "5px",
    },
    leftContainer: {
      width: "50%",
    },
    rightContainer: {
      width: "50%",
    },
  };
});

const AccountCard = ({ handleClick, account }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const classes = useStyles(colors);
  let { accountNumber, accountName, bankName } = {};
  if (account) {
    ({ accountNumber, accountName, bankName } = account);
  }

  return (
    <Card variant="outlined" color={colors.primary[400]} sx={{ m: 0.5 }}>
      <CardContent className={classes.cardContainer}>
        <div className={classes.leftContainer}>
          <Typography variant="h5">{bankName}</Typography>
          <Box marginTop={2.4}>
            <Typography
              variant="h6"
              color={colors.orangeAccent[300]}
              fontWeight="bold"
              fontSize="10px"
            >
              account name
            </Typography>
            <Typography variant="h6" fontWeight="bold" fontSize="10px">
              {accountName}
            </Typography>
          </Box>
        </div>
        <div className={classes.rightContainer}>
          <Box marginTop={5}>
            <Typography
              variant="h6"
              color={colors.orangeAccent[300]}
              fontWeight="bold"
              fontSize="10px"
            >
              account number
            </Typography>
            <Typography variant="h6" fontWeight="bold" fontSize="10px">
              {accountNumber}
            </Typography>
          </Box>
        </div>
      </CardContent>
    </Card>
  );
};

export default AccountCard;
