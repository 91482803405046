import React from "react";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import useAuth from "../../hooks/useAuth";
import StrapiSwitch from "../../components/Switch";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { generateReqId } from "../../utils/generateReqId";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import BackButton from "../../components/BackButton";
import ReactDatePicker from "react-datepicker";
import { useEffect } from "react";
import { useState } from "react";
import naijaStateLocalGovernment from "naija-state-local-government";

const CREATE_USER_URL = "/logs-service/api/v1/requestlog/create";

const UpdateUser = () => {
  const { queryUserDetails, snackError, success } = useAuth();
  let {
    id,
    userId,
    firstName,
    surname,
    smsAlertConfig,
    referenceCode,
    phoneNumber,
    gender,
    email,
    dateOfBirth,
    corporate,
    referral,
    otherDetails,
  } = queryUserDetails?.manageProfile;

  const {
    billsPayment = false,
    deposit = false,
    withdrawal = false,
  } = queryUserDetails?.transConfig || {};

  const {
    businessType = "",
    organisationEmail = "",
    organisationName = "",
    organisationPhone = "",
    organizationAddress = "",
  } = otherDetails || {};

  dateOfBirth = new Date(dateOfBirth ? dateOfBirth : null);
  const active = queryUserDetails?.manageProfile?.otherDetails;

  const [businessTypes, setBusinessTypes] = useState([]);
  const [userActivities, setUserActivities] = useState([]);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleFormSubmit = async (values) => {
    const {
      firstName,
      surname,
      email,
      dateOfBirth,
      phoneNumber,
      gender,
      referral,
      referenceCode,
      smsAlertConfig,
    } = values;

    try {
      const response = await axiosPrivate.post(CREATE_USER_URL, {
        customerName: `${firstName} ${surname}`,
        payload: JSON.stringify({
          id,
          email,
          firstName,
          surname,
          dateOfBirth: dateOfBirth.toDateString(),
          gender,
          phoneNumber,
          userId,
          referral,
          referenceCode,
          smsAlertConfig,
          corporate,
        }),
        requestId: generateReqId(),
        requestType: "UPDATEPERSONALUSER",
      });
      success(response.data.message);
      // navigate("/manage_user", { replace: true });
    } catch (error) {
      if (error.response.status == 403) snackError("Not Authorized");
      console.log(error);
    }
  };

  const handleCheckChange = async (e, setFieldValue) => {
    if (e.target.name === "smsAlertConfig") {
      try {
        const response = await axiosPrivate.post(
          "/auth-service/api/v1/admin/users/subscribe-sms-alert",
          {
            phoneNumber,
          }
        );
        setFieldValue(e.target.name, response?.data?.data?.active);
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    } else if (e.target.name === "billPayments") {
      try {
        const response = await axiosPrivate.post(
          "/temporal-service/api/v1/wallet/toggle/" + userId,
          {
            phoneNumber,
          },
          {
            params: {
              type: "Bills",
            },
          }
        );
        setFieldValue(e.target.name, response?.data?.data?.billsPayment);
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    } else if (e.target.name === "withdrawal") {
      try {
        const response = await axiosPrivate.post(
          "/temporal-service/api/v1/wallet/toggle/" + userId,
          {
            phoneNumber,
          },
          {
            params: {
              type: "Withdrawal",
            },
          }
        );
        setFieldValue(e.target.name, response?.data?.data?.withdrawal);
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    } else if (e.target.name === "deposit") {
      try {
        const response = await axiosPrivate.post(
          "/temporal-service/api/v1/wallet/toggle/" + userId,
          {
            phoneNumber,
          },
          {
            params: {
              type: "Deposit",
            },
          }
        );
        setFieldValue(e.target.name, response?.data?.data?.deposit);
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getBusinessIndustries = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayapay.ng/identity-manager-service/api/v1/waya-merchant/business-kyc/business-industry"
      );
      setBusinessTypes(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserActivities = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayagram.ng/wayagram-service/main/admin-profile/all"
      );
      setUserActivities(response?.data?.data?.AllWayAGramProfiles);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserActivities();
    getBusinessIndustries();
  }, []);

  let userActivity;
  if (userActivities.length > 0) {
    userActivity = userActivities.filter((user) => user.UserId == userId);
  }

  const genders = [
    {
      name: "Male",
    },
    {
      name: "Female",
    },
  ];

  const initialValues = {
    userType: corporate ? "Corporate" : "Individual",
    status: active ? "Active" : "Inactive",
    userId,
    firstName,
    surname,
    email,
    phoneNumber,
    gender,
    dateOfBirth,
    referenceCode,
    referral,
    currentTierLevel: "Not set",
    transProcessStatus: "",
    accountBlockStatus: "",
    maxDailyTransLimit: 0,
    maxDailyTransCount: 0,
    BVN: "",
    smsAlertConfig: smsAlertConfig,
    billPayments: billsPayment,
    withdrawal: withdrawal,
    deposit: deposit,
    maxTransLimit: 0,
    numOfTransAllowed: 0,
    singleTransferLimit: 0,
    organisationName,
    organisationEmail,
    organisationPhone,
    organisationState: "Lagos",
    organisationCity: "Ikeja",
    organizationAddress,
    businessType,
  };

  return (
    <Box m="20px">
      <BackButton path="/" />
      <Header title="UPDATE USER" subtitle="Update this user profile" />

      <Formik onSubmit={handleFormSubmit} initialValues={initialValues}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                disabled
                fullWidth
                variant="filled"
                type="text"
                label="User Type"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.userType}
                name="userType"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                disabled
                variant="filled"
                type="text"
                label="Status"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.status}
                name="status"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                disabled
                variant="filled"
                type="text"
                label="User Id"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.userId}
                name="userId"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.surname}
                name="surname"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneNumber}
                name="phoneNumber"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                select
                label="Gender"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.gender}
                name="gender"
                sx={{ gridColumn: "span 1" }}
              >
                {genders.map((gender, index) => (
                  <MenuItem key={index} value={gender.name}>
                    {gender.name}
                  </MenuItem>
                ))}
              </TextField>
              <Box sx={{ gridColumn: "span 1" }}>
                <ReactDatePicker
                  selected={values.dateOfBirth}
                  onChange={(date) => setFieldValue("dateOfBirth", date)}
                  name="dateOfBirth"
                  dateFormat="d MMMM, yyyy"
                  customInput={
                    <TextField
                      fullWidth
                      InputProps={{ readOnly: true }}
                      label="Date of Birth"
                    />
                  }
                />
              </Box>
              <TextField
                fullWidth
                disabled
                variant="filled"
                type="text"
                label="Reference Code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.referenceCode}
                name="referenceCode"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                disabled
                variant="filled"
                type="text"
                label="Referral"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.referral}
                name="referral"
                sx={{ gridColumn: "span 1" }}
              />
              <StrapiSwitch
                name="smsAlertConfig"
                checked={values.smsAlertConfig}
                onChange={(e) => handleCheckChange(e, setFieldValue)}
                label="Sms Alert"
              />
              <Typography
                variant="h3"
                sx={{ gridColumn: "span 4", color: colors.grey[600] }}
              >
                TRANSACTION DETAILS
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Maximun Daily Transaction Limit"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.maxDailyTransLimit}
                name="maxDailyTransLimit"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Maximun Daily Transaction Count"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.maxDailyTransCount}
                name="maxDailyTransCount"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                disabled
                variant="filled"
                type="number"
                label="BVN"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.BVN}
                name="BVN"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Maximun Transaction Limit"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.maxTransLimit}
                name="maxTransLimit"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="No. of Transactions Allowed"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.numOfTransAllowed}
                name="numOfTransAllowed"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Single Transfer Limit"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.singleTransferLimit}
                name="singleTransferLimit"
                sx={{ gridColumn: "span 1" }}
              />
              <StrapiSwitch
                checked={values.billPayments}
                name="billPayments"
                onChange={(e) => handleCheckChange(e, setFieldValue)}
                label="Bills Payments"
              />
              <StrapiSwitch
                checked={values.withdrawal}
                name="withdrawal"
                onChange={(e) => handleCheckChange(e, setFieldValue)}
                label="Withdrawal"
              />
              <StrapiSwitch
                checked={values.deposit}
                name="deposit"
                onChange={(e) => handleCheckChange(e, setFieldValue)}
                label="Deposit"
              />
              {active && (
                <>
                  <Typography
                    variant="h3"
                    sx={{ gridColumn: "span 4", color: colors.grey[600] }}
                  >
                    ORGANIZATION DETAILS
                  </Typography>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Organisation Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organisationName}
                    name="organisationName"
                    sx={{ gridColumn: "span 1" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Organisation Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organisationEmail}
                    name="organisationEmail"
                    sx={{ gridColumn: "span 1" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Organisation Phone Number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organisationPhone}
                    name="organisationPhone"
                    sx={{ gridColumn: "span 1" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    select
                    label="Organisation State"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organisationState}
                    name="organisationState"
                    sx={{ gridColumn: "span 1" }}
                  >
                    {naijaStateLocalGovernment.states().map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    select
                    label="Organisation City"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organisationCity}
                    name="organisationCity"
                    sx={{ gridColumn: "span 1" }}
                  >
                    {values?.organisationState
                      ? naijaStateLocalGovernment
                          .lgas(values?.organisationState)
                          .lgas?.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))
                      : []}
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Organisation Address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organizationAddress}
                    name="organizationAddress"
                    sx={{ gridColumn: "span 1" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    select
                    label="Business Type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.businessType}
                    name="businessType"
                    sx={{ gridColumn: "span 1" }}
                  >
                    {businessTypes.map((type, index) => (
                      <MenuItem key={index} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              )}
              {userActivity?.length > 0 && (
                <>
                  <Typography
                    variant="h3"
                    sx={{
                      gridColumn: "span 4",
                      color: colors.grey[600],
                    }}
                  >
                    USER ACTIVITIES
                  </Typography>
                  <TextField
                    fullWidth
                    disabled
                    variant="filled"
                    type="number"
                    label="Groups"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={userActivity[0]?.groupCount}
                    name="groups"
                    sx={{ gridColumn: "span 1" }}
                  />
                  <TextField
                    fullWidth
                    disabled
                    variant="filled"
                    type="number"
                    label="Posts"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={userActivity[0]?.posts}
                    name="posts"
                    sx={{ gridColumn: "span 1" }}
                  />
                  <TextField
                    fullWidth
                    disabled
                    variant="filled"
                    type="number"
                    label="Pages"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={userActivity[0]?.pageCount}
                    name="pages"
                    sx={{ gridColumn: "span 1" }}
                  />
                  <TextField
                    fullWidth
                    disabled
                    variant="filled"
                    type="number"
                    label="Followers"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={userActivity[0]?.followers}
                    name="followers"
                    sx={{ gridColumn: "span 1" }}
                  />
                  <TextField
                    fullWidth
                    disabled
                    variant="filled"
                    type="number"
                    label="Following"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={userActivity[0]?.followings}
                    name="following"
                    sx={{ gridColumn: "span 1" }}
                  />
                </>
              )}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                underline="none"
                sx={{
                  backgroundColor: colors.orangeAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "12px 25px",
                }}
              >
                Update User
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default UpdateUser;
