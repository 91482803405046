import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import SearchTable from "../../components/SearchTable";
import RejectRequestModal from "../../components/modals/RejectRequestModal";
import WarningModal from "../../components/modals/WarningModal";

const WayaBankRequestLogs = () => {
  const [details, setDetails] = useState();
  const [stats, setStats] = useState([]);
  const [rejectModal, setRejectModal] = useState({
    open: false,
    url: "",
    user: {},
  });
  const [modal, setModal] = useState({
    openConfirm: false,
    url: "",
    action: "",
  });
  const { success, snackError, cookies } = useAuth();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const getRequestLogs = async () => {
    try {
      const response = await axiosPrivate.get(
        "/logs-service/api/v1/requestlog/list"
      );
      setDetails(response?.data?.data?.requestLog);
    } catch (error) {
      if (error.response.status === 403) {
        // snackError("Sorry, Not Authorized ");
      } else {
        console.log(error);
      }
    }
  };

  const getTotalRequestLogs = async () => {
    try {
      const response = await axiosPrivate.get(
        "/logs-service/api/v1/requestlog/total-request"
      );
      setStats(response?.data?.data);
    } catch (error) {
      if (error.response.status === 403) {
        // snackError("Sorry, Not Authorized ");
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getRequestLogs();
    getTotalRequestLogs();
  }, []);

  const columns = [
    { field: "requestId", headerName: "Request Id", width: 150 },
    {
      field: "createdBy",
      headerName: "Requested By",
      width: 150,
    },
    {
      field: "initiatorRole",
      headerName: "Role",
      cellClassName: "name-column--cell",
      width: 200,
    },
    {
      field: "customerName",
      headerName: "Request For",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "requestType",
      headerName: "Request Type",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: ({ row: { status } }) => {
        return (
          <Typography
            color={
              status == "APPROVED"
                ? colors.greenAccent[400]
                : colors.redAccent[400]
            }
          >
            {status}
          </Typography>
        );
      },
    },
    {
      field: "dateCreated",
      headerName: "Date",
      width: 100,
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}

      <RejectRequestModal
        isOpen={rejectModal.open}
        setIsOpen={setRejectModal}
        url={rejectModal.url}
        user={rejectModal.user}
      />

      <WarningModal
        open={modal.openConfirm}
        action={modal.action}
        url={modal.url}
        user={modal.user}
        setOpen={setModal}
      />

      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title="REQUEST LOGS" subtitle="Manage Users Request Logs" />
      </Box>

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={stats.totalRequest}
              subtitle="Requests"
              icon={
                <PlaylistAddIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={stats.totalApproved}
              subtitle="Approved"
              icon={
                <ExitToAppIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={stats.totalInitiated}
              subtitle="Pending"
              icon={
                <AssignmentTurnedInIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={stats.totalRejected}
              subtitle="Rejected"
              icon={
                <AssignmentLateIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={stats.totalFailed}
              subtitle="Failed"
              icon={
                <AssignmentLateIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Box
          m="10px"
          width="100%"
          height="60vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={details ? details : []}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default WayaBankRequestLogs;
