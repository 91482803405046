import React, { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import useAuth from "../../hooks/useAuth";
import Searchbox from "../../components/Searchbox";
import BackButton from "../../components/BackButton";

const LoginTracker = () => {
  const theme = useTheme();
  const { queryUserDetails } = useAuth();
  const colors = tokens(theme.palette.mode);

  let { loginTracker } = queryUserDetails;

  const trackers = loginTracker?.map((tracker) => ({
    id: tracker.id,
    country: tracker.country,
    device: tracker.device,
    loginDate: tracker.loginDate,
    ip: tracker.ip,
    province: tracker.province,
    city: tracker.city,
  }));

  const columns = [
    { field: "ip", headerName: "IP ADDRESS", width: 150 },
    {
      field: "device",
      headerName: "DEVICE",
      width: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "country",
      headerName: "COUNTRY",
      width: 100,
    },
    {
      field: "city",
      headerName: "CITY",
      width: 100,
    },
    {
      field: "loginDate",
      headerName: "TIME",
      headerAlign: "left",
      width: 200,
      align: "left",
      renderCell: ({ row: { loginDate } }) => {
        return Date(loginDate);
      },
    },
  ];
  return (
    <Box m="20px">
      <BackButton path="/" />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="LOGIN TRACKER" subtitle="Track User Logins" />
      </Box>
      <Box
        m="8px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "#fff solid",
          },
          "& .name-column--cell": {
            color: colors.orangeAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.redAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.redAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.orangeAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          getRowId={(row) => {
            return row.id;
          }}
          rows={loginTracker ? trackers : []}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default LoginTracker;
