import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";
import Topbar from "./pages/global/Topbar";
import ManageUser from "./pages/user";
import ManageOTP from "./pages/otp";
import RequireAuth from "./components/requireAuth";
import Login from "./pages/login";
import { ROLES } from "./shared/constants";
import CustomSnackbars from "./components/Snackbar";
import NoAuthRequired from "./components/noAuth";
import ForgetPassword from "./pages/login/forgetPassword";
import UpdateUser from "./pages/user/updateUser";
import UserKYC from "./pages/user/userKYC";
import LoginTracker from "./pages/user/loginTracker";
import QRCode from "./pages/qrCode";
import RequestLogs from "./pages/manageLogs/requestLogs";
import ManageFraud from "./pages/manageLogs/manageFraud";
import ManageKYC from "./pages/manageKYC/manageKYC";
import AllTransctions from "./pages/transactions/allTransactions";
import KYCSettings from "./pages/manageKYC/KYCSettings";
import PaymentRequest from "./pages/transactions/paymentRequest";
import NonWayaTransfer from "./pages/transactions/non_waya_transfer";
import ManageTerminals from "./pages/terminals/manageTerminals";
import Settlements from "./pages/transactions/settlements";
import ManageGroup from "./pages/manageLogs/manageGroup";
import ManagePage from "./pages/manageLogs/managePage";
import ManagePoll from "./pages/manageLogs/managePoll";
import ManageDonation from "./pages/manageLogs/manageDonation";
import ManageWayaQuickKYC from "./pages/manageKYC/manageWayaQuickKYC";
import WayaPosTransactions from "./pages/transactions/wayaposTransactions";
import WayaPosSettlements from "./pages/transactions/wayaposSettlementHistory";
import WayaQuickTransaction from "./pages/transactions/wayaquickTransactionHistory";
import ManagePos from "./pages/terminals/managePos";
import ManagePost from "./pages/manageLogs/managePost";
import ManageEvent from "./pages/manageLogs/manageEvent";
import WayaquickRequestLogs from "./pages/manageLogs/wayaquickRequestLogs";
import WayaPosRequestLogs from "./pages/manageLogs/wayaposRequestLogs";
import TransactionDetails from "./pages/transactions/transactionDetails";
import PaymentLogs from "./pages/manageLogs/paymentLogs";
import WayaGramAccount from "./pages/manageLogs/account";
import SettlementDetails from "./pages/transactions/settlementDetails";
import WayaBankRequestLogs from "./pages/manageLogs/wayaBankRequestLogs";

const App = () => {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <MyProSidebarProvider>
          <CssBaseline />
          <div style={{ height: "100%", width: "100%" }}>
            <main>
              <Topbar />
              <CustomSnackbars />
              <Routes>
                <Route element={<NoAuthRequired />}>
                  <Route path="/login" element={<Login />} />
                </Route>
                <Route element={<NoAuthRequired />}>
                  <Route path="/forget-password" element={<ForgetPassword />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="/" element={<ManageUser />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="/update_user" element={<UpdateUser />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="/user_kyc" element={<UserKYC />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="/transactions" element={<AllTransctions />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route
                    path="/transaction_history"
                    element={<WayaPosTransactions />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="/settlements" element={<Settlements />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route
                    path="/settlement_history"
                    element={<WayaPosSettlements />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route
                    path="/transaction_history_wayaquick"
                    element={<WayaQuickTransaction />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route
                    path="/transaction_details_wayaquick"
                    element={<TransactionDetails />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route
                    path="/wayabank_request_log"
                    element={<WayaBankRequestLogs />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route
                    path="/settlement_details_wayaquick"
                    element={<SettlementDetails />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route
                    path="/payment_requests"
                    element={<PaymentRequest />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route
                    path="/non_waya_transfers"
                    element={<NonWayaTransfer />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="/login_tracker" element={<LoginTracker />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="/qr_code" element={<QRCode />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="request_log" element={<RequestLogs />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route
                    path="request_logs"
                    element={<WayaquickRequestLogs />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route
                    path="request_logs_wayapos"
                    element={<WayaPosRequestLogs />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="manage_fraud" element={<ManageFraud />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="manage_group" element={<ManageGroup />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="manage_page" element={<ManagePage />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="manage_poll" element={<ManagePoll />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="manage_event" element={<ManageEvent />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="manage_post" element={<ManagePost />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="manage_donation" element={<ManageDonation />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="payment_log" element={<PaymentLogs />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="account" element={<WayaGramAccount />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="manage_kyc" element={<ManageKYC />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route
                    path="kyc_management"
                    element={<ManageWayaQuickKYC />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="kyc_settings" element={<KYCSettings />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="/otp" element={<ManageOTP />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route
                    path="/manage_terminals"
                    element={<ManageTerminals />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.AdminOwner, ROLES.AdminSuper]}
                    />
                  }
                >
                  <Route path="/manage_pos" element={<ManagePos />} />
                </Route>
              </Routes>
            </main>
          </div>
        </MyProSidebarProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
