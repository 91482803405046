import React from "react";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { tokens } from "../theme";

const useStyles = makeStyles((props) => {
  return {
    cardContainer: {
      position: "relative",
      border: "1px solid #7c7c7c",
      background: "transparent",
      textAlign: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: 220,
      height: 110,
      borderRadius: "5px",
    },
    leftContainer: {
      width: "55%",
      textAlign: "left",
    },
    rightContainer: {
      width: "45%",
      textAlign: "right",
    },
  };
});

const WalletCard = ({ handleClick, wallet }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const classes = useStyles(colors);

  let {
    accountNo,
    acct_name,
    clr_bal_amt,
    acct_crncy_code,
    acct_opn_date,
    walletDefault,
  } = {};
  if (wallet) {
    ({
      accountNo,
      acct_name,
      clr_bal_amt,
      acct_crncy_code,
      acct_opn_date,
      walletDefault,
    } = wallet);
  }

  return (
    <Card
      variant="outlined"
      color={colors.primary[400]}
      sx={{ m: 0.5, mx: 0.59 }}
    >
      <CardContent className={classes.cardContainer}>
        {walletDefault && (
          <Typography
            variant="h5"
            fontSize="10px"
            position="absolute"
            top={1}
            left={3}
            fontWeight="bold"
            color={colors.orangeAccent[400]}
          >
            DEFAULT
          </Typography>
        )}
        <div className={classes.leftContainer}>
          <Typography variant="h5" fontWeight="bold" fontSize="10px">
            {acct_crncy_code}{" "}
            <span
              style={{
                color: colors.greenAccent[400],
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              {clr_bal_amt}
            </span>
          </Typography>
          <Box marginTop={2.5}>
            <Typography
              variant="h6"
              color={colors.orangeAccent[300]}
              fontWeight="bold"
              fontSize="10px"
            >
              account name
            </Typography>
            <Typography variant="h6" fontWeight="bold" fontSize="10px">
              {acct_name}
            </Typography>
          </Box>
        </div>
        <div className={classes.rightContainer}>
          <Typography
            variant="h6"
            fontWeight="bold"
            color={colors.grey[300]}
            fontSize="10px"
          >
            open:{" "}
            <span
              style={{
                color: colors.blueAccent[500],
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              {acct_opn_date}
            </span>
          </Typography>
          <Box marginTop={2.5}>
            <Typography
              variant="h6"
              color={colors.orangeAccent[300]}
              fontWeight="bold"
              fontSize="10px"
            >
              account number
            </Typography>
            <Typography variant="h6" fontWeight="bold" fontSize="10px">
              {accountNo}
            </Typography>
          </Box>
        </div>
      </CardContent>
    </Card>
  );
};

export default WalletCard;
