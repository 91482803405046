import * as React from "react";
import { FormControlLabel, Switch } from "@mui/material";

const label = { inputProps: { "aria-label": "Color switch demo" } };

export default function StrapiSwitch({ label, checked, onChange, name }) {
  return (
    <FormControlLabel
      control={
        <Switch
          //   {...label}
          label="Start"
          checked={checked}
          onChange={onChange}
          name={name}
          color="secondary"
        />
      }
      label={label}
      //   labelPlacement="start"
    />
  );
}
