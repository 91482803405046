import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, IconButton, InputBase, useTheme } from "@mui/material";
import * as yup from "yup";
import { tokens } from "../theme";
import { Formik } from "formik";
import DateRangeComp from "./DateRangeComp";

const SearchTableWithDatePicker = ({ handleSearch, placeholder }) => {
  const searchRef = useRef(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const initialValues = {
    searchKey: "",
    dateRange: [null, null],
  };

  const checkoutSchema = yup.object().shape({
    searchKey: yup.string().required(),
  });

  return (
    <Box
      display="flex"
      backgroundColor={colors.primary[400]}
      p={0.2}
      borderRadius={1}
    >
      <Formik
        onSubmit={handleSearch}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", padding: 2, width: "100%" }}
          >
            {values.searchKey.length > 0 && (
              <IconButton
                onClick={() => {
                  setFieldValue("searchKey", "");
                  searchRef.current.focus();
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
            <InputBase
              sx={{ ml: 1, flex: 1, width: "200px" }}
              placeholder={placeholder ? placeholder : "Search..."}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.searchKey}
              inputRef={searchRef}
              name="searchKey"
            />
            <IconButton type="submit">
              <SearchIcon />
            </IconButton>
            <DateRangeComp />
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default SearchTableWithDatePicker;
