import {
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import SearchTable from "../../components/SearchTable";
import GroupModal from "../../components/modals/GroupModal";
import SpreadSheetUploadModal from "../../components/modals/SpreadSheetUploadModal";

const ManageGroup = () => {
  const [details, setDetails] = useState();
  const [modal, setModal] = useState({ open: false });
  const [spreadSheetModal, setSpreadSheetModal] = useState({ open: false });
  const { cookies } = useAuth();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [newDetails, setNewDetails] = useState([]);

  const getGroups = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayagram.ng/wayagram-service/main/admin-profile/search",
        {
          params: {
            query: "a",
            page: 1,
            sso_id: cookies?.auth?.user?.id,
          },
        }
      );
      setDetails(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.filter(
        (group) =>
          group?.email?.toLowerCase().trim() === searchKey.toLowerCase() ||
          group?.phone?.toLowerCase().trim() === searchKey.toLowerCase() ||
          group?.username?.toLowerCase().trim() === searchKey.toLowerCase()
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOption = async (e) => {
    setAnchorEl(null);
    if (e.target.textContent === "Create Single Group") {
      setModal({ open: true });
    } else if (e.target.textContent === "Create Bulk Group") {
      setSpreadSheetModal({ open: true, title: e.target.textContent });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const columns = [
    {
      field: "displayName",
      headerName: "Creator Name",
      width: 120,
    },
    {
      field: "username",
      headerName: "Username",
      width: 100,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 100,
    },
    {
      field: "bio",
      headerName: "Description",
      width: 250,
    },
    {
      field: "isPublic",
      headerName: "Type",
      width: 100,
      renderCell: ({ row: { isPublic } }) => (
        <Typography
          color={isPublic ? colors.greenAccent[400] : colors.redAccent[400]}
        >
          {isPublic ? "Public" : "Private"}
        </Typography>
      ),
    },

    {
      field: "followers",
      headerName: "Members",
      width: 80,
    },
    {
      field: "pageCount",
      headerName: "Likes",
      width: 80,
    },
    {
      field: "posts",
      headerName: "Posts",
      width: 80,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: ({ row: { status } }) => (
        <Typography
          color={
            status == "APPROVED"
              ? colors.greenAccent[400]
              : colors.redAccent[400]
          }
        >
          {status}
        </Typography>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Last Activity",
      width: 110,
      renderCell: ({ row: { updatedAt } }) => updatedAt.split("T")[0],
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      width: 110,
      renderCell: ({ row: { createdAt } }) => createdAt.split("T")[0],
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <GroupModal modal={modal} setModal={setModal} />
      <SpreadSheetUploadModal
        modal={spreadSheetModal}
        setModal={setSpreadSheetModal}
      />
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ justifyContent: "space-between" }}
      >
        <Grid xs={12} sm={12} md={6} lg={3.6} xl={3}>
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
          >
            <Header title="GROUPS" subtitle="Manage User Groups" />
          </Box>
        </Grid>
        <Box mx={1.5} mt={2} height={45} display="flex">
          <SearchTable
            handleSearch={handleSearch}
            placeholder="username/email/phone"
          />
          <Button
            onClick={(e) => toggleMenu(e)}
            underline="none"
            sx={{
              backgroundColor: colors.orangeAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "15px",
            }}
          >
            Create New Group
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            classes={{ paper: "dropdown-menu3" }}
          >
            <MenuItem onClick={(e) => handleOption(e)}>
              Create Single Group
            </MenuItem>
            <MenuItem onClick={(e) => handleOption(e)}>
              Create Bulk Group
            </MenuItem>
          </Menu>
        </Box>
        <Box
          m="10px"
          width="100%"
          height="70vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={newDetails ? newDetails : []}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default ManageGroup;
