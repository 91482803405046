import React, { useEffect, useRef, useState } from "react";
import {
  Tabs,
  Tab,
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import NonWayaTransferForm from "./screens/NonWayaTransferForm";
import { generatePaymentRef } from "../../utils/generatePaymentRef";
import { generateUrlId } from "../../utils/generateUrlID";

const MultipleNonWayaTransferModal = ({ modal, setModal }) => {
  const [forms, setForms] = useState([{ id: 1 }]);
  const [formValues, setFormValues] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const { success } = useAuth();
  const submitRef = useRef(null);
  const [callTime, setCallTime] = useState(0);

  const handleTabChange = (event, newValue) => {
    // Handle tab change logic
    submitRef?.current?.click();
    setCurrentTab(newValue);
  };

  const handleAddForm = () => {
    submitRef?.current?.click();
    const newFormId = forms.length + 1;
    setForms([...forms, { id: newFormId }]);
    setCurrentTab(newFormId - 1);
  };

  const handleSubmitAll = async () => {
    submitRef?.current?.click();
    console.log("All Form values:", formValues);

    const forms = [];
    for (let form in formValues) {
      let { amount, narration, recipientEmailOrPhone, recipientName, wallet } =
        formValues[form];

      forms.push({
        tranCrncy: "NGN",
        eventId: "COMPAYM",
        transactionCategory: "COMMISSION",
        fullName: wallet?.acct_name,
        amount,
        emailOrPhoneNo: recipientEmailOrPhone,
        recipientName,
        customerDebitAccountNo: wallet?.accountNo,
        paymentReference: generatePaymentRef(),
        tranNarration: narration,
        walletOp: [wallet],
      });
    }

    try {
      const response = await axiosPrivate.post(
        "https://services.staging.wayabank.ng/logs-service/api/v1/requestlog/create",
        {
          customerName: "Multiple Transfer Non Waya",
          payload: JSON.stringify(forms),
          requestId: generateUrlId(),
          requestType: "MultiTransferToNonWaya",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setModal({ open: false });
  };

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <Box
        sx={{
          width: "60%",
          //   height: "40%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Tabs
          sx={{
            bgcolor: colors.primary[400],
          }}
          value={currentTab}
          onChange={handleTabChange}
        >
          {forms.map((form) => (
            <Tab
              sx={{
                borderRadius: "5px",
                bgcolor: colors.primary[400],
                "&.Mui-selected": {
                  color: colors.grey[100],
                  bgcolor: colors.orangeAccent[700],
                },
              }}
              key={form.id}
              label={`Form ${form.id}`}
            />
          ))}
        </Tabs>
        <Box>
          <Typography variant="h4" sx={{ textAlign: "center", mt: 1.5, mb: 2 }}>
            Send Using Webform
          </Typography>
        </Box>
        {forms.map((form, index) => (
          <Box
            key={form.id}
            sx={{ display: currentTab === index ? "block" : "none" }}
          >
            <NonWayaTransferForm
              // businessTypes={businessTypes}
              form={form}
              forms={forms}
              submitRef={submitRef}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              setFormValues={setFormValues}
              setForms={setForms}
            />
          </Box>
        ))}
        <IconButton
          onClick={handleAddForm}
          sx={{ ml: 5, mr: 1, p: 2, mb: 2, bgcolor: colors.grey[700] }}
        >
          <AddIcon />
        </IconButton>
        <Button
          onClick={handleSubmitAll}
          color="secondary"
          variant="contained"
          sx={{ p: 2, width: "80%", mb: 2 }}
        >
          Create Users
        </Button>
      </Box>
    </Modal>
  );
};

export default MultipleNonWayaTransferModal;
