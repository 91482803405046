import {
  Box,
  Button,
  Modal,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import * as yup from "yup";
import { useTheme } from "@emotion/react";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles({
  textArea: {
    width: "100%",
    marginBottom: 20,
  },
});

const RejectRequestModal = ({ isOpen, setIsOpen, url, user, action }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const { success, snackError, cookies } = useAuth();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  // Formik
  const handleFormSubmit = async (values, actions) => {
    setIsLoading(true);
    const { reason } = values;
    if (action === "reject request") {
      try {
        const response = await axiosPrivate.post(url, {
          reason: reason,
          requestId: user.id,
          requestStatus: "REJECTED",
          userId: user.userId,
        });
        success(response?.data?.respDesc);
      } catch (error) {
        if (error.response) {
          snackError(error?.response?.data?.data);
        } else {
          console.log(error);
        }
      }
    } else if (
      action === "reject payment log" ||
      action == "reject disbursement"
    ) {
      const { id } = user;
      try {
        const response = await axiosPrivate.patch(
          url,
          {
            rejection_reason: reason,
            payment_request_id: id,
          },
          {
            params: {
              sso_id: cookies.auth.user.id,
            },
          }
        );
        success(response?.data?.message);
      } catch (error) {
        if (error.response) {
          snackError(error?.response?.data?.data);
        } else {
          console.log(error);
        }
      }
    } else if (action === "reject user kyc") {
      const { id } = user;
      try {
        const response = await axiosPrivate.put(url + id, null, {
          params: {
            status: "REJECTED",
            reason,
          },
        });
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    } else if (action === "reject kyc") {
      const { merchantId } = user;
      try {
        const response = await axiosPrivate.post(
          `https://services.staging.wayapay.ng/identity-manager-service/api/v1/waya-merchant/business-kyc/${merchantId}/change-status/REJECTED`,
          null,
          {
            params: {
              reasonForRejection: reason,
            },
          }
        );
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    } else if (action === "reject kyc request") {
      const { id } = user;
      try {
        const response = await axiosPrivate.put(url + id, null, {
          params: {
            reason,
            status: "REJECTED",
          },
        });
        success(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await axiosPrivate.patch(url, {
          action: "DECLINE",
          logId: user.id,
          reason: reason,
        });
        success(response?.data?.message);
      } catch (error) {
        if (error.response) {
          snackError(error?.response?.data?.data);
        } else {
          console.log(error);
        }
      }
    }
    setIsLoading(false);
    actions.resetForm();
    handleClose();
  };

  const initialValues = {
    reason: "",
  };

  const checkoutSchema = yup.object().shape({
    reason: yup.string(),
  });

  // Close the modal
  const handleClose = () => {
    setIsOpen((prev) => {
      return { ...prev, open: false };
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 520,
    height: 280,
    bgcolor: colors.primary[400],
    border: "1px solid #000",
    boxShadow: 24,
    p: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h5" component="h2" align="center">
          Reject {action == "reject kyc" ? "KYC" : "Request"}
        </Typography>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <TextareaAutosize
                className={classes.textArea}
                minRows={4}
                variant="filled"
                type="text"
                label="Rejection Reason"
                placeholder="Give rejection reason"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.reason}
                name="reason"
              />
              <Button
                variant="outlined"
                type="submit"
                disabled={isLoading}
                sx={{
                  backgroundColor: colors.orangeAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                underline="none"
              >
                Reject
              </Button>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default RejectRequestModal;
