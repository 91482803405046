import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import StrapiSwitch from "../../components/Switch";
import QRCodeModal from "../../components/modals/QRCodeModal";

const ManageFraud = () => {
  const [details, setDetails] = useState([]);
  const [newDetails, setNewDetails] = useState({});

  const [qrCodeModal, setQrCodeModal] = useState({
    open: false,
    title: "",
  });
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const getFraudRules = async () => {
      try {
        const response = await axiosPrivate.get(
          "/fraud-service/api/v1/fraud-rule"
        );
        setDetails(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getFraudRules();
  }, [newDetails]);

  const active = details?.filter((fraud) => fraud.activated === true);

  const columns = [
    { field: "ruleName", headerName: "Rule Name", width: 200 },
    {
      field: "maximumAttempt",
      headerName: "Number of Attempts",
      width: 200,
    },
    {
      field: "activated",
      headerName: "Activate",
      cellClassName: "name-column--cell",
      width: 200,
      renderCell: ({ row }) => {
        const toggleRule = async () => {
          try {
            const response = await axiosPrivate.put(
              "/fraud-service/api/v1/fraud-rule/edit",
              {
                ...row,
                activated: !row.activated,
              }
            );
            setNewDetails(response?.data.data);
          } catch (error) {
            console.log(error);
          }
        };
        return <StrapiSwitch checked={row.activated} onChange={toggleRule} />;
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <QRCodeModal
        open={qrCodeModal.open}
        setOpen={setQrCodeModal}
        title={qrCodeModal.title}
      />

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid xs={12} sm={12} md={6} lg={3.6} xl={3}>
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
          >
            <Header title="MANAGE FRAUD" subtitle="Manage Fraud Rules" />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.8} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={details?.length}
              subtitle="All fraud rules"
              icon={
                <PlaylistAddIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.8} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={active.length}
              subtitle="Active rules"
              icon={
                <ExitToAppIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.8} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={details.length - active.length}
              subtitle="Inactive Rule"
              icon={
                <AssignmentTurnedInIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Box
          m="10px"
          width="100%"
          height="60vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={details}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default ManageFraud;
