import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SearchTable from "../../components/SearchTable";
import WarningModal from "../../components/modals/WarningModal";
import RejectRequestModal from "../../components/modals/RejectRequestModal";
import KYCModal from "../../components/modals/KYCModal";

const ManageWayaQuickKYC = () => {
  const [details, setDetails] = useState({});
  const [newDetails, setNewDetails] = useState([]);
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [modal, setModal] = useState({ open: false, user: {} });
  const [warningModal, setWarningModal] = useState({ openConfirm: false });
  const [rejectModal, setRejectModal] = useState({ open: false });

  const getKYC = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayapay.ng/identity-manager-service/api/v1/waya-merchant/business-kyc",
        {
          params: {
            page: 0,
            size: 10,
          },
        }
      );
      return response?.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getKYCStats = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayapay.ng/identity-manager-service/api/v1/waya-merchant/business-kyc/statistics"
      );
      return response?.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getBusinessTypes = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayapay.ng/identity-manager-service/api/v1/waya-merchant/business-kyc/business-industry"
      );
      return response?.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const KYCDetails = async () => {
      const [kyc, kycStats, businessTypes] = await Promise.all([
        getKYC(),
        getKYCStats(),
        getBusinessTypes(),
      ]);
      setDetails({ kyc, kycStats, businessTypes });
      let pendingKYC = kyc.filter((kyc) => kyc.approvalStatus == "PENDING");
      setNewDetails(pendingKYC);
    };
    KYCDetails();
  }, []);

  const columns = [
    { field: "businessName", headerName: "Business Name", width: 200 },
    {
      field: "businessEmail",
      headerName: "Email Address",
      width: 200,
    },
    {
      field: "businessPhoneNumber",
      headerName: "Phone Number",
      width: 150,
    },
    {
      field: "businessType",
      headerName: "Business Type",
      cellClassName: "name-column--cell",
      width: 150,
    },
    {
      field: "updatedAt",
      headerName: "Verified Date",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "approvalStatus",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      width: 100,
      renderCell: ({ row: { approvalStatus } }) => {
        return (
          <Typography
            color={
              approvalStatus == "APPROVED"
                ? colors.greenAccent[400]
                : colors.redAccent[400]
            }
          >
            {approvalStatus}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: ({ row }) => {
        return (
          <Button
            onClick={() => setModal({ open: true, user: row })}
            underline="none"
            sx={{
              backgroundColor: colors.orangeAccent[700],
              color: colors.grey[100],
            }}
          >
            Update KYC
          </Button>
        );
      },
    },
  ];

  const columns2 = [
    { field: "name", headerName: "Business Name", width: 200 },
    {
      field: "createdAt",
      headerName: "Date Created",
      width: 250,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (cellValue) => {
        return (
          <Button
            underline="none"
            sx={{
              backgroundColor: colors.orangeAccent[700],
              color: colors.grey[100],
            }}
          >
            Update Type
          </Button>
        );
      },
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.kyc?.filter(
        (transaction) =>
          transaction.businessEmail === searchKey ||
          transaction.businessPhoneNumber === searchKey ||
          transaction.businessName === searchKey
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <KYCModal
        isOpen={modal.open}
        setModal={setModal}
        user={modal.user}
        businessTypes={details.businessTypes}
        setWarningModal={setWarningModal}
        setRejectModal={setRejectModal}
      />
      <WarningModal
        open={warningModal.openConfirm}
        action={warningModal.action}
        setOpen={setWarningModal}
        user={modal.user}
      />
      <RejectRequestModal
        isOpen={rejectModal.open}
        setIsOpen={setRejectModal}
        user={modal.user}
        action={rejectModal.action}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{ borderRadius: 3, marginBottom: 2 }}
        >
          <Tab
            // disabled={isLoading}
            label="BUSINESS KYC"
            sx={{
              bgcolor: colors.primary[400],
              "&.Mui-selected": {
                color: colors.grey[100],
                bgcolor: colors.orangeAccent[700],
              },
            }}
          />
          <Tab
            // disabled={isLoading}
            label="BUSINESS TYPES"
            sx={{
              bgcolor: colors.primary[400],
              "&.Mui-selected": {
                color: colors.grey[100],
                bgcolor: colors.orangeAccent[700],
              },
            }}
          />
        </Tabs>

        {value === 0 && (
          <Box width="95%">
            {/* HEADER */}

            {/* GRID & CHARTS */}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              justifyContent="space-between"
            >
              <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
                <Box
                  display={smScreen ? "flex" : "block"}
                  flexDirection={smScreen ? "row" : "column"}
                  justifyContent={smScreen ? "space-between" : "start"}
                  alignItems={smScreen ? "center" : "start"}
                  m="10px 0"
                >
                  <Header
                    title="Business KYC"
                    subtitle="Manage WayaQuick Business KYC"
                  />
                </Box>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={3}
                xl={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                }}
              >
                <SearchTable
                  handleSearch={handleSearch}
                  placeholder="email/phone/name"
                />
              </Grid>
              <Box
                m="10px"
                width="100%"
                height="60vh"
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                    color: colors.orangeAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.redAccent[700],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.redAccent[700],
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.orangeAccent[200]} !important`,
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                  },
                }}
              >
                <DataGrid
                  getRowId={(row) => {
                    return row.createdAt;
                  }}
                  rows={newDetails}
                  columns={columns}
                  // components={{ Toolbar: GridToolbar }}
                />
              </Box>
            </Grid>
          </Box>
        )}

        {value === 1 && (
          <Box width="95%">
            {/* HEADER */}

            {/* GRID & CHARTS */}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              justifyContent="space-between"
            >
              <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
                <Box
                  display={smScreen ? "flex" : "block"}
                  flexDirection={smScreen ? "row" : "column"}
                  justifyContent={smScreen ? "space-between" : "start"}
                  alignItems={smScreen ? "center" : "start"}
                  m="10px 0"
                >
                  <Header
                    title="Business Type"
                    subtitle="Manage WayaQuick Business Types"
                  />
                </Box>
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
                <Box
                  width="100%"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <StatBox
                    title={details?.businessTypes?.length}
                    subtitle="Business Types"
                    icon={
                      <PlaylistAddIcon
                        sx={{
                          color: colors.orangeAccent[600],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>
              </Grid>
              <Box
                m="10px"
                width="100%"
                height="60vh"
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                    color: colors.orangeAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.redAccent[700],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.redAccent[700],
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.orangeAccent[200]} !important`,
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                  },
                }}
              >
                <DataGrid
                  getRowId={(row) => {
                    return row.createdAt;
                  }}
                  rows={details?.businessTypes ? details?.businessTypes : []}
                  columns={columns2}
                />
              </Box>
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ManageWayaQuickKYC;
