import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import DottedCard from "../DottedCard";
import WalletCard from "../WalletCard";
import useAuth from "../../hooks/useAuth";
import AccountCard from "../AccountCard";

const ModalTab = ({ title, handleClick }) => {
  const { queryUserDetails } = useAuth();
  const { manageTransaction, manageAccount } = queryUserDetails;
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      justifyContent="center"
    >
      <Typography variant="h4" m={3} textAlign="center" gutterBottom>
        {title}
      </Typography>
      <Box maxHeight={500} sx={{ overflowY: "scroll" }}>
        <Grid
          container
          padding={2.5}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {title === "Accounts" ? (
            manageTransaction.map((wallet, index) => {
              return <WalletCard key={index} wallet={wallet} />;
            })
          ) : title === "Banks" ? (
            // <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <AccountCard account={manageAccount} />
          ) : (
            // </Grid>
            <WalletCard />
          )}
          <DottedCard handleClick={handleClick} />
        </Grid>
      </Box>
    </Box>
  );
};

export default ModalTab;
