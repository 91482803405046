import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

const PasswordField = ({ iconEnd, setShowPassword, InputProps, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        endAdornment: iconEnd ? (
          <InputAdornment
            position="end"
            sx={{ cursor: "pointer" }}
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {iconEnd}
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

export default PasswordField;
