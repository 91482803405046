import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";
import { tokens } from "../../theme";

const TitlesList = ({ t1, t2, s1, s2 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box display="flex" width="100%" justifyContent="space-between" p={3}>
      <Box width="50%">
        <Typography variant="h4">{t1}</Typography>
        <Typography variant="h5" color={colors.grey[400]}>
          {s1}
        </Typography>
      </Box>
      <Box width="50%">
        <Typography variant="h4">{t2}</Typography>
        <Typography variant="h5" color={colors.grey[400]}>
          {s2}
        </Typography>
      </Box>
    </Box>
  );
};

export default TitlesList;
