import React, { useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { tokens } from "../../../theme";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import SearchableSelectTransfer from "../../SearchableSelectTransfer";
import SearchableSelector from "../../SearchableSelector";

const NonWayaTransferForm = ({
  form,
  forms,
  submitRef,
  currentTab,
  setCurrentTab,
  setFormValues,
  setForms,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [selectValue, setSelectValue] = useState(null);
  const [options, setOptions] = useState([]);

  const handleDeleteForm = (formId) => {
    const updatedForms = forms.filter((form) => form.id !== formId);
    setForms(updatedForms);
    setCurrentTab(formId == forms.length ? forms.length - 2 : formId - 1);
  };

  const handleFormSubmit = (values) => {
    // Handle form submission logic
    const formId = forms[currentTab].id;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [formId]: values,
    }));
  };

  const initialValues = {
    username: null,
    recipientName: "",
    recipientEmailOrPhone: "",
    narration: "",
    amount: "",
    wallet: "",
  };

  const getWallets = async ({ userId }) => {
    try {
      const response = await axiosPrivate.get(
        "/temporal-service/api/v1/wallet/accounts/" + userId
      );
      setOptions(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const checkoutSchema = yup.object().shape({
    username: yup.object().required("Required"),
    recipientName: yup.string().required("Required"),
    recipientEmailOrPhone: yup.string().required("Required"),
    narration: yup.string().required("Required"),
    amount: yup.number().required("Required"),
    wallet: yup.object().required("Required"),
  });

  const handleSelectChange = (e, newValue, setFieldValue) => {
    setFieldValue("username", newValue);
    getWallets(newValue);
  };

  return (
    <Formik
      key={form.id}
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box padding={5}>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
                marginBottom: "10px",
              }}
            >
              <SearchableSelector
                value={values.username}
                name="username"
                sx={{ gridColumn: "span 2" }}
                handleChange={(e, newValue) =>
                  handleSelectChange(e, newValue, setFieldValue)
                }
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Recipient Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.recipientName}
                name="recipientName"
                error={!!touched.recipientName && !!errors.recipientName}
                helperText={touched.recipientName && errors.recipientName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Recipient's Email Or Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.recipientEmailOrPhone}
                name="recipientEmailOrPhone"
                error={
                  !!touched.recipientEmailOrPhone &&
                  !!errors.recipientEmailOrPhone
                }
                helperText={
                  touched.recipientEmailOrPhone && errors.recipientEmailOrPhone
                }
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Amount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.amount}
                name="amount"
                error={!!touched.amount && !!errors.amount}
                helperText={touched.amount && errors.amount}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Narration"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.narration}
                name="narration"
                error={!!touched.narration && !!errors.narration}
                helperText={touched.narration && errors.narration}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                label="Sender's Wallet"
                fullWidth
                select
                name="wallet"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.wallet}
                sx={{ gridColumn: "span 4" }}
              >
                {options?.map((option, index) => {
                  const { acct_name, accountNo } = option;
                  return (
                    <MenuItem key={index} value={option}>
                      {acct_name + " - " + accountNo}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
            <Button
              type="submit"
              ref={currentTab + 1 == form.id ? submitRef : null}
              sx={{ display: "none" }}
            />
            <IconButton
              onClick={() => handleDeleteForm(form.id)}
              sx={{ position: "absolute", top: 80, right: 50 }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default NonWayaTransferForm;
