import React from "react";
import { Card, CardContent, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    position: "relative",
    border: "1px dashed #000",
    background: "transparent",
    textAlign: "center",
    width: 200,
    height: 110,
    margin: 5,
  },
  iconContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const DottedCard = ({ handleClick }) => {
  const classes = useStyles();

  return (
    <Card className={classes.cardContainer} variant="outlined">
      <CardContent>
        <div className={classes.iconContainer}>
          <IconButton onClick={handleClick}>
            <AddIcon />
          </IconButton>
        </div>
      </CardContent>
    </Card>
  );
};

export default DottedCard;
