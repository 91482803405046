import React, { useEffect } from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { tokens } from "../../theme";
import { generateReqId } from "../../utils/generateReqId";
import SearchableSelectTransfer from "../SearchableSelectTransfer";

const TransferModal = ({ isOpen, setModal, title = "Send to Single User" }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { success, snackError } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [selectValue, setSelectValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const userId = Number(
    selectValue?.slice(selectValue?.lastIndexOf("-") + 1, selectValue?.length)
  );

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const { amount, narration, recipientEmail, wallet } = values;
    let reqId = generateReqId();
    const customerName = selectValue?.slice(0, selectValue?.indexOf("-") - 1);

    try {
      const response = await axiosPrivate.post(
        "/logs-service/api/v1/requestlog/create",
        {
          payload: JSON.stringify({
            customerName,
            amount: amount,
            customerDebitAccountNo: wallet.slice(
              wallet.lastIndexOf("-") + 1,
              wallet.length
            ),
            emailOrPhoneNo: recipientEmail,
            fullName: customerName,
            paymentReference: userId + "|1|" + reqId,
            tranCrncy: "NGN",
            tranNarration: narration,
            userId: userId,
          }),
          requestId: reqId,
          requestType: "TRANSFERToNonWaya",
        }
      );

      success(response?.data?.message);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    handleClose();
  };

  const initialValues = {
    recipientPhone: "",
    recipientEmail: "",
    narration: "",
    amount: "",
    wallet: "",
  };

  useEffect(() => {
    const getWallets = async () => {
      try {
        const response = await axiosPrivate.get(
          "/temporal-service/api/v1/wallet/accounts/" + userId
        );
        const wallets = response?.data?.data?.map(
          ({ accountNo, acct_name }) => acct_name + "-" + accountNo
        );
        setOptions(wallets);
      } catch (error) {
        console.log(error);
      }
    };

    getWallets();
  }, [selectValue]);

  const checkoutSchema = yup.object().shape({
    recipientPhone: yup.number().required("Required"),
    recipientEmail: yup.string().email().required("Required"),
    narration: yup.string().required("Required"),
    amount: yup.number().required("Required"),
    wallet: yup.string().required("Required"),
  });

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  const handleSelectChange = (e) => {
    setSelectValue(e.target.textContent);
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          backgroundColor: colors.primary[400],
          p: 2,
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box padding={5}>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", marginBottom: "30px" }}
                >
                  {title}
                </Typography>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                    marginBottom: "10px",
                  }}
                >
                  <SearchableSelectTransfer
                    value={selectValue}
                    handleChange={handleSelectChange}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Recipient's Phone Number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.recipientPhone}
                    name="recipientPhone"
                    error={!!touched.recipientPhone && !!errors.recipientPhone}
                    helperText={touched.recipientPhone && errors.recipientPhone}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Recipient's Email Address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.recipientEmail}
                    name="recipientEmail"
                    error={!!touched.recipientEmail && !!errors.recipientEmail}
                    helperText={touched.recipientEmail && errors.recipientEmail}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.amount}
                    name="amount"
                    error={!!touched.amount && !!errors.amount}
                    helperText={touched.amount && errors.amount}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Narration"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.narration}
                    name="narration"
                    error={!!touched.narration && !!errors.narration}
                    helperText={touched.narration && errors.narration}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    label="Sender's Wallet"
                    fullWidth
                    select
                    name="wallet"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.wallet}
                    sx={{ gridColumn: "span 4" }}
                  >
                    {options?.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  disabled={isLoading}
                  variant="contained"
                  sx={{ width: "100%", p: 2, mt: 2 }}
                >
                  Proceed
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default TransferModal;
