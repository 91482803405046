// Function to generate a random 16-digit ID
export const generateSelfPaymentRef = () => {
  const digits = "0123456789";
  let id = "WAYA";

  while (id.length < 13) {
    id += digits[Math.floor(Math.random() * digits.length)];
  }

  return id + "SELF";
};
