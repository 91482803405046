import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import SearchTable from "../../components/SearchTable";
import RejectRequestModal from "../../components/modals/RejectRequestModal";
import WarningModal from "../../components/modals/WarningModal";

const RequestLogs = () => {
  const [details, setDetails] = useState();
  const [newDetails, setNewDetails] = useState([]);
  const [rejectModal, setRejectModal] = useState({
    open: false,
    url: "",
    user: {},
  });
  const [modal, setModal] = useState({
    openConfirm: false,
    url: "",
    action: "",
  });
  const { success, snackError, cookies } = useAuth();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const getRequestLogs = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayagram.ng/wayagram-service/all-posts/logs",
        {
          params: {
            sso_id: cookies.auth.user.id,
          },
        }
      );
      return response?.data?.data;
    } catch (error) {
      if (error.response.status === 403) {
        snackError("Sorry, Not Authorized");
      } else {
        console.log(error);
      }
    }
  };

  const getTotalRequestLogs = async () => {
    try {
      const response = await axiosPrivate.get(
        "https://services.staging.wayapos.ng/logs-service/api/v1/requestlog/total-request"
      );
      return response?.data?.data;
    } catch (error) {
      if (error.response.status === 403) {
        // snackError("Sorry, Not Authorized ");
      } else {
        console.log(error);
      }
    }
  };

  const handleSearch = async ({ searchKey }) => {
    try {
      let result = details?.requestLogs?.filter(
        (log) =>
          log.id === searchKey ||
          log.requestBy?.firstName?.toLowerCase() === searchKey.toLowerCase()
      );
      setNewDetails(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const QRDetails = async () => {
      const [requestLogs, totalRequestLogs] = await Promise.all([
        getRequestLogs(),
        getTotalRequestLogs(),
      ]);
      setDetails({
        requestLogs,
        totalRequestLogs,
      });

      let pendingLogs = requestLogs.filter((log) => log.status == "PENDING");
      setNewDetails(pendingLogs);
    };
    QRDetails();
  }, []);

  const columns = [
    { field: "id", headerName: "Request Id", width: 250 },
    {
      field: "requestBy",
      headerName: "Requested By",
      width: 110,
      renderCell: ({ row: { requestBy } }) => requestBy.firstName,
    },
    {
      field: "roles",
      headerName: "Role",
      cellClassName: "name-column--cell",
      width: 150,
      renderCell: ({ row: { requestBy } }) => requestBy.roles[0],
    },
    {
      field: "approvedBy",
      headerName: "Approved By",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderCell: ({ row: { approvedBy } }) =>
        approvedBy?.firstName
          ? approvedBy?.firstName + " " + approvedBy?.lastName
          : "------------",
    },
    {
      field: "requestType",
      headerName: "Request Type",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: ({ row: { status } }) => {
        return (
          <Typography
            color={
              status == "APPROVED"
                ? colors.greenAccent[400]
                : colors.redAccent[400]
            }
          >
            {status}
          </Typography>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 100,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Button
              onClick={() =>
                setModal({
                  openConfirm: true,
                  user: row,
                  action: "accept",
                  url: "https://services.staging.wayagram.ng/wayagram-service/all-posts/logs/admin/decide-log",
                })
              }
              disabled={row.status === "APPROVED"}
              underline="none"
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
              }}
            >
              Accept
            </Button>
            <Button
              onClick={() =>
                setRejectModal({
                  open: true,
                  user: row,
                  url: "https://services.staging.wayagram.ng/wayagram-service/all-posts/logs/admin/decide-log",
                })
              }
              disabled={row.status === "DECLINED"}
              underline="none"
              sx={{
                backgroundColor: colors.orangeAccent[700],
                ml: 1,
                color: colors.grey[100],
              }}
            >
              Reject
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}

      <RejectRequestModal
        isOpen={rejectModal.open}
        setIsOpen={setRejectModal}
        url={rejectModal.url}
        user={rejectModal.user}
      />

      <WarningModal
        open={modal.openConfirm}
        action={modal.action}
        url={modal.url}
        user={modal.user}
        setOpen={setModal}
      />

      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title="REQUEST LOGS" subtitle="Manage Users Request Logs" />

        <Box display={"flex"} justifyContent={"space-between"}>
          <SearchTable handleSearch={handleSearch} placeholder="id/requestBy" />
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={details?.totalRequestLogs?.totalRequest}
              subtitle="Requests"
              icon={
                <PlaylistAddIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={details?.totalRequestLogs?.totalApproved}
              subtitle="Approved"
              icon={
                <ExitToAppIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={details?.totalRequestLogs?.totalInitiated}
              subtitle="Pending"
              icon={
                <AssignmentTurnedInIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={details?.totalRequestLogs?.totalRejected}
              subtitle="Rejected"
              icon={
                <AssignmentLateIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={details?.totalRequestLogs?.totalFailed}
              subtitle="Failed"
              icon={
                <AssignmentLateIcon
                  sx={{ color: colors.orangeAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Box
          m="10px"
          width="100%"
          height="63vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.orangeAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.redAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.redAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.orangeAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={newDetails ? newDetails : []}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default RequestLogs;
