import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, InputBase, useTheme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import * as yup from "yup";
import { tokens } from "../theme";
import { Formik } from "formik";

const Searchbox = ({ handleSearch }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const emailRegex =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  const phoneRegex = /^(\+234-|\+234|0)?\d{10,13}$/;

  const initialValues = {
    searchKey: "",
  };
  const checkoutSchema = yup.object().shape({
    searchKey: yup
      .string("Enter your Email/Phone Number")
      .required("Email/Phone Number is required")
      .test("emailOrPhone", "Enter Valid Phone/Email", function (value) {
        let isValidEmail = emailRegex.test(value);
        let isValidPhone = phoneRegex.test(value);
        if (!isValidEmail && !isValidPhone) {
          return false;
        }
        return true;
      }),
  });

  return (
    <Box
      display="flex"
      backgroundColor={colors.primary[400]}
      p={0.2}
      borderRadius={1}
    >
      <Formik
        onSubmit={handleSearch}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} style={{ display: "flex", padding: 2 }}>
            {values.searchKey.length > 0 && (
              <IconButton onClick={() => setFieldValue("searchKey", "")}>
                <ClearIcon />
              </IconButton>
            )}
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Phone/Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.searchKey}
              name="searchKey"
            />
            <IconButton type="submit">
              <SearchIcon />
            </IconButton>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Searchbox;
