import React from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { IconButton } from "@mui/material";
import { tokens } from "../theme";
import { useTheme } from "@emotion/react";

const GenericPdfDownloader = ({ data, downloadFileName }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const downloadPdfDocument = () => {
    const pdf = new jsPDF("l", "pt", "a4");
    const columns = [
      "Transaction Id",
      "Date",
      "Sender Name",
      "Sender Account",
      "Category",
      "Amount",
      "Type",
      "Narration",
    ];
    var rows = [];

    for (let i = 0; i < data.length; i++) {
      /*for (var key in data[i]) {
            var temp = [key, data[i][key]];
            rows.push(temp);
          }*/
      var temp = [
        data[i].paymentReference,
        data[i].createdAt.split("T")[0],
        data[i].senderName,
        data[i].acctNum,
        data[i].tranType,
        data[i].tranAmount,
        data[i].partTranType == "C" ? "Credit" : "Debit",
        data[i].tranNarrate.slice(0, 25),
      ];
      rows.push(temp);
    }

    pdf.text(135, 100, downloadFileName);
    pdf.autoTable(columns, rows, {
      startY: 40,
      theme: "grid",
      styles: {
        font: "helvetica",
        halign: "left",
        cellPadding: 3.5,
        lineWidth: 0,
        textColor: [100, 100, 100],
      },
      headStyles: {
        textColor: [250, 250, 250],
        fontStyle: "bold",
        lineWidth: 0,
        fillColor: [30, 164, 247],
      },
      alternateRowStyles: {
        fillColor: [212, 212, 212],
        lineWidth: 0,
      },
      rowStyles: {
        lineWidth: 0,
      },
      tableLineColor: [0, 0, 0],
    });
    // console.log(pdf.output("datauristring"));
    pdf.save(downloadFileName);
  };

  return (
    <IconButton
      onClick={downloadPdfDocument}
      sx={{
        borderRadius: 2,
        mr: 2,
        color: colors.grey[100],
        px: 2,
        bgcolor: colors.grey[900],
      }}
    >
      <PictureAsPdfIcon />
    </IconButton>
  );
};

export default GenericPdfDownloader;
